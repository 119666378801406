import * as React from 'react';
import { renderEditField } from './InputValueEditor';
import { Title, ValueEditorContainer, ValueList } from '@glueapp/component-library';
import styled from 'styled-components';
import { ObjectDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { DataValue, getDefaultValueForDataType } from '@glueapp/graphexecution/lib/types/DataValue';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';

const TypeLiteralListItem = styled.li`
    padding-top: 4px;
    padding-bottom: 4px;
`;


interface TypeLiteralValueEditorProps {
    type: ObjectDataType;
    value: { [key: string]: DataValue };
    userDefinedType?: UserDefinedType;
    onChange: (value: { [key: string]: DataValue }, updatedType?: UserDefinedType) => void;
}

export const ObjectValueEditor: React.FC<TypeLiteralValueEditorProps> = ({type, value, userDefinedType, onChange }) => {
    return (
        <ValueEditorContainer>
            <Title>
                Object
            </Title>
            <ValueList>
                {Object.entries(type.properties).map(([propertyKey, propertyType]) => {
                    const propertyFromValue: DataValue|undefined = value[propertyKey];
                    return (
                        <TypeLiteralListItem key={propertyKey}>
                            <Title>{propertyKey}:</Title>
                            {renderEditField(
                                propertyType,
                                (propertyFromValue !== undefined) ? propertyFromValue : getDefaultValueForDataType((propertyType)),
                                userDefinedType,
                                (changedValue: DataValue) => onChange({ ...value, [propertyKey]: changedValue })
                            )}
                        </TypeLiteralListItem>
                    );
                })}
            </ValueList>
        </ValueEditorContainer>
    );
};
