export interface EnvironmentConfig {
    graphServiceUrl: string;
    authenticationServiceUrl: string;
    integrationServiceUrl: string;
    webhookServiceUrl: string;
    liveSyncRegistrationServiceUrl: string;
    liveSyncServiceUrl: string;
    todoistApiProxy: string;
}

const stageEnvironmentVariableMissingMessage =
    'Glue is missing the stage environment variable, please specify the environment variable REACT_APP_GLUE_STAGE.\nexample: REACT_APP_GLUE_STAGE=development';

export const getEnvironmentConfig = (): EnvironmentConfig => {
    const stage = process.env.REACT_APP_GLUE_STAGE;
    if (!stage) {
        throw new Error(stageEnvironmentVariableMissingMessage);
    }
    switch (stage) {
        case 'production': {
            return {
                graphServiceUrl: 'https://graphservice.glueapp.org',
                authenticationServiceUrl: 'https://authenticationservice.glueapp.org',
                integrationServiceUrl: 'https://integrationservice.glueapp.org',
                webhookServiceUrl: 'https://webhookservice.glueapp.org',
                liveSyncRegistrationServiceUrl: 'https://register-live-sync.glueapp.org',
                liveSyncServiceUrl: 'wss://live-sync.glueapp.org',
                // FIXME replace with proxy in new structure
                todoistApiProxy: 'https://ller1yec98.execute-api.eu-central-1.amazonaws.com/Live'
            };
        }
        case 'development': {
            return {
                graphServiceUrl: 'https://graphservice.development.glueapp.org',
                authenticationServiceUrl: 'https://authenticationservice.development.glueapp.org',
                integrationServiceUrl: 'https://integrationservice.development.glueapp.org',
                webhookServiceUrl: 'https://webhookservice.development.glueapp.org',
                liveSyncRegistrationServiceUrl: 'https://register-live-sync.development.glueapp.org',
                liveSyncServiceUrl: 'wss://live-sync.development.glueapp.org',
                // FIXME replace with proxy in new structure
                todoistApiProxy: 'https://ller1yec98.execute-api.eu-central-1.amazonaws.com/Live'
            };
        }
        default: {
            return {
                graphServiceUrl: `https://graphservice-${stage}.development.glueapp.org`,
                authenticationServiceUrl: `https://authenticationservice-${stage}.development.glueapp.org`,
                integrationServiceUrl: `https://integrationservice-${stage}.development.glueapp.org`,
                webhookServiceUrl: `https://webhookservice-${stage}.development.glueapp.org`,
                liveSyncRegistrationServiceUrl: `https://register-live-sync-${stage}.development.glueapp.org`,
                liveSyncServiceUrl: `wss://live-sync-${stage}.development.glueapp.org`,
                // FIXME replace with proxy in new structure
                todoistApiProxy: 'https://ller1yec98.execute-api.eu-central-1.amazonaws.com/Live'
            };
        }
    }
};
