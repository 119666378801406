import React, { useContext } from 'react';
import { ThemeType } from '@glueapp/component-library';
import { ThemeContext } from 'styled-components';

interface Props extends React.SVGProps<SVGSVGElement> {
}

export const ProfileIcon: React.FC<Props> = (props) => {
    const { primaryContrastColor } = useContext<ThemeType>(ThemeContext);
    return (
        <svg {...props} viewBox="0 16 176 160" stroke={primaryContrastColor} fill="none" >
            <path d="M137 147C137 119.938 115.062 98 88 98C60.938 98 39 119.938 39 147" strokeWidth="11"/>
            <circle cx="88" cy="65" r="32.5" strokeWidth="11"/>
        </svg>
    );
}
