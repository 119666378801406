export function dictionaryFromNumberTuple<InValue, OutValue>(arr: InValue[], getTuple: (inValue: InValue) => [number, OutValue]): { [key: number]: OutValue; } {
    return arr.reduce((acc: { [key: number]: OutValue; }, inValue: InValue) => {
        const tuple = getTuple(inValue);
        acc[tuple[0]] = tuple[1];
        return acc;
    }, {});
}


export function dictionaryFromStringTuple<InValue, OutValue>(arr: InValue[], getTuple: (inValue: InValue) => [string | number, OutValue]): { [key: string]: OutValue; } {
    return arr.reduce((acc: { [key: string]: OutValue; }, inValue: InValue) => {
        const tuple = getTuple(inValue);
        acc[tuple[0]] = tuple[1];
        return acc;
    }, {});
}
