import React, { useMemo, useState } from 'react';
import { GlobalStyle } from './GlobalStyles';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import { ThemeProvider } from 'styled-components';
import { AuthenticationProvider } from './authentication/AuthenicationProvider';
import { DarkTheme, LightTheme } from '@glueapp/component-library';

export const ThemeSwitchingContext = React.createContext<{ toggleTheme: () => void, activeTheme: 'LIGHT'|'DARK' }>({ toggleTheme: () => {}, activeTheme: 'LIGHT' });

export function App() {
    const [selectedTheme, setSelectedTheme] = useState<'LIGHT'|'DARK'>(() => {
        const localStorageItem = localStorage.getItem('GLUE_THEME');
        if (localStorageItem === 'LIGHT' || localStorageItem === 'DARK') {
            return localStorageItem;
        }
        return 'LIGHT';
    });
    const themeSwitchingContextValue = useMemo(() => {
        return {
            activeTheme: selectedTheme,
            toggleTheme: () => {
                switch (selectedTheme) {
                    case 'LIGHT':
                        setSelectedTheme('DARK');
                        localStorage.setItem('GLUE_THEME', 'DARK');
                        break;
                    case 'DARK':
                        setSelectedTheme('LIGHT');
                        localStorage.setItem('GLUE_THEME', 'LIGHT');
                        break;
                }
            }
        };
    }, [selectedTheme]);

    return (
        <>
            <ThemeSwitchingContext.Provider value={themeSwitchingContextValue}>
                <ThemeProvider theme={selectedTheme === 'LIGHT' ? LightTheme : DarkTheme}>
                    <GlobalStyle />
                    <Router>
                        <AuthenticationProvider>
                            <Routes />
                        </AuthenticationProvider>
                    </Router>
                </ThemeProvider>
            </ThemeSwitchingContext.Provider>
        </>
    );
}