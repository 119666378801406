import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '@glueapp/component-library';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
    body {
        margin: 0;
        padding: 0;
        background: ${props => props.theme.backgroundColor};
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    } 
`;
