import styled from 'styled-components';
import React from 'react';
import { useAuthentication } from '../authentication/AuthenicationProvider';
import { Button } from '../base/Button';

const ProfileContainer = styled.div`
    background-color: ${props => props.theme.elevatedBackgroundColor};    
    border: 2px solid ${props => props.theme.separatorColor};
    filter: drop-shadow(0px 0px 5px #000000AA);
    border-radius: 8px; 
    display: flex;
    flex-direction: column;
    padding: 8px;
`;

const Email = styled.p`
    color: ${props => props.theme.textColor};
    margin-top: 4px;
    margin-bottom: 4px;
    grid-area: email;
`;

const LogoutButton = styled(Button)`
    grid-area: logout;
`;

interface Props {
    className?: string;
}

export const Profile: React.FC<Props> = ({ className = '' }) => {
    const { user, logout } = useAuthentication();
    if (!user) {
        return null;
    }
    return (
        <ProfileContainer className={className}>
            <Email>Logged in as: {user.email}</Email>
            <LogoutButton onClick={logout}>Logout</LogoutButton>
        </ProfileContainer>
    );
};
