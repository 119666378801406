import { css, keyframes } from 'styled-components';

const BackgroundAnimation = keyframes`
    0%{background-position: 100% 50%}
    100%{background-position: -100% 50%}
`;

export const RainbowBackgroundAnimation = css`
        background: linear-gradient(
            to right,
            hsl(0, 100%, 65%) 0%,
            hsl(45, 100%, 65%) 12.5%,
            hsl(90, 100%, 65%) 25%,
            hsl(135, 100%, 65%) 37.5%,
            hsl(180, 100%, 65%) 50%,
            hsl(225, 100%, 65%) 62.5%,
            hsl(270, 100%, 65%) 75%,
            hsl(315, 100%, 65%) 87.5%,
            hsl(360, 100%, 65%) 100%
        );
        background-size: 200% 100%;
        animation: ${BackgroundAnimation} 1s infinite linear;
`;