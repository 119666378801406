import styled from 'styled-components';

interface Props {
    baseColor?: string;
    hoverColor?: string;
}

export const Button = styled.button<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: ${props => props.theme.textColor};
    background: ${props => props.baseColor || props.theme.backgroundColor};
    border-radius: 8px;
    border: 2px solid white;
    min-width: 120px;
    padding: 8px;
    outline: none;
    transition: background-color .2s ease;
    
    &:disabled {
        color: ${props => props.theme.disabledText};
        background: ${props => props.theme.elevatedBackgroundColor};
        border: 1px solid ${props => props.theme.subtleAccentColor};
        
        &:hover {
            background: ${props => props.theme.subtleAccentColor};
            cursor: not-allowed;
        }  
    }
    &:hover {
        background: ${props => props.hoverColor || props.theme.positiveAccentColor};
    }
`;
