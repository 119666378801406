import * as React from 'react';
import { ValueEditorContainer } from '@glueapp/component-library';
import styled from 'styled-components';
import { ShapeValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';
import {
    ArrayDataType,
    BooleanDataType,
    DataType,
    NumberDataType,
    NumberLiteralDataType,
    ObjectDataType,
    ShapeDataType,
    StringDataType,
    StringLiteralDataType, typeAMatchesTypeB
} from '@glueapp/graphexecution/lib/types/DataType';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { ShapeStringLiteralEditor } from './ShapeStringLiteralEditor';
import { ShapeNumberLiteralEditor } from './ShapeNumberLiteralEditor';
import { ShapeArrayEditor } from './ShapeArrayEditor';
import { ShapeObjectEditor } from './ShapeObjectEditor';

const renderEditField = (
    value: DataType,
    onChange: (value: DataType) => void
) => {
    switch(value.kind) {
        case 'string':
        case 'number':
        case 'boolean':
        case 'shape':
        case 'union':
        case 'generic':
            return null;
        case 'stringLiteral':
            return (
                <ShapeStringLiteralEditor
                    value={value as StringLiteralDataType}
                    onChange={onChange}
                />
            );
        case 'numberLiteral':
            return (
                <ShapeNumberLiteralEditor
                    value={value as NumberLiteralDataType}
                    onChange={onChange}
                />
            );
        case 'array':
            return (
                <ShapeArrayEditor
                    value={value as ArrayDataType}
                    onChange={onChange}
                />
            );
        case 'object':
            return (
                <ShapeObjectEditor
                    value={value as ObjectDataType}
                    onChange={onChange}
                />
            );
    }
};



const StringOption: StringDataType =                { kind: 'string', };
const NumberOption: NumberDataType =                { kind: 'number', };
const BooleanOption: BooleanDataType =              { kind: 'boolean', };
const StringLiteralOption: StringLiteralDataType =  { kind: 'stringLiteral', stringLiteral: '', };
const NumberLiteralOption: NumberLiteralDataType =  { kind: 'numberLiteral', numberLiteral: 0, };
const ObjectOption: ObjectDataType =                { kind: 'object', restriction: 'open', properties: {} };
const ArrayOption: ArrayDataType =                  { kind: 'array', subType: { kind: 'string' } };
const SHAPE_TYPE_SELECTOR_OPTIONS: { [key: string]: DataType } = {
    [StringOption.kind]: StringOption,
    [NumberOption.kind]: NumberOption,
    [BooleanOption.kind]: BooleanOption,
    [StringLiteralOption.kind]: StringLiteralOption,
    [NumberLiteralOption.kind]: NumberLiteralOption,
    [ObjectOption.kind]: ObjectOption,
    [ArrayOption.kind]: ArrayOption
};

const ShapeTypeSelectorOptionKindToLabel: Map<string, string> = new Map([
    [StringOption.kind, 'Text'],
    [NumberOption.kind, 'Number'],
    [BooleanOption.kind, 'Boolean'],
    [StringLiteralOption.kind, 'A specific Text'],
    [NumberLiteralOption.kind, 'A specific Number'],
    [ObjectOption.kind, 'Object'],
    [ArrayOption.kind, 'List']
]);

export const ShapeSelect = styled.select`
    color: ${props => props.theme.textColor};
    background: ${props => props.theme.elevatedBackgroundColor};
    border: 1px solid ${props => props.theme.separatorColor};
    font-size: 16px;
    padding: 4px;
`;

interface ShapeTypeSelectorEditorProps {
    value: DataType;
    restriction?: DataType;
    onChange: (value: DataType) => void;
}

export const ShapeTypeSelectorEditor: React.FC<ShapeTypeSelectorEditorProps> = ({
     value,
     restriction,
     onChange
 }) => {
    const filteredOptions: DataType[] = useMemo(() => {
        if (restriction) {
            return Object.values(SHAPE_TYPE_SELECTOR_OPTIONS).filter(option => typeAMatchesTypeB(option, restriction));
        } else {
            return Object.values(SHAPE_TYPE_SELECTOR_OPTIONS);
        }
    }, [restriction]);

    const handleSelectChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = SHAPE_TYPE_SELECTOR_OPTIONS[event.target.value];
        if (selectedOption) {
            onChange(selectedOption);
        }
    }, [onChange]);

    return (
        <>
            <ShapeSelect value={value.kind} onChange={handleSelectChange} disabled={filteredOptions.length < 2}>
                {filteredOptions.map((option, index) => (
                    <option
                        key={index}
                        value={option.kind}
                    >
                        {ShapeTypeSelectorOptionKindToLabel.get(option.kind)}
                    </option>
                ))}
            </ShapeSelect>
            {renderEditField(value, onChange)}
        </>
    );
};


interface ShapeValueEditorProps {
    type: ShapeDataType;
    value?: ShapeValue;
    onChange: (value: ShapeValue, updatedType?: UserDefinedType) => void;
}

export const ShapeValueEditor: React.FC<ShapeValueEditorProps> = ({type, value, onChange }) => {
    return (
        <ValueEditorContainer>
            <ShapeTypeSelectorEditor
                value={value?.type ?? { kind: 'string' }}
                restriction={type.restriction}
                onChange={(newValue) => onChange({ isShapeValue: true, type: newValue })}
            />
        </ValueEditorContainer>
    );
};
