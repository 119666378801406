import { MouseEvent, MutableRefObject, PointerEvent, useState } from 'react';
import { subtractVectors, Vector } from '../helpers/Vector';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';

export interface ViewDrag {
    startMousePosition: Vector,
    startScrollPosition: Vector
}

export function useViewDrag(
    containerRef: MutableRefObject<HTMLDivElement|null>,
    svgRef: MutableRefObject<SVGElement|null>
) {
    const [viewDrag, setViewDrag] = useState<ViewDrag|null>(null);

    const handleViewDragStart = (event: PointerEvent) => {
        switch(event.button) {
            case 0: {
                const containerElement = containerRef.current;
                if (containerElement) {
                    setViewDrag({
                        startMousePosition: getInElementPositionFromEvent(event, containerElement),
                        startScrollPosition: {
                            x: containerElement.scrollLeft,
                            y: containerElement.scrollTop
                        }
                    });
                    const svgElement = svgRef.current;
                    if (svgElement) {
                        svgElement.setPointerCapture(event.pointerId);
                    }
                }
                break;
            }
            case 2: {
                // TODO show contextual menu with delete
                event.preventDefault();
                break;
            }
        }
    };

    const handleViewDragMove = (event: MouseEvent) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const mousePosition = getInElementPositionFromEvent(event, containerElement);
            if (viewDrag) {
                const difference = subtractVectors(viewDrag.startMousePosition, mousePosition);
                const containerElement = containerRef.current;
                if (containerElement) {
                    containerElement.scrollTo(
                        viewDrag.startScrollPosition.x + difference.x,
                        viewDrag.startScrollPosition.y + difference.y
                    );
                }
            }
        }
    };

    const handleViewDragEnd = (event: PointerEvent) => {
        setViewDrag(null);
        const svgElement = svgRef.current;
        if (svgElement) {
            svgElement.releasePointerCapture(event.pointerId);
        }
    }


    return {
        viewDrag,
        setViewDrag,
        handleViewDragStart,
        handleViewDragMove,
        handleViewDragEnd
    }
}