import React, { useEffect, useRef, useState } from 'react';
import { AsyncStateStatus, useAsyncState } from '../helpers/useAsyncState';
import { Link } from 'react-router-dom';
import { useGraphStorageApi } from '../api/useGraphStorageApi';
import styled, { keyframes } from 'styled-components';
import { GraphIcon } from './GraphIcon';
import { Prompt } from '../base/Prompt';
import { DeleteIcon } from './DeleteIcon';
import { GraphDescription } from './types';


function getColorForId(id: string) {
    const value = id
        .split('')
        .reduce((acc: number, currentCharacter: string) => acc + currentCharacter.charCodeAt(0), 0);
    return `hsl(${value % 360}, 100%, 65%)`;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LoadingIndicator = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    animation: ${fadeIn};
    animation-duration: 2s;
`;

const Container = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.backgroundColor};
      border: 1px solid ${props => props.theme.subtleAccentColor};
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.backgroundColor};
      border: 0px none #ffffff;
      border-radius: 53px;
    }
    ::-webkit-scrollbar-track:hover {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-track:active {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
`;

const EmptyStateMessage = styled.div`
    color: ${props => props.theme.textColor};
    font-size: 18px;
    user-select: none;
`;

const ListContainer = styled.div`
    padding: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-gap: 50px;
`;

const GraphListItem = styled(Link)<{ color: string }>`
    background-color: ${({color}) => color};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
    text-align: center;
    
    border-radius: 16px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.21);
    
    transition: transform .2s;
    
    &:hover {
        border: 2px solid ${props => props.theme.primaryColor};
        transform: scale(1.05);
    }
`;

const DeleteButton = styled.button`
    position: absolute;
    top: 4px;
    right: 4px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    
    transition: transform .2s;
    
    &:hover {
        transform: scale(1.1);
    }
`;

const useGraphDeletionWorkflow = () => {
    const { status, setPending, setError, setSuccess } = useAsyncState<undefined>(undefined);
    const [graphIdState, setGraphIdState] = useState<string|undefined>(undefined);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const { deleteGraph: deleteGraphApi } = useGraphStorageApi();

    const runWorkflow = async (graphId: string) => {
        setShowPopup(false);
        setPending();

        try {
            await deleteGraphApi(graphId);
            setSuccess(undefined);
        } catch (e: any) {
            setError(e);
        }
    };

    const deleteGraph = async (graphId: string) => {
        setGraphIdState(graphId);
        setShowPopup(true);
    };

    const renderDeleteUi = () => {
        if (showPopup) {
            return (
                <Prompt
                    message={`Are you sure you want to delete this graph?`}
                    confirmationLabel={'Delete'}
                    onConfirm={() => {
                        if (graphIdState) {
                            runWorkflow(graphIdState)
                        }
                    }}
                    onCancel={() => {
                        setGraphIdState(undefined);
                        setShowPopup(false);
                    }}
                />
            );
        } else {
            return null;
        }
    };

    return {
        deleteGraph,
        renderDeleteUi,
        status
    };
};


interface Props {
    className?: string;
}

export const GraphList: React.FC<Props> = ({ className = ''}) => {
    const { data, status, setSuccess, setPending, setError } = useAsyncState<GraphDescription[]>([]);
    const { getAllGraphDescriptions } = useGraphStorageApi();
    const { deleteGraph, renderDeleteUi, status: deleteStatus } = useGraphDeletionWorkflow();
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const previousDeleteStatus = useRef<AsyncStateStatus>(deleteStatus);
    useEffect(() => {
        if(deleteStatus === AsyncStateStatus.SUCCESS && previousDeleteStatus.current !== deleteStatus) {
            setRefreshCounter(refreshCounter+1);
        }
        previousDeleteStatus.current = deleteStatus;
    }, [deleteStatus, refreshCounter])

    useEffect(() => {
        let requestAborted = false;
        const fetchData = async () => {
            setPending();
            try {
                const graphList = await getAllGraphDescriptions();
                if (!requestAborted) {
                    setSuccess(graphList);
                }
            } catch (e: any) {
                if (!requestAborted) {
                    setError(e);
                }
            }
        };
        fetchData();
        return () => { requestAborted = true; };
    }, [getAllGraphDescriptions, setError, setPending, setSuccess, refreshCounter]);

    const [hoveredGraphId, setHoveredGraphId] = useState<string|undefined>(undefined);

    const renderContent = () => {
        switch (status) {
            case AsyncStateStatus.SUCCESS: {
                if (data.length > 0) {
                    return (
                        <>
                            <ListContainer>
                                {data.map(graphDescription => {
                                    const isHovered = graphDescription.id === hoveredGraphId;
                                    return (
                                        <GraphListItem
                                            key={graphDescription.id}
                                            to={`/graph/${graphDescription.id}`}
                                            color={getColorForId(graphDescription.id)}
                                            onMouseEnter={() => setHoveredGraphId(graphDescription.id)}
                                            onMouseLeave={() => setHoveredGraphId(undefined)}
                                        >
                                            {
                                                isHovered ?
                                                    <DeleteButton
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            deleteGraph(graphDescription.id)
                                                        }}
                                                        aria-label="Delete Graph"
                                                    >
                                                        <DeleteIcon />
                                                    </DeleteButton> :
                                                    null
                                            }
                                            <p/>
                                            <GraphIcon animated={isHovered} />
                                            <p>{graphDescription.metadata.name}</p>
                                        </GraphListItem>
                                    )
                                })}
                            </ListContainer>
                            {renderDeleteUi()}
                        </>
                    );
                } else {
                    return (
                        <LoadingIndicator>
                            <EmptyStateMessage>
                                This is where your workflows go
                            </EmptyStateMessage>
                        </LoadingIndicator>
                    );
                }
            }
            case AsyncStateStatus.INITIAL:
            case AsyncStateStatus.PENDING: {
                return (
                    <LoadingIndicator>
                        <GraphIcon animated size={800} />
                    </LoadingIndicator>
                );
            }
        }
    };

    return (
        <Container className={className}>
            {renderContent()}
        </Container>
    );
};
