import { EffectCallback, useEffect, useRef } from 'react';

export const useThrottledEffect = (effect: EffectCallback, throttlingInterval: number, deps: any[] = []): void => {
    const lastExecutionTimeRef = useRef<number>(Date.now());
    const effectRef = useRef<EffectCallback>(effect);
    effectRef.current = effect;
    useEffect(() => {
        const currentTime = Date.now();
        const timeDelta = (lastExecutionTimeRef.current - currentTime) + throttlingInterval;
        if (timeDelta < 0) {
            effectRef.current();
            lastExecutionTimeRef.current = currentTime;
        } else {
            const timeoutHandle = setTimeout(() => {
                lastExecutionTimeRef.current = Date.now();
                effectRef.current();
            }, timeDelta);
            return () => clearTimeout(timeoutHandle);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [throttlingInterval, ...deps]);
};
