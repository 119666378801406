import { useCallback, useEffect, useState } from 'react';
import { useWebhookApi, Webhook } from '../api/useWebhookApi';

export const useWebhooks = (graphId: string) => {
    const [webhooks, setWebhooks] = useState<Webhook[]>([]);
    const [nodeInstancesWithPendingRequests, setNodeInstancesWithPendingRequests] = useState<number[]>([]);

    const { createWebhook: createWebhookApi, getWebhooksForGraph } = useWebhookApi();

    useEffect(() => {
        const performEffect = async () => {
            try {
                const webhooks = await getWebhooksForGraph(graphId);
                setWebhooks(webhooks);
            } catch {

            }
        }
        performEffect();
    }, [getWebhooksForGraph, graphId]);

    const createWebhook = useCallback(async (nodeInstanceId: number) => {
        try {
            setNodeInstancesWithPendingRequests([...nodeInstancesWithPendingRequests, nodeInstanceId]);
            const newWebhook = await createWebhookApi(graphId, nodeInstanceId)
            setWebhooks([...webhooks.filter(hook => hook.nodeInstanceId !== nodeInstanceId), newWebhook]);
        } catch {

        } finally {
            setNodeInstancesWithPendingRequests(nodeInstancesWithPendingRequests.filter(i => i !== nodeInstanceId));
        }
    }, [createWebhookApi, nodeInstancesWithPendingRequests, graphId, webhooks]);

    return {
        webhooks,
        nodeInstancesWithPendingRequests,
        createWebhook
    }
}
