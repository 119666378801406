import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import {
    DataConnection,
    DataGlueGraph,
    DataNodeInstance
} from '@glueapp/graphexecution/lib/types/Graph';
import {
    BooleanPrimitive,
    createSingleNodeInstance,
    isDataGlueGraph,
    NumberPrimitive,
    TextPrimitive
} from '@glueapp/graphexecution/lib';
import { getAverageVector } from '@glueapp/graphexecution/lib/helpers/Vector';
import { screenToWorldCoordinate } from './helpers/coordinateSystemMapping';
import { addVectors, subtractVectors } from '../helpers/Vector';

interface CopyAndPasteContent {
    nodeInstances: DataNodeInstance[];
    connections: DataConnection[];
}

export const useCopyAndPaste = (
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number,
    graph: DataGlueGraph,
    mergeInGraph: (nodes: DataNodeInstance[], connections: DataConnection[], deselectOld: boolean) => void
) => {
    const graphRef = useRef(graph);
    graphRef.current = graph;

    const handleCopy = useCallback((event: ClipboardEvent) => {
        if (document.activeElement === containerRef.current && event.clipboardData) {
            const nodeInstances = graphRef.current.nodeInstances;
            const connections = graphRef.current.connections;
            const selectedNodeInstances = nodeInstances.filter(nodeInstance => nodeInstance.selected);
            const selectedNodeInstanceIds = new Set(selectedNodeInstances.map(nodeInstance => nodeInstance.id));
            const selectedConnections = connections.filter(connection =>
                selectedNodeInstanceIds.has(connection.sendingNodeInstanceId) && selectedNodeInstanceIds.has(connection.receivingNodeInstanceId)
            );
            const copyAndPasteContent: CopyAndPasteContent = {
                nodeInstances: selectedNodeInstances,
                connections: selectedConnections
            }
            event.clipboardData.setData('text/plain', JSON.stringify(copyAndPasteContent));
            event.preventDefault();
        }
    }, [containerRef]);

    useEffect(() => {
        document.addEventListener('copy', handleCopy);
        return () => document.removeEventListener('copy', handleCopy);
    }, [handleCopy]);


    const handlePaste = useCallback((event: ClipboardEvent) => {
        const containerElement = containerRef.current;
        if(containerElement && document.activeElement === containerRef.current && event.clipboardData) {
            const viewCenter = screenToWorldCoordinate(
                { x: containerElement.clientWidth / 2, y: containerElement.clientHeight / 2 },
                {
                    x: containerElement.scrollLeft,
                    y: containerElement.scrollTop
                },
                zoomLevel
            );
            const clipBoardText = event.clipboardData?.getData('text/plain');
            try {
                // JSON parsing cases objects, boolean and number
                const parsedClipBoard = JSON.parse(clipBoardText);
                switch (typeof parsedClipBoard) {
                    case 'object': {
                        if(isDataGlueGraph(parsedClipBoard)) {
                            const centerOfNodes = getAverageVector(parsedClipBoard.nodeInstances.map(nodeInstance => nodeInstance.position));
                            mergeInGraph(
                                parsedClipBoard.nodeInstances.map(
                                    node => ({
                                        ...node,
                                        position: addVectors(subtractVectors(node.position, centerOfNodes), viewCenter)
                                    })
                                ),
                                parsedClipBoard.connections,
                                true
                            );
                        } else {
                            // TODO Create an object or list here
                        }
                        break;
                    }
                    case 'boolean': {
                        mergeInGraph(
                            [createSingleNodeInstance(0, BooleanPrimitive.id, viewCenter, true, { 'Input': parsedClipBoard })],
                            [],
                            true
                        );
                        break;
                    }

                    case 'number': {
                        mergeInGraph(
                            [createSingleNodeInstance(0, NumberPrimitive.id, viewCenter, true, { 'Number': parsedClipBoard })],
                            [],
                            true
                        );
                    }
                }
            } catch {
                // If JSON parsing doesn't work, just use text
                if(!clipBoardText) {
                    return;
                }
                mergeInGraph(
                    [createSingleNodeInstance(0, TextPrimitive.id, viewCenter, true, { 'Input': clipBoardText })],
                    [],
                    true
                );
            }
        }
    }, [containerRef, mergeInGraph, zoomLevel]);

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => document.removeEventListener('paste', handlePaste);
    }, [handlePaste]);
};
