import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoginApi } from './useLoginApi';
import jwtDecode from 'jwt-decode';
import { getEnvironmentConfig } from '../environmentConfig';

interface User {
    email: string;
}

interface IDToken {
    at_hash: string;
    sub: string;
    aud: string;
    email_verified: boolean,
    token_use: string;
    auth_time: number,
    iss: string;
    'cognito:username': string;
    exp: number,
    iat: number,
    email: string
}

interface AccessToken {
    sub: string;
    token_use: string;
    scope: string;
    auth_time: number,
    iss: string;
    exp: number,
    iat: number,
    version: number,
    jti: string;
    client_id: string;
    username: string;
}

interface AuthenticationCredentials {
    idToken: IDToken;
    accessToken: AccessToken;
    refreshToken: string;
}


interface RawAuthenticationCredentials {
    idToken: string;
    accessToken: string;
    refreshToken: string;
}


export function getDecodedAuthenticationCredentialsFromLocalStorage(): AuthenticationCredentials|null {
    const rawCredentials = getAuthenticationCredentialsFromLocalStorage();
    if (rawCredentials) {
        return decodeAuthenticationCredentials(rawCredentials);
    }
    return null;
}

export function getAuthenticationCredentialsFromLocalStorage(): RawAuthenticationCredentials|null {
    const localStorageItem = localStorage.getItem('GLUE_AUTHENTICATION');
    if (localStorageItem) {
        const parsedItem = JSON.parse(localStorageItem);
        if (
            typeof parsedItem.idToken === 'string' &&
            typeof parsedItem.accessToken === 'string' &&
            typeof parsedItem.refreshToken === 'string'
        ) {
            return {
                idToken: parsedItem.idToken,
                accessToken: parsedItem.accessToken,
                refreshToken: parsedItem.refreshToken
            };
        }
    }

    return null;
}

function setAuthenticationCredentialsToLocalStorage(authenticationCredentials: RawAuthenticationCredentials): void {
    localStorage.setItem('GLUE_AUTHENTICATION', JSON.stringify(authenticationCredentials));
}
function clearAuthenticationCredentialsFromLocalStorage(): void {
    localStorage.removeItem('GLUE_AUTHENTICATION');
}

export function decodeAuthenticationCredentials(rawAuthCredentials: RawAuthenticationCredentials): AuthenticationCredentials {
    return {
        idToken: jwtDecode(rawAuthCredentials.idToken),
        accessToken: jwtDecode(rawAuthCredentials.accessToken),
        refreshToken: rawAuthCredentials.refreshToken
    };
}

interface AuthenticationContextType {
    user: User | null;
    login: () => void;
    logout: () => void;
    setAuthenticationCredentials: (idToken: string, accessToken: string, refreshToken: string) => void;
    authWorkflow: () => Promise<void>;
    reAuthenticate: () => Promise<void>;
    credentialsAreAboutToExpire: () => boolean;
    credentialsAreValid: () => boolean;
}


const AuthenticationContext = React.createContext<AuthenticationContextType>({
    user: null,
    login: () => {},
    logout: () => {},
    setAuthenticationCredentials: () => {},
    authWorkflow: async () => {},
    reAuthenticate: async () => {},
    credentialsAreAboutToExpire: () => false,
    credentialsAreValid: () => false
});

export const AuthenticationProvider: React.FC<{}> = props => {
    const [user, setUser] = useState<User|null>(null);
    const history = useHistory();

    const { refreshLogin } = useLoginApi();


    const credentialsAreAboutToExpire = useCallback(() => {
        const credentials = getDecodedAuthenticationCredentialsFromLocalStorage();
        if(credentials === null) {
            return false;
        }
        const currentTime = new Date().getTime();
        return currentTime + 30000 > credentials.idToken.exp*1000  &&
            currentTime + 30000 > credentials.accessToken.exp*1000;
    }, []);

    const credentialsAreValid = useCallback(() => {
        const credentials = getDecodedAuthenticationCredentialsFromLocalStorage();
        if(credentials === null) {
            return false;
        }
        const currentTime = new Date().getTime();
        return credentials.idToken.exp*1000 > currentTime &&
            credentials.accessToken.exp*1000 > currentTime;
    }, []);

    const login = useCallback(() => {
        window.location.href = `${getEnvironmentConfig().authenticationServiceUrl}/login?frontendRedirectUrl=${window.origin}/login-success`;
    }, []);

    const reAuthenticate = useCallback(async () => {
        const rawAuthCredentials = getAuthenticationCredentialsFromLocalStorage();
        if (rawAuthCredentials) {
            const decodedAuthenticationCredentials = decodeAuthenticationCredentials(rawAuthCredentials);
            try {
                const refreshedTokens = await refreshLogin(decodedAuthenticationCredentials.refreshToken);
                const rawRefreshedCredentials: RawAuthenticationCredentials = {
                    idToken: refreshedTokens.idToken,
                    accessToken: refreshedTokens.accessToken,
                    refreshToken: decodedAuthenticationCredentials.refreshToken
                };
                setAuthenticationCredentialsToLocalStorage(rawRefreshedCredentials);
                setUser({ email: decodedAuthenticationCredentials.idToken.email });
            } catch {
                history.push('/login');
            }
        } else {
            history.push('/login');
        }
    }, [history, refreshLogin]);

    const authWorkflow = useCallback(async () => {
        const rawAuthCredentials = getAuthenticationCredentialsFromLocalStorage();
        if (rawAuthCredentials) {
            if (!credentialsAreValid()) {
                await reAuthenticate();
            } else {
                const decodedAuthenticationCredentials = decodeAuthenticationCredentials(rawAuthCredentials);
                setUser({ email: decodedAuthenticationCredentials.idToken.email });
            }
        } else {
            history.push('/login');
        }

    }, [history, reAuthenticate, credentialsAreValid]);

    useEffect(() => {
        authWorkflow().then(() => {}).catch(() => {});
    }, [authWorkflow]);


    const logout = useCallback(() => {
        clearAuthenticationCredentialsFromLocalStorage();
        history.push('/login');
    }, [history]);


    const setAuthenticationCredentials = useCallback((idToken: string, accessToken: string, refreshToken: string) => {
        const rawCredentials: RawAuthenticationCredentials = { idToken, accessToken, refreshToken };
        setAuthenticationCredentialsToLocalStorage(rawCredentials);
    }, []);

    return (
        <AuthenticationContext.Provider value={{
            user,
            login,
            logout,
            setAuthenticationCredentials,
            authWorkflow,
            reAuthenticate,
            credentialsAreAboutToExpire,
            credentialsAreValid
        }}>
            {props.children}
        </AuthenticationContext.Provider>
    );
};



export const useAuthentication = (): AuthenticationContextType => {
    return useContext(AuthenticationContext);
};