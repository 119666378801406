import styled from 'styled-components';

export const NavigationLayer = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
`;

export const CentralGraphOverlayMessage = styled.h2`
    text-align: center;
    color: ${props => props.theme.primaryContrastColor};
`;