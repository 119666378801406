import { Vector } from '../../helpers/Vector';
import styled from 'styled-components';

interface Props {
    position: Vector;
    color: string;
    backgroundColor: string;
}

export const GraphMessage = styled.div<Props>`
    position: absolute;
    left: ${props => props.position.x}px;
    top: ${props => props.position.y}px;
    pointer-events: none;
    color: ${props => props.color}; 
    background-color: ${props => props.backgroundColor};
    border-radius: 4px;
    padding: 8px;
    transform: translate(-50%, -50%);
`;
