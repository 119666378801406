import * as React from 'react';
import { TextAreaInputField, ValueEditorContainer } from '@glueapp/component-library';
import { StringDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';

interface StringValueEditorProps {
    type: StringDataType;
    value: string;
    userDefinedType?: UserDefinedType;
    onChange: (value: string, updatedType?: UserDefinedType) => void;
}

export const StringValueEditor: React.FC<StringValueEditorProps> = ({ type, value, onChange }) => {
    return (
        <ValueEditorContainer>
            <TextAreaInputField placeholder="Text" value={value} onChange={e => onChange(e.target.value)} />
        </ValueEditorContainer>
    );
};