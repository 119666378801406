import { MouseEvent, MutableRefObject, useCallback, useState, PointerEvent, useRef } from 'react';
import { subtractVectors, Vector } from '../helpers/Vector';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';

export interface NodeDrag {
    lastMousePosition: Vector;
}
export function useNodeDrag(
    moveNodes: (delta: Vector) => void,
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number
) {
    const [nodeDrag, setNodeDrag] = useState<NodeDrag|null>(null);
    const zoomLevelRef = useRef<number>(zoomLevel);
    zoomLevelRef.current = zoomLevel;


    const startNodeDrag = useCallback((event: PointerEvent) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const mousePosition = getInElementPositionFromEvent(event, containerElement);

            setNodeDrag({
                lastMousePosition: {
                    x: mousePosition.x / zoomLevelRef.current,
                    y: mousePosition.y / zoomLevelRef.current
                }
            });
        }
    }, [containerRef]);

    const updateNodeDrag = useCallback((event: MouseEvent) => {
        const containerElement = containerRef.current;
        if (nodeDrag && containerElement) {
            const mouseScreenPosition = getInElementPositionFromEvent(event, containerElement);
            const mouseWorldPosition = { x: mouseScreenPosition.x / zoomLevelRef.current, y: mouseScreenPosition.y / zoomLevelRef.current };
            const mouseMovement = subtractVectors(mouseWorldPosition, nodeDrag.lastMousePosition);
            moveNodes(mouseMovement);
            setNodeDrag({ lastMousePosition: mouseWorldPosition });
        }
    }, [containerRef, nodeDrag, moveNodes]);

    return {
        nodeDrag,
        setNodeDrag,
        startNodeDrag,
        updateNodeDrag
    };
}