import { DataGlueGraph } from '@glueapp/graphexecution/lib/types/Graph';
import { ValidationResult } from '@glueapp/graphexecution/lib/validation/validateGraph';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ExecutionError, ExecutionResult, GraphExecutor } from '@glueapp/graphexecution/lib/executeGraph/executeGraph';
import { NODE_LIBRARY } from '@glueapp/graphexecution/lib/nodeLibrary';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { useIntegrationAccessApi } from '../api/useIntegrationAccessApi';

export type NodeRunRecordDictionary = {
    [key: number]: Set<number>;
}

export const useGraphExecution = (
    graph: DataGlueGraph|undefined,
    validationResult: ValidationResult|undefined,
    graphValid: boolean
) => {
    const [executionResult, setExecutionResult] = useState<ExecutionResult|undefined>(undefined);
    const [executionError, setExecutionError] = useState<ExecutionError|undefined>(undefined);
    const [executionRunning, setExecutionRunning] = useState<boolean>(false)

    const { callIntegration } = useIntegrationAccessApi();

    const graphExecutor = useRef<GraphExecutor|undefined>(undefined);

    const resetExecution = () => {
        setExecutionResult(undefined);
        setExecutionError(undefined);
    };

    const updateRanNodesFromExecutor = useCallback(() => {
        const currentExecutor = graphExecutor.current;
        if (currentExecutor) {
            const runRecords = currentExecutor.getRunRecords();
            const newRunRecord: NodeRunRecordDictionary = {};
            for (let record of runRecords) {
                if (!newRunRecord[record.nodeInstanceId]) {
                    newRunRecord[record.nodeInstanceId] = new Set([record.timeRun]);
                }
                newRunRecord[record.nodeInstanceId].add(record.timeRun);
            }
            setRanNodes(newRunRecord);
        }
    }, []);

    const [ranNodes, setRanNodes] = useState<NodeRunRecordDictionary>({});
    useEffect(() => {
        if (executionRunning) {
            updateRanNodesFromExecutor();
            const intervalId = window.setInterval(updateRanNodesFromExecutor, 50);

            return () => {
                updateRanNodesFromExecutor();
                clearInterval(intervalId);
            }
        }
    }, [executionRunning, updateRanNodesFromExecutor]);


    const performExecuteGraphWorkflow = (triggerNodeInstanceId: number) => {
        async function performEffect() {
            if (graph && validationResult && graphValid) {
                try {
                    setExecutionRunning(true);
                    graphExecutor.current = new GraphExecutor(callIntegration, true);
                    const executionResult = await graphExecutor.current.executeGraph(
                        graph,
                        NODE_LIBRARY,
                        { nodeInstanceId: triggerNodeInstanceId, payload: {} }
                    );
                    updateRanNodesFromExecutor();
                    setExecutionRunning(false);
                    graphExecutor.current = undefined;
                    setExecutionError(undefined);
                    setExecutionResult(executionResult);
                } catch (e: any) {
                    // Intentional console.log to surface potential execution errors to users and developers
                    console.log(e);
                    setExecutionRunning(false);
                    setExecutionResult(undefined);
                    setExecutionError(e);
                }
            } else {
                setExecutionResult(undefined);
                setExecutionError(undefined);
            }
        }
        performEffect();
    };
    const performExecuteGraphWorkflowRef = useRef(performExecuteGraphWorkflow);
    performExecuteGraphWorkflowRef.current = performExecuteGraphWorkflow;

    const staticExecute = useCallback((triggerNodeInstanceId: number) => {
        performExecuteGraphWorkflowRef.current(triggerNodeInstanceId);
    }, [])


    return {
        executionResult,
        setExecutionResult,
        executionError,
        setExecutionError,
        triggerExecution: staticExecute,
        executionRunning,
        resetExecution,
        ranNodes
    }
}
