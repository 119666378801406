import React from 'react';
import styled from 'styled-components';
import { InputValueEditor } from './InputValueEditor/InputValueEditor';
import { NodeInstanceDataPort, UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';
import { DataValue, getDefaultValueForDataType } from '@glueapp/graphexecution/lib/types/DataValue';
import { getColorFromDataType } from '../GraphEditor/dataLayer/conversions/getColorFromDataType';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
`;


interface InputLabelProps {
    backgroundColor: string;
}

const InputLabel = styled.div<InputLabelProps>`
    background: ${props => props.backgroundColor};
    color: white;
    padding: 4px;
    border-radius: 4px;
`;

const DisabledLabel = styled.p`
    color: ${props => props.theme.disabledText};
`;


interface Props {
    inPort: NodeInstanceDataPort;
    portValue: DataValue;
    portUserDefinedType: UserDefinedType;
    hasConnection?: boolean;
    onValueChange: (inPort: NodeInstanceDataPort, value: DataValue, type?: UserDefinedType) => void;
}

const BasePortValueEditor: React.FC<Props> = ({
    inPort,
    portValue,
    portUserDefinedType,
    onValueChange,
    hasConnection = false
}) => {
    return (
        <InputContainer key={inPort.name}>
            <InputLabel backgroundColor={inPort.type !== undefined ? getColorFromDataType(inPort.type): 'black'}>{inPort.name}</InputLabel>
            {hasConnection ?
                <DisabledLabel>Covered by Connection</DisabledLabel> :
                (inPort.type === undefined || inPort.type === null) ?
                    <DisabledLabel>No type determined for port</DisabledLabel> :
                    <InputValueEditor
                        type={inPort.type}
                        value={portValue !== undefined ? portValue : getDefaultValueForDataType(inPort.type)}
                        userDefinedType={portUserDefinedType}
                        onChange={(newValue, newType) => onValueChange(inPort, newValue, newType)}
                    />
            }
        </InputContainer>
    );
};

export const PortValueEditor = React.memo(BasePortValueEditor);
