import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Vector } from '../helpers/Vector';
import { Bounds, getBoundsHeight, getBoundsWidth } from './helpers/bounds';


export function useZooming(
    latestMousePosition: MutableRefObject<Vector>,
    containerRef: MutableRefObject<HTMLDivElement|null>
) {

    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [scrollChange, setScrollChange] = useState<number>(0);
    const [newScrollPosition, setNewScrollPosition] = useState<Vector|null>(null);

    useLayoutEffect(() => {
        if (scrollChange !== 0) {
            const containerElement = containerRef.current;
            if (containerElement) {
                containerElement.scrollBy(
                    (containerElement.scrollLeft + latestMousePosition.current.x) * (scrollChange - 1), // + e.deltaX,
                    (containerElement.scrollTop + latestMousePosition.current.y) * (scrollChange - 1)
                );
            }
            setScrollChange(0);
        }
    }, [scrollChange, containerRef, latestMousePosition])

    useLayoutEffect(() => {
        if (newScrollPosition !== null) {
            const containerElement = containerRef.current;
            if (containerElement) {
                containerElement.scrollTo(newScrollPosition.x, newScrollPosition.y);
            }
            setNewScrollPosition(null);
        }
    }, [containerRef, newScrollPosition])



    const handleZoom = useCallback((e: WheelEvent) => {
        e.preventDefault();
        const scrollChange: number = 1 + e.deltaY * -0.0015;
        setZoomLevel(zoomLevel * scrollChange);
        setScrollChange(scrollChange);
    }, [zoomLevel]);

    const frameBoundsInView = useCallback((bounds: Bounds): void => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const boundsWidth = getBoundsWidth(bounds)
            const boundsHeight = getBoundsHeight(bounds);
            const { width: containerWidth, height: containerHeight } = containerElement.getBoundingClientRect();
            const widthRatio = containerWidth / boundsWidth;
            const heightRatio = containerHeight / boundsHeight;
            const minRatio = Math.min(widthRatio, heightRatio);
            const newZoomLevel = Math.min(minRatio * 0.9, 1);
            const zoomViewportWidth = containerWidth / newZoomLevel;
            const zoomViewportHeight = containerHeight / newZoomLevel;
            let xOffset = zoomViewportWidth * 0.5 - boundsWidth * 0.5;
            let yOffset = zoomViewportHeight * 0.5 - boundsHeight * 0.5;

            setZoomLevel(newZoomLevel);
            setNewScrollPosition({
                x: (bounds.topLeft.x - xOffset) * newZoomLevel,
                y: (bounds.topLeft.y - yOffset) * newZoomLevel
            });
        }
    }, [containerRef]);


    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleZoom, { passive: false });
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleZoom);
            }
        }
    }, [containerRef, handleZoom])

    return {
        zoomLevel,
        frameBoundsInView
    }
}