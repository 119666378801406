import { Dispatch, Reducer, ReducerAction, useCallback, useReducer } from 'react';

interface UndoRedoReducerState<S> {
    past: S[],
    present: S,
    actualState: S,
    future: S[]
}

export function useUndoRedoReducer<R extends Reducer<S, any>, S>(
    reducer: R,
    initialValue: S,
    ignoredActions: Set<string> = new Set([]),
    historyLimit: number = 100
): {
    state: S,
    dispatch: Dispatch<ReducerAction<R>>,
    undo: () => void,
    redo: () => void,
    makeCurrentStateBaseline: () => void
} {
    const initialState = {
        past: [],
        present: initialValue,
        actualState: initialValue,
        future: []
    }
    const internalReducer = useCallback(function (state: UndoRedoReducerState<S>, action: { type: string }): UndoRedoReducerState<S> {
        const { past, present, future, actualState } = state;
        switch (action.type) {
            case 'UNDO': {
                if (past.length > 0) {
                    const previous = past[past.length - 1]
                    const newPast = past.slice(0, past.length - 1)
                    return {
                        past: newPast,
                        present: previous,
                        actualState: previous,
                        future: [present, ...future]
                    }
                }
                return state;
            }
            case 'REDO': {
                if (future.length > 0) {
                    const next = future[0]
                    const newFuture = future.slice(1)
                    return {
                        past: [...past, present],
                        present: next,
                        actualState: next,
                        future: newFuture
                    }
                }
                return state;
            }
            case 'MAKE_BASELINE': {
                return {
                    past: [],
                    present: state.actualState,
                    actualState: state.actualState,
                    future: []
                }
            }
            default:
                // All other actions get delegated to the reducer passed to the hook
                const newState = reducer(actualState, action)
                if (present === newState) {
                    return state
                }
                if (ignoredActions.has(action.type)) {
                    return {
                        past: past,
                        present: present,
                        actualState: newState,
                        future: future
                    }
                }
                return {
                    past: [...past.slice(-1 * historyLimit), present],
                    present: newState,
                    actualState: newState,
                    future: []
                }
        }
    }, [ignoredActions, reducer, historyLimit])

    const [state, dispatch] = useReducer(internalReducer, initialState);

    const undo = useCallback(() => dispatch({ type: 'UNDO' }), []);
    const redo = useCallback(() => dispatch({ type: 'REDO' }), []);
    const makeBaseline = useCallback(() => dispatch({ type: 'MAKE_BASELINE' }), []);

    return {
        state: state.actualState,
        dispatch,
        undo,
        redo,
        makeCurrentStateBaseline: makeBaseline
    }
}
