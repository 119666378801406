import React, { MouseEvent, MutableRefObject, useCallback, useContext, useRef, useState } from 'react';
import { Vector } from '../helpers/Vector';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';
import { screenToWorldCoordinate } from './helpers/coordinateSystemMapping';
import { ViewDrag } from './useViewDrag';
import { SCurve } from './BaseComponents/SCurve';
import {
    ConnectionPortType,
    DataConnection,
    DataGlueGraph,
    DataNodeInstance
} from '@glueapp/graphexecution/lib/types/Graph';
import { ThemeType } from '@glueapp/component-library';
import { ThemeContext } from 'styled-components';
import { NodePortPositions } from './GraphEditorDrawing';

function isValidConnection(connection: Omit<DataConnection, 'id'>): boolean {
    return true;
}

export interface ConnectionDrag {
    connectionDragStartNodeId: number;
    connectionDragStartPortName: string;
    connectionDragStartPortType: ConnectionPortType;
}

export function useConnectionDrag(
    graph: DataGlueGraph,
    nodePortPositions: NodePortPositions,
    addConnection: (connection: Omit<DataConnection, 'id'>) => void,
    removeConnection: (index: number) => void,
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number,
    setViewDrag: (value: ViewDrag|null) => void
) {
    const [connectionDrag, setConnectionDrag] = useState<ConnectionDrag|null>(null);
    const [connectionDragPosition, setConnectionDragPosition] = useState<Vector|null>(null);
    const connections = graph.connections;
    const theme = useContext<ThemeType>(ThemeContext);
    const zoomLevelRef = useRef<number>(zoomLevel);
    zoomLevelRef.current = zoomLevel;

    const handleConnectionDragMove = useCallback((event: MouseEvent) => {
        const containerElement = containerRef.current;
        if (containerElement && connectionDrag) {

            setConnectionDragPosition(
                screenToWorldCoordinate(
                    getInElementPositionFromEvent(
                        event,
                        containerElement
                    ),
                    { x: containerElement.scrollLeft, y: containerElement.scrollTop },
                    zoomLevelRef.current
                )
            );
        }
    }, [connectionDrag, containerRef]);

    const handleOutPortMouseDown = useCallback((event: MouseEvent, node: DataNodeInstance, portName: string, portType: ConnectionPortType = 'external') => {
        const containerElement = containerRef.current;
        if (containerElement) {
            setConnectionDrag({
                connectionDragStartNodeId: node.id,
                connectionDragStartPortName: portName,
                connectionDragStartPortType: portType
            });
            setConnectionDragPosition(
                screenToWorldCoordinate(
                    getInElementPositionFromEvent(event, containerElement),
                    {
                        x: containerElement.scrollLeft,
                        y: containerElement.scrollTop
                    },
                    zoomLevelRef.current
                )
            );
        }
    }, [containerRef]);

    const handleInPortMouseUp = useCallback((event: MouseEvent, node: DataNodeInstance, portName: string, portType: ConnectionPortType = 'external') => {
        if (connectionDrag) {
            // Don't create connections that would connect a trigger port with a non-trigger port
            const newConnection = {
                sendingNodeInstanceId: connectionDrag.connectionDragStartNodeId,
                sendingPortName: connectionDrag.connectionDragStartPortName,
                sendingPortType: connectionDrag.connectionDragStartPortType,
                receivingNodeInstanceId: node.id,
                receivingPortName: portName,
                receivingPortType: portType
            }

            if (isValidConnection(newConnection)) {
                addConnection(newConnection);
            }
            setConnectionDrag(null);
            setConnectionDragPosition(null);
            setViewDrag(null);
        }
    }, [connectionDrag, addConnection, setViewDrag]);

    const handleInPortMouseDown = useCallback((event: MouseEvent, node: DataNodeInstance, portName: string) => {
        const index = connections.findIndex(
            connection => connection.receivingNodeInstanceId === node.id && connection.receivingPortName === portName
        );
        const containerElement = containerRef.current;
        if (index !== -1 && containerElement) {
            // Start drag of new connection based on old.
            const connection = connections[index];
            setConnectionDrag({
                connectionDragStartNodeId: connection.sendingNodeInstanceId,
                connectionDragStartPortName: connection.sendingPortName,
                connectionDragStartPortType: connection.sendingPortType ?? 'external'
            });
            setConnectionDragPosition(
                screenToWorldCoordinate(
                    getInElementPositionFromEvent(event, containerElement),
                    {
                        x: containerElement.scrollLeft,
                        y: containerElement.scrollTop
                    },
                    zoomLevelRef.current
                )
            );
            // Clear old connection
            removeConnection(index);
        }
    }, [connections, removeConnection, containerRef]);

    const handleConnectionMouseDown = useCallback((event: MouseEvent, connection: DataConnection) => {
        const index = connections.indexOf(connection);

        const containerElement = containerRef.current;
        if (index !== -1 && containerElement) {
            // Start drag of new connection based on old.
            setConnectionDrag({
                connectionDragStartNodeId: connection.sendingNodeInstanceId,
                connectionDragStartPortName: connection.sendingPortName,
                connectionDragStartPortType: connection.sendingPortType ?? 'external'
            });
            setConnectionDragPosition(
                screenToWorldCoordinate(
                    getInElementPositionFromEvent(event, containerElement),
                    {
                        x: containerElement.scrollLeft,
                        y: containerElement.scrollTop
                    },
                    zoomLevelRef.current
                )
            );
            // Clear old connection
            removeConnection(index);
        }
    }, [connections, removeConnection, containerRef]);


    const drawConnectionDrag = () => {
        if (connectionDrag && connectionDragPosition) {
            const sendingPortPosition = nodePortPositions[connectionDrag.connectionDragStartNodeId]?.outPortPositions[connectionDrag.connectionDragStartPortName];
            if (sendingPortPosition) {

                const endPosition = connectionDragPosition;
                const color = theme.primaryContrastColor;

                return (
                    <g>
                        <SCurve strokeColor={color} startPosition={sendingPortPosition} endPosition={endPosition} />
                        <polygon
                            transform={`translate(${endPosition.x}, ${endPosition.y})`}
                            points={`0 5, 10 0, 0 -5`}
                            fill={color}
                        />
                    </g>
                );
            }
        }
    };

    return {
        isConnectionDragActive: connectionDrag !== null,
        setConnectionDrag,
        handleConnectionDragMove,
        handleOutPortMouseDown,
        handleInPortMouseUp,
        handleInPortMouseDown,
        handleConnectionMouseDown,
        drawConnectionDrag
    }
}