import * as React from 'react';
import styled from 'styled-components';


export const Key = styled.div`
    padding: 8px;
    border-radius: 8px;
    font-weight: bold;
    min-width: 16px;
    color: ${props => props.theme.backgroundColor};
    background: ${props => props.theme.primaryContrastColor};
`;

interface Props {
    label: string;
}

export const KeyIcon: React.FC<Props> = ({ label }) => {
    return (
        <Key>
            {label}
        </Key>
    );
};
