import { useEffect, useRef, useState } from 'react';

export function useRerenderOnElementResize<T extends HTMLElement = HTMLDivElement>() {
    const [, setForceRerender] = useState<number>(0);
    const elementRef = useRef<T>(null);
    useEffect(() => {
        const currentElement = elementRef.current;
        if(currentElement) {
            const resizeObserver = new ResizeObserver((e) => {
                setForceRerender(n => n+1);
            })
            resizeObserver.observe(currentElement);

            return () => {
                resizeObserver.disconnect();
            }
        }
    }, []);

    return {
        elementRef
    }
}