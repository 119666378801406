import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../../base/ArrowIcon';
import { NodeInstanceDataPort } from '@glueapp/graphexecution/lib/types/Graph';
import { DataValue, isTriggerValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { RainbowBackgroundAnimation } from '../../../base/GlueRainbowBackgroundAnimation';


const OutputValueContainer = styled.div`
    position: absolute;
    left: 12px;
    top: -18px;
    pointer-events: all;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;


interface OutputValueDisplayContainerProps {
    semiOpaque: boolean;
}

const OutputValueDisplayContainer = styled.div<OutputValueDisplayContainerProps>`
    margin-left: 4px;
    
    padding: 8px;
    pointer-events: all;
    border-radius: 8px;
    text-align: left;
    max-width: 50vw;
    max-height: 60vh;
    overflow: scroll;
    white-space: pre;
    overflow-wrap: break-word;
    color: ${props => props.theme.textColor};
    border: 2px solid ${props => props.theme.separatorColor};
    background: ${props => props.theme.backgroundColor};
    opacity: ${props => props.semiOpaque ? 0.4 : 1};
    
    overscroll-behavior: contain;
    
    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.backgroundColor};
      border: 1px solid ${props => props.theme.subtleAccentColor};
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.backgroundColor};
      border: 0 none #ffffff;
      border-radius: 53px;
    }
    ::-webkit-scrollbar-track:hover {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-track:active {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    
    
    & > p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`;

const BaseTriggerIndicator = styled.div`
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.theme.separatorColor};
`;
const RunningTriggerIndicator = styled(BaseTriggerIndicator)`
    ${RainbowBackgroundAnimation}
`
const NotRunningTriggerIndicator = styled(BaseTriggerIndicator)`
    background: ${props => props.theme.backgroundColor};
`;

const Square = styled.div`
    width: 12px;
    height: 12px;
    background: ${props => props.theme.negativeAccentColor};
`;

const ShowOutputButton = styled.button`
    padding: 8px;
    border-radius: 50%;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.theme.separatorColor};
    background: ${props => props.theme.backgroundColor};
    
    &:hover {
        cursor: pointer;
        border: 2px solid ${props => props.theme.primaryContrastColor};
        background: ${props => props.theme.subtleAccentColor};
    }
`;

interface OutputValueDisplayProps {
    isHovered: boolean;
    isOpen: boolean;
    result: DataValue;
}

const OutputValueDisplay: React.FC<OutputValueDisplayProps> = ({isHovered, isOpen, result }) => {
    const getText = () => {
        switch (typeof result) {
            case 'undefined':
            case 'object':
            case 'function':
            case 'symbol':
                return { __html: JSON.stringify(result, null, 4)}

            case 'boolean':
            case 'number':
            case 'bigint':
                return { __html: String(result)}
            case 'string':
                return { __html: `"${result}"` }
        }
    }
    return (
        <OutputValueDisplayContainer semiOpaque={isHovered && !isOpen} dangerouslySetInnerHTML={getText()} />
    );
};

export const OutputValue: React.FC<{ outPort: NodeInstanceDataPort, result: DataValue }> = ({
    outPort,
    result
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const renderButton = () => {
        switch (outPort.type?.kind) {
            case 'trigger':{
                if(isTriggerValue(result) && result.running) {
                    return (
                        <RunningTriggerIndicator>
                            <ArrowIcon />
                        </RunningTriggerIndicator>
                    );
                } else {
                    return (
                        <NotRunningTriggerIndicator>
                            <Square />
                        </NotRunningTriggerIndicator>
                    );
                }
            }
            default: {
                return (
                    <ShowOutputButton
                        onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen); }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <ArrowIcon />
                    </ShowOutputButton>
                );
            }
        }
    }

    return (
        <OutputValueContainer>
            {renderButton()}
            {(isOpen || isHovered) && <OutputValueDisplay isOpen={isOpen} isHovered={isHovered} result={result} />}
        </OutputValueContainer>
    );
};
