import React, { MouseEvent, MutableRefObject, useCallback, useContext, useState } from 'react';
import { subtractVectors, Vector } from '../helpers/Vector';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';
import { screenToWorldCoordinate } from './helpers/coordinateSystemMapping';
import {
    DataGlueGraph,
} from '@glueapp/graphexecution/lib/types/Graph';
import { ThemeType } from '@glueapp/component-library';
import { ThemeContext } from 'styled-components';
import { Bounds, boundsFrom2Vectors, computeNodeBounds, doBoundsIntersect } from './helpers/bounds';


export function useRectangleSelection(
    graph: DataGlueGraph,
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number,
    selectNodes: (nodeIds: number[]) => void
) {
    const [rectangleSelectionStart, setRectangleSelectionStart] = useState<Vector|null>(null);
    const [rectangleSelectionEnd, setRectangleSelectionEnd] = useState<Vector|null>(null);

    const theme = useContext<ThemeType>(ThemeContext);

    const handleSelectionStart = useCallback((event: MouseEvent) => {
        const containerElement = containerRef.current;
        if (containerElement) {

            const position = screenToWorldCoordinate(
                getInElementPositionFromEvent(
                    event,
                    containerElement
                ),
                { x: containerElement.scrollLeft, y: containerElement.scrollTop },
                zoomLevel
            );
            setRectangleSelectionStart(position);
            setRectangleSelectionEnd(position);
        }
    }, [containerRef, zoomLevel]);

    const handleSelectionMove = useCallback((event: MouseEvent) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const position = screenToWorldCoordinate(
                getInElementPositionFromEvent(
                    event,
                    containerElement
                ),
                { x: containerElement.scrollLeft, y: containerElement.scrollTop },
                zoomLevel
            );
            setRectangleSelectionEnd(position);
        }
    }, [containerRef, zoomLevel]);

    const handleRectangleSelectionEnd = useCallback(() => {
        if (rectangleSelectionStart && rectangleSelectionEnd) {
            const selectionRectangleBounds: Bounds = boundsFrom2Vectors(rectangleSelectionStart, rectangleSelectionEnd)
            const toBeSelectedNodeIds: number[] = graph.nodeInstances.filter(
                nodeInstance => {
                    const nodeBounds = computeNodeBounds(nodeInstance);
                    return doBoundsIntersect(nodeBounds, selectionRectangleBounds);
                }
            ).map(nodeInstance => nodeInstance.id);

            selectNodes(toBeSelectedNodeIds);
        }

        setRectangleSelectionStart(null);
        setRectangleSelectionEnd(null);
    }, [rectangleSelectionStart, rectangleSelectionEnd, graph.nodeInstances, selectNodes]);


    const drawRectangleSelection = () => {
        if (rectangleSelectionStart && rectangleSelectionEnd) {
            const lowestX = Math.min(rectangleSelectionStart.x, rectangleSelectionEnd.x)
            const lowestY = Math.min(rectangleSelectionStart.y, rectangleSelectionEnd.y)
            const difference = subtractVectors(rectangleSelectionStart, rectangleSelectionEnd);
            return (
                <rect
                    stroke={theme.positiveAccentColor}
                    fill={theme.positiveAccentColor}
                    strokeOpacity="70%"
                    fillOpacity="30%"
                    x={lowestX}
                    y={lowestY}
                    width={Math.abs(difference.x)}
                    height={Math.abs(difference.y)}
                />
            );
        } else {
            return null;
        }
    };


    return {
        rectangleSelectionActive: rectangleSelectionStart !== null || rectangleSelectionEnd !== null,
        handleSelectionStart,
        handleSelectionMove,
        handleRectangleSelectionEnd,
        drawRectangleSelection
    }
}