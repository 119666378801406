import { ExecutionError, ExecutionResult } from '@glueapp/graphexecution/lib';
import { useLiveSyncRegistrationApi } from '../api/useLiveSyncRegistrationApi';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';


// TODO Remvoe the duplication and import them from the BE service
export interface RunResultFile {
    graphId: string;
    error?: ExecutionError;
    result?: ExecutionResult;
}

export interface RunResultMessage {
    type: 'RunResult';
    payload: {
        runResultUrl: string;
    };
}


async function registerWebSocket(
    graphId: string,
    createConnectionAuthorization: (graphId: string) => Promise<{ connectionAuthorizationId: string }>
): Promise<WebSocket> {
    const response = await createConnectionAuthorization(graphId);
    const authorizationId = response.connectionAuthorizationId;
    const socket = new WebSocket(
        `${getEnvironmentConfig().liveSyncServiceUrl}?graphId=${graphId}&connectionAuthorizationId=${authorizationId}`
    );

    return socket;
}

function useWebSocket(graphId: string): WebSocket|null {
    const { createConnectionAuthorization } = useLiveSyncRegistrationApi();
    const [webSocket, setWebSocket] = useState<WebSocket|null>(null);

    useEffect(() => {
        async function create() {
            const socket = await registerWebSocket(graphId, createConnectionAuthorization);
            setWebSocket(socket);
        }

        create();

        return () => {
            setWebSocket(null);
        };
    }, [graphId, createConnectionAuthorization]);


    useEffect(() => {
        if (webSocket) {
            webSocket.addEventListener('open', (event) => {

            });
            webSocket.addEventListener('close', (event) => {
                setWebSocket(null);
            });
            webSocket.addEventListener('error', (event) => {
                setWebSocket(null);
            });
        }
    }, [webSocket])

    return webSocket;
}

export const useLiveSync = (
    graphId: string,
    setExecutionResult: (executionResult: ExecutionResult) => void,
    setExecutionError: (executionError: ExecutionError) => void
) => {
    const webSocket = useWebSocket(graphId);
    useEffect(() => {
        if (webSocket) {
            webSocket.addEventListener('message', (event) => {
                const data: RunResultMessage = JSON.parse(event.data);
                switch (data.type) {
                    case 'RunResult': {
                        const payload = data.payload;
                        axios.get<RunResultFile>(payload.runResultUrl).then(response => {
                            const runResultData = response.data;
                            if (runResultData.result) {
                                setExecutionResult(runResultData.result);
                            }
                            if (runResultData.error) {
                                setExecutionError(runResultData.error);
                            }
                        })
                    }
                }
            });
        }
    }, [setExecutionError, setExecutionResult, webSocket]);
}

