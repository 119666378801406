import * as React from 'react';
import { Title, ValueEditorContainer } from '@glueapp/component-library';
import { StringLiteralDataType } from '@glueapp/graphexecution/lib/types/DataType';

interface StringValueEditorProps {
    type: StringLiteralDataType;
}

export const StringLiteralValueEditor: React.FC<StringValueEditorProps> = ({ type }) => {
    return (
        <ValueEditorContainer>
            <Title>
                {type.stringLiteral}
            </Title>
        </ValueEditorContainer>
    );
};