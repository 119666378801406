import React from 'react';
import styled, { css } from 'styled-components';
import {
    VariableTypeDetermination
} from '@glueapp/graphexecution/lib/types/Graph';
import { ShapeValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { DataType } from '@glueapp/graphexecution/lib/types/DataType';
import { ShapeValueEditor } from './ShapeValueEditor/ShapeValueEditor';


const SwitcherButton = styled.button<{ active?: boolean }>`
    color: ${props => props.theme.textColor};
    background: ${props => props.theme.backgroundColor};
    border: 1px solid ${props => props.theme.separatorColor};
    font-size: 16px;
    font-weight: bold;
    padding-top: 8px;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 8px;
    white-space: nowrap;
    
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
    }
    
    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    
    ${props => props.active ? css`
        background: ${props => props.theme.positiveAccentColor};
    ` : ''}
`;

const InferredSwitcherButton = styled(SwitcherButton)`
    flex: 2;
`

const FixedSwitcherButton = styled(SwitcherButton)`
    flex: 1;
`

const TypeSwitchRow = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;


export const VariableTypeInput: React.FC<{
    dataType: DataType|undefined;
    typeDetermination: VariableTypeDetermination;
    onChange: (typeDetermination: VariableTypeDetermination, dataType: DataType|undefined) => void;
}> = ({
    dataType,
    typeDetermination,
    onChange
}) => {
    return (
        <>
            <TypeSwitchRow>
                <FixedSwitcherButton
                    active={typeDetermination === 'FIXED'}
                    onClick={() => onChange('FIXED', dataType ?? { kind: 'string' })}
                >
                    Fixed Type
                </FixedSwitcherButton>
                <InferredSwitcherButton
                    active={typeDetermination === 'INFERRED'}
                    onClick={() => onChange('INFERRED', undefined)}
                >
                    Figure Type out for me
                </InferredSwitcherButton>
            </TypeSwitchRow>
            {
                dataType ? (
                    <ShapeValueEditor
                        type={{ kind: 'shape', restriction: undefined }}
                        value={{ type: dataType, isShapeValue: true }}
                        onChange={(value: ShapeValue) => onChange(typeDetermination, value.type)}
                    />
                ): null
            }
        </>
    );
}