import * as React from 'react';
import { Title, ValueEditorContainer } from '@glueapp/component-library';
import { NumberLiteralDataType } from '@glueapp/graphexecution/lib/types/DataType';

interface NumberValueEditorProps {
    type: NumberLiteralDataType;
}

export const NumberLiteralValueEditor: React.FC<NumberValueEditorProps> = ({ type}) => {
    return (
        <ValueEditorContainer>
            <Title>
                {type.numberLiteral}
            </Title>
        </ValueEditorContainer>
    );
};