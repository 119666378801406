import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import Fuse from 'fuse.js';

export function searchNodeTemplates(
    dataNodes: DataNode[], search: string
): DataNode[] {
    if (search.length < 1) {
        return dataNodes;
    } else {
        const fuse = new Fuse(
            dataNodes,
            {
                findAllMatches: true,
                keys: [
                    'name',
                    'description',
                    'category'
                ]
            }
        );
        const searchResult = fuse.search(search);
        return searchResult.map(r => r.item);
    }
}
