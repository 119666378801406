import { DataType } from '@glueapp/graphexecution/lib/types/DataType';

export function getColorFromDataType(dataType: DataType): string {
    if (!dataType) {
        return 'grey';
    }
    switch (dataType.kind) {
        case 'string':
        case 'stringLiteral':
            return '#2868ee';
        case 'number':
        case 'numberLiteral':
            return '#4caa48';
        case 'boolean':
            return '#d38228';
        case 'object':
            return '#764be0';
        case 'shape':
            return '#c80798';
        case 'array':
            return 'grey';
        case 'union':
            return '#a36ba4';
        case 'generic':
            if (dataType.resolvedType) {
                return getColorFromDataType(dataType.resolvedType);
            }
            return '#282828';
        case 'trigger':
            return 'white';
    }
}
