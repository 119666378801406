import styled from 'styled-components';

export const ShortcutsSheet = styled.div`
    position: absolute;
    z-index: 1;
    top: 52px;
    right: 0;
    display: none;
    flex-direction: column;
    color: ${props => props.theme.primaryContrastColor};
    filter: drop-shadow(0px 0px 5px #000000AA);
    background: ${props => props.theme.backgroundColor};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.separatorColor};
    padding: 8px;
    cursor: auto;
    
    & > *:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.separatorColor};
    }
`;