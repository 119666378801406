import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GraphList } from './GraphList';
import { GraphListTopBar } from './GraphListTopBar';
import { useGraphStorageApi } from '../api/useGraphStorageApi';

const Container = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    min-height: 100vh;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 54px calc(100vh - 54px);
    grid-template-areas:
        "top-bar"
        "graph-list";
`;

const NamedGraphList = styled(GraphList)`
    grid-area: graph-list;
`;

const NamedGraphListTopBar = styled(GraphListTopBar)`
    grid-area: top-bar;
`;


interface Props {

}

export const GraphListLayout: React.FC<Props> = (props) => {
    const { createGraph } = useGraphStorageApi();
    const history = useHistory();

    const createGraphWorkflow = async () => {
        const graphId = await createGraph({
            metadata: {
                name: 'Untitled Graph',
                description: ''
            },
            graph: {
                nodeInstances: [],
                connections: [],
                variables: []
            }
        });
        history.push(`/graph/${graphId}`);
    };

    return (
        <Container>
            <NamedGraphListTopBar onCreateGraphClicked={createGraphWorkflow} />
            <NamedGraphList />
        </Container>
    );
};
