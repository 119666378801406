import * as React from 'react';
import { InputField, Title, ValueEditorContainer } from '@glueapp/component-library';
import { NumberLiteralDataType } from '@glueapp/graphexecution/lib/types/DataType';

interface ShapeNumberLiteralEditorProps {
    value: NumberLiteralDataType ;
    onChange: (value: NumberLiteralDataType) => void;
}

export const ShapeNumberLiteralEditor: React.FC<ShapeNumberLiteralEditorProps> = ({ value, onChange }) => {
    return (
        <ValueEditorContainer>
            <Title>
                <InputField
                    type='number'
                    value={value.numberLiteral}
                    onChange={e => onChange({ kind: 'numberLiteral', numberLiteral: parseFloat(e.target.value) })}
                />
            </Title>
        </ValueEditorContainer>
    );
};