import { useAuthenticatedAxiosInstance } from './useAuthenticatedAxiosInstance';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';

export const useLiveSyncRegistrationApi = () => {
    const api = useAuthenticatedAxiosInstance(`${getEnvironmentConfig().liveSyncRegistrationServiceUrl}`);

    const createConnectionAuthorization = useCallback(
        async (graphId: string): Promise<{ connectionAuthorizationId: string }> => {
            const result: AxiosResponse = await api.post(
                `/createConnectionAuthorization/${graphId}`
            );
            return result.data;
        },
        [api]
    );


    return {
        createConnectionAuthorization
    }
};
