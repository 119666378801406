import * as React from 'react';

import styled from 'styled-components';
import { ActionButton } from '../base/ActionButton';
import { ArrowIcon } from '../base/ArrowIcon';
import { TopBar } from '../NavigationBar/TopBar';

const Title = styled.div`
    flex-grow: 1;
    text-align: center;
    color: ${props => props.theme.textColor};
`;

interface Props {
    className?: string;
    onCreateGraphClicked: () => void;
}

export const GraphListTopBar: React.FC<Props> = ({ onCreateGraphClicked, className = '' }) =>  {
    return (
        <TopBar
            className={className}
            renderCenter={() => (
                <Title>
                    Your Workflows
                </Title>
            )}
            renderRightSection={() => {
                return (
                    <ActionButton onClick={onCreateGraphClicked}>
                        Create Workflow
                        <ArrowIcon />
                    </ActionButton>
                );
            }}
        />
    );
};
