import * as React from 'react';

import styled, { ThemeContext } from 'styled-components';
import { ReactNode, useContext, useState } from 'react';
import { ThemeSwitchingContext } from '../App';
import { GraphsMenuItem } from './GraphsMenuItem';
import { Profile } from './Profile';
import { ProfileIcon } from './ProfileIcon';
import { ThemeType } from '@glueapp/component-library';

const TopBarContainer = styled.div`
    display: grid;
    
    background-color: ${props => props.theme.backgroundColor};
    border-bottom: 2px solid ${props => props.theme.separatorColor};
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
    
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100%;
    grid-template-areas: 
        "menu center right"
`;

const Menu = styled.div`
    grid-area: menu;
    display: flex;
    align-items: stretch;
`;

const CenterSection = styled.div`
    grid-area: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const RightSection = styled.div`
    grid-area: right;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

const ThemeSwitcher = styled.svg`
    margin-left: 8px;
    width: 40px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 3px ${props => props.theme.primaryContrastColor});
`;

const PositionedProfile = styled(Profile)`
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 151;
`;

const DismissBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 150;
`;

const ProfileSection = styled.div`
    margin-left: 8px;
    position: relative;
`;

const ProfileButton = styled.button`
    display: flex;
    background: ${props => props.theme.backgroundColor};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.subtleAccentColor};
    padding: 2px;
    outline: none;
    transition: background-color .2s ease;
    
    &:hover {
        background: ${props => props.theme.subtleAccentColor};        
        cursor: pointer;
    }
`;
const StyledProfileIcon = styled(ProfileIcon)`
    width: 40px;
`;

interface Props {
    className?: string;
    renderCenter?: () => ReactNode;
    renderRightSection?: () => ReactNode;
}

export const TopBar: React.FC<Props> = ({ renderCenter, renderRightSection, className = '' }) =>  {
    const { toggleTheme, activeTheme } = useContext(ThemeSwitchingContext);
    const { primaryContrastColor } = useContext<ThemeType>(ThemeContext);
    const [showingProfile, setShowingProfile] = useState<boolean>(false);

    return (
        <TopBarContainer className={className}>
            <Menu>
                <GraphsMenuItem to={'/'}/>
                <ProfileSection>
                    {showingProfile ? <PositionedProfile /> : null}
                    {showingProfile ? <DismissBackground onClick={() => setShowingProfile(false)} /> : null}
                </ProfileSection>
                <ProfileButton
                    onClick={() => setShowingProfile(!showingProfile)}
                >
                    <StyledProfileIcon />
                </ProfileButton>
                <ThemeSwitcher onClick={toggleTheme} viewBox="0 0 100 100">
                    <defs>
                        <mask id="cutout">
                            <rect x="0" y="0" width="100" height="100" fill="white" />
                            <circle cx={activeTheme === 'LIGHT' ? -50 : 35} cy={50} r={40} fill="black"/>
                        </mask>
                    </defs>
                    {
                        activeTheme === 'LIGHT'
                            ? <g transform="translate(50 50)">
                                {Array.from({ length: 17 }, (_, i) =>
                                    <line key={i} transform={`rotate(${(i / 17) * 360})`} x1={0} y1={42} x2={0} y2={50} stroke={primaryContrastColor} strokeWidth={3} />
                                )}
                            </g> : null
                    }
                    <circle cx={50} cy={50} r={40} fill={primaryContrastColor} mask="url(#cutout)"/>
                </ThemeSwitcher>
            </Menu>
            <CenterSection>{renderCenter && renderCenter()}</CenterSection>
            <RightSection>{renderRightSection && renderRightSection()}</RightSection>
        </TopBarContainer>
    );
};
