import { useAuthenticatedAxiosInstance } from './useAuthenticatedAxiosInstance';
import { useCallback } from 'react';
import { GraphPackage, GraphDescription } from '../GraphList/types';
import { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';

export const useGraphStorageApi = () => {
    const api = useAuthenticatedAxiosInstance(`${getEnvironmentConfig().graphServiceUrl}`);

    const getAllGraphDescriptions = useCallback(
        async (): Promise<GraphDescription[]> => {
            const result: AxiosResponse = await api.get(
                `/graphs`
            );
            return result.data.graphDescriptions;
        },
        [api]
    );

    const getGraph = useCallback(
        async (graphId: string): Promise<GraphPackage> => {
            const result: AxiosResponse = await api.get(
                `/get-graph/${graphId}`
            );
            const data: GraphPackage = result.data;
            if (data.graph.variables) {
                return data;
            } else {
                return {
                    metadata: data.metadata,
                    graph: {
                        ...data.graph,
                        variables: []
                    }
                };
            }
        },
        [api]
    );

    const deleteGraph = useCallback(
        async (graphId: string): Promise<void> => {
            await api.delete(
                `/delete-graph/${graphId}`
            );
            return;
        },
        [api]
    );

    const updateGraph = useCallback(
        async (graphId: string, graphPackage: GraphPackage): Promise<void> => {
            const result: AxiosResponse = await api.put(
                `/update-graph/${graphId}`,
                {
                    graphPackage: graphPackage
                }
            );
            return result.data;
        },
        [api]
    );

    const createGraph = useCallback(
        async (graphPackage: GraphPackage): Promise<string> => {
            const result: AxiosResponse = await api.post(
                `/create-graph`,
                {
                    graphPackage: graphPackage
                }
            );
            return result.data.graphId;
        },
        [api]
    );

    return {
        getAllGraphDescriptions,
        getGraph,
        updateGraph,
        createGraph,
        deleteGraph
    }
};
