import React from 'react';
import styled from 'styled-components';
import { ActionButton } from '../base/ActionButton';
import { useAuthentication } from './AuthenicationProvider';


const LoginContainer = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`;

const LogInHeader = styled.h2`
    color: ${props => props.theme.textColor};
    font-size: 24px;
`;

export const LoginPage = () => {
    const { login } = useAuthentication();

    return (
        <LoginContainer>
            <LogInHeader>Glue - Visual Programming for Automation</LogInHeader>
            <ActionButton onClick={login}>Sign in / Sign up</ActionButton>
        </LoginContainer>
    );
};

