import * as React from 'react';

import { TextAreaInputField, Title, ValueEditorContainer } from '@glueapp/component-library';
import { StringLiteralDataType } from '@glueapp/graphexecution/lib/types/DataType';

interface ShapeStringLiteralEditorProps {
    value: StringLiteralDataType;
    onChange: (value: StringLiteralDataType) => void;
}

export const ShapeStringLiteralEditor: React.FC<ShapeStringLiteralEditorProps> = ({ value, onChange }) => {
    return (
        <ValueEditorContainer>
            <Title>
                <TextAreaInputField
                    placeholder="Text"
                    value={value.stringLiteral}
                    onChange={e => onChange({ kind: 'stringLiteral', stringLiteral: e.target.value })}
                />
            </Title>
        </ValueEditorContainer>
    );
};