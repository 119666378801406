export const getHighestIdFromObjectsWithIds = (objectsWithIds: { id: number }[]): number => {
    if (objectsWithIds.length === 0)
        return 0;

    return objectsWithIds.reduce<number>(
        (highest: number, obj: { id: number }): number => {
            return Math.max(highest, obj.id);
        },
        0
    );
};