import React, {
    Reducer,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    DataConnection,
    DataGlueGraph,
    DataNodeInstance,
    GlueVariable,
    NodeInstanceDataPort,
    UserDefinedType
} from '@glueapp/graphexecution/lib/types/Graph';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { NODE_LIBRARY, NODE_LIBRARY_DICTIONARY } from '@glueapp/graphexecution/lib/nodeLibrary';
import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { GraphMetadata } from '../GraphList/types';
import { useGraphStorageApi } from '../api/useGraphStorageApi';
import { useDebouncedEffect } from '../helpers/useDebouncedEffect';
import { ValidationResult } from '@glueapp/graphexecution/lib/validation/validateGraph';
import { NodeInstanceEditor } from '../NodeInstanceEditor/NodeInstanceEditor';
import { areValidationsValid } from '@glueapp/graphexecution/lib/validation/areValidationsValid';
import {
    addConnection,
    addNode,
    deselectAllNodes,
    deselectNodes,
    GraphReducerActions,
    moveNodes,
    moveNodesEnd,
    reframeSubGraphNode,
    reframeSubGraphNodeEnd,
    removeConnection,
    removeNode,
    selectBranch,
    selectBranchAdd,
    selectNodes,
    selectOnlyOneNode,
    setGraph,
    setPortValueAndType,
    addVariable,
    deleteVariable,
    setSelectedVariable,
    updateVariable,
    mergeInGraph,
    actionsIgnoredForUndoRedo
} from './actions';
import { graphReducer } from './reducer';
import { GraphEditorDrawing } from './drawingLayer/GraphEditorDrawing';
import { Vector } from './helpers/Vector';
import { NodeLibraryContextProvider } from './NodeLibraryContextProvider';
import { Container, MainSection, SideBar, StyledTopBar } from './LayoutComponents';
import { processGraph } from '@glueapp/graphexecution/lib/validation/processGraph';
import { graphIntegrityCheck } from '@glueapp/graphexecution/lib/validation/graphIntegrityCheck';
import { dictionaryFromNumberTuple } from './helpers/dictionaryFromTuple';
import { useUndoRedoReducer } from '../helpers/useUndoRedoReducer';
import { useThrottledEffect } from '../helpers/useThrottledEffect';
import { useGraphExecution } from './useGraphExecution';
import { useWebhooks } from './useWebhooks';
import { useLiveSync } from './useLiveSync';
import { useIntegrationAccesses } from './useIntegrationAccesses';
import { areAllIntegrationsAndNodesForGraphPresent } from '@glueapp/graphexecution/lib/validation/areAllIntegrationsAndNodesForGraphPresent';
import { GraphUpdateContext } from './GraphUpdateContext';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';

interface Props {
    graphId: string;
}

export const GraphEditorLayout : React.FC<Props> = props => {
    const { getGraph, updateGraph } = useGraphStorageApi();
    const { integrationAccesses, deleteIntegrationAccess } = useIntegrationAccesses();

    const {
        state: dataGraph,
        dispatch: dispatchGraphAction,
        undo,
        redo,
        makeCurrentStateBaseline
    } = useUndoRedoReducer<Reducer<DataGlueGraph|undefined, GraphReducerActions>, DataGlueGraph|undefined>(
        graphReducer, undefined, actionsIgnoredForUndoRedo
    );
    const [graphMetaData, setGraphMetadata] = useState<GraphMetadata|undefined>(undefined);

    const [validationResult, setValidationResult] = useState<ValidationResult|undefined>(undefined);

    const { webhooks, createWebhook, nodeInstancesWithPendingRequests } = useWebhooks(props.graphId);

    useEffect(() => {
        const fetchData = async () => {
            const graphPackage = await getGraph(props.graphId);
            setGraphMetadata(graphPackage.metadata);
            dispatchGraphAction(setGraph(graphPackage.graph));
            makeCurrentStateBaseline();
        };
        fetchData();
    }, [props.graphId, getGraph, dispatchGraphAction, makeCurrentStateBaseline]);


    const [graphValid, setGraphValid] = useState<boolean>(false);
    useEffect(() => {
        if (
            dataGraph && integrationAccesses &&
            validationResult && areValidationsValid(validationResult) &&
            areAllIntegrationsAndNodesForGraphPresent(dataGraph, NODE_LIBRARY, integrationAccesses)
        ) {
            setGraphValid(true);
        } else {
            setGraphValid(false);
        }
    }, [dataGraph, integrationAccesses, validationResult]);


    const {
        executionResult,
        setExecutionResult,
        executionError,
        setExecutionError,
        triggerExecution,
        resetExecution,
        ranNodes
    } = useGraphExecution(dataGraph, validationResult, graphValid);

    useLiveSync(props.graphId, setExecutionResult, setExecutionError);


    useThrottledEffect(() => {
        const asyncUpdate = async () => {
            if (dataGraph && graphMetaData) {
                const cleanedGraph = graphIntegrityCheck(dataGraph, NODE_LIBRARY_DICTIONARY);
                if (cleanedGraph !== dataGraph) {
                    dispatchGraphAction(setGraph(cleanedGraph));
                }
                try {
                    await updateGraph(props.graphId, { graph: cleanedGraph, metadata: graphMetaData });
                } catch (e) {

                }
            }
        };
        asyncUpdate();
    }, 3000,[dataGraph, graphMetaData, updateGraph, NODE_LIBRARY]);

    const lastDataGraph = useRef<DataGlueGraph|undefined>(dataGraph);
    useDebouncedEffect(() => {
        if (dataGraph && dataGraph !== lastDataGraph.current && integrationAccesses) {
            const { graph, validationResult } = processGraph(dataGraph, NODE_LIBRARY, integrationAccesses);
            dispatchGraphAction(setGraph(graph));
            lastDataGraph.current = graph;
            setValidationResult(validationResult);
            resetExecution();
        }
    }, 300,[dataGraph, graphMetaData, updateGraph, NODE_LIBRARY, integrationAccesses]);


    const nodeInstanceDictionary: { [key: number]: DataNodeInstance } = useMemo(
        () => dictionaryFromNumberTuple(dataGraph?.nodeInstances ?? [], nodeInstance => [nodeInstance.id, nodeInstance]),
        [dataGraph]
    )
    const dispatchUpdatePortValue = useCallback((nodeInstanceId: number, port: NodeInstanceDataPort, value: DataValue, userDefinedType?: UserDefinedType) => {
        dispatchGraphAction(setPortValueAndType(nodeInstanceId, port, value, userDefinedType));
    }, [dispatchGraphAction]);

    const dispatchAddConnection = useCallback((connection: Omit<DataConnection, 'id'>) => dispatchGraphAction(addConnection(connection)), [dispatchGraphAction]);
    const dispatchRemoveConnection = useCallback((index: number) => dispatchGraphAction(removeConnection(index)), [dispatchGraphAction]);
    const dispatchAddNode = useCallback((nodeTemplate: DataNode, position: Vector, subGraphId?: number) => dispatchGraphAction(addNode(nodeTemplate.id, position, subGraphId)), [dispatchGraphAction]);
    const dispatchMergeInGraph = useCallback((nodes: DataNodeInstance[], connections: DataConnection[], deselectOld: boolean) => dispatchGraphAction(mergeInGraph(nodes, connections, deselectOld)), [dispatchGraphAction]);
    const dispatchMoveNodes = useCallback(move => dispatchGraphAction(moveNodes(move)), [dispatchGraphAction]);
    const dispatchReframeNode = useCallback((nodeId, position, width, height) => dispatchGraphAction(reframeSubGraphNode(nodeId, position, width, height)), [dispatchGraphAction]);
    const dispatchReframeSubGraphNodeEnd = useCallback(() => dispatchGraphAction(reframeSubGraphNodeEnd()), [dispatchGraphAction]);
    const dispatchMoveNodesEnd = useCallback(() => dispatchGraphAction(moveNodesEnd()), [dispatchGraphAction]);
    const dispatchRemoveNodes = useCallback((nodeIds: number[]) => dispatchGraphAction(removeNode(nodeIds)), [dispatchGraphAction]);
    const dispatchSelectNodes = useCallback((nodeIds: number[]) => dispatchGraphAction(selectNodes(nodeIds)), [dispatchGraphAction]);
    const dispatchSelectBranch = useCallback((nodeId: number) => dispatchGraphAction(selectBranch(nodeId)), [dispatchGraphAction]);
    const dispatchSelectBranchAdd = useCallback((nodeId: number) => dispatchGraphAction(selectBranchAdd(nodeId)), [dispatchGraphAction]);
    const dispatchDeselectNodes = useCallback((nodeIds: number[]) => dispatchGraphAction(deselectNodes(nodeIds)), [dispatchGraphAction]);
    const dispatchSelectOnlyOneNode = useCallback((nodeId: number) => dispatchGraphAction(selectOnlyOneNode(nodeId)), [dispatchGraphAction]);
    const dispatchSelectAllNodes = useCallback(() => dispatchGraphAction(deselectAllNodes()), [dispatchGraphAction]);

    const dispatchAddVariable = useCallback((newVariable: GlueVariable) => dispatchGraphAction(addVariable(newVariable)), [dispatchGraphAction]);
    const dispatchUpdateVariable = useCallback((oldName: string, variable: GlueVariable) =>
        dispatchGraphAction(updateVariable(oldName, variable)), [dispatchGraphAction]);
    const dispatchDeleteVariable = useCallback((name: string) => dispatchGraphAction(deleteVariable(name)), [dispatchGraphAction]);

    const dispatchSetSelectedVariable = useCallback((name: string|undefined, nodeInstanceId: number) => dispatchGraphAction(setSelectedVariable(name, nodeInstanceId)), [dispatchGraphAction]);

    const renderSidebar = () => {
        if (dataGraph) {
            const selectedNodes = dataGraph.nodeInstances.filter(nodeInstance => nodeInstance.selected);
            if (selectedNodes.length > 0) {
                return (
                    <SideBar>
                        {selectedNodes.map(nodeInstance => {
                            const nodeOfSelectedNodeInstance = NODE_LIBRARY_DICTIONARY[nodeInstance.nodeId];
                            const nodeIntegration = nodeOfSelectedNodeInstance.integration;
                            let nodeIntegrationAccesses: IntegrationAccess[] = [];
                            if (nodeIntegration !== undefined) {
                                nodeIntegrationAccesses = (integrationAccesses ?? []).filter(integration => integration.provider === nodeIntegration.provider);
                            }
                            return (
                                <NodeInstanceEditor
                                    key={nodeInstance.id}
                                    nodeInstance={nodeInstance}
                                    integrationAccesses={nodeIntegrationAccesses}
                                    deleteIntegrationAccess={deleteIntegrationAccess}
                                    node={nodeOfSelectedNodeInstance}
                                    connections={dataGraph.connections}
                                    onValueChange={dispatchUpdatePortValue}
                                    webhook={webhooks.find(webhook => webhook.nodeInstanceId === nodeInstance.id)}
                                    webhookCreatePending={nodeInstancesWithPendingRequests.includes(nodeInstance.id)}
                                    createWebhook={createWebhook}
                                    variables={dataGraph.variables}
                                    setSelectedVariable={dispatchSetSelectedVariable}
                                    addVariable={dispatchAddVariable}
                                    updateVariable={dispatchUpdateVariable}
                                    deleteVariable={dispatchDeleteVariable}
                                />
                            );
                        })}
                    </SideBar>
                );
            }
        }
    };

    return (
        <Container>
            <NodeLibraryContextProvider nodeLibrary={NODE_LIBRARY}>
                <GraphUpdateContext.Provider value={dispatchGraphAction}>
                    <StyledTopBar
                        graphName={graphMetaData?.name ?? ''}
                        onGraphNameChange={newGraphName => setGraphMetadata({ description: '', name: newGraphName })}
                    />
                    <MainSection>
                        {dataGraph !== undefined && integrationAccesses ?
                            <GraphEditorDrawing
                                graph={dataGraph}
                                nodeDictionary={nodeInstanceDictionary}
                                nodeTemplates={NODE_LIBRARY}
                                executionResult={executionResult}
                                executionError={executionError}
                                validationResult={validationResult}
                                addConnection={dispatchAddConnection}
                                removeConnection={dispatchRemoveConnection}
                                addNode={dispatchAddNode}
                                mergeInGraph={dispatchMergeInGraph}
                                moveNodes={dispatchMoveNodes}
                                reframeNode={dispatchReframeNode}
                                reframeNodeEnd={dispatchReframeSubGraphNodeEnd}
                                moveNodesEnd={dispatchMoveNodesEnd}
                                removeNodes={dispatchRemoveNodes}
                                selectNodes={dispatchSelectNodes}
                                selectBranch={dispatchSelectBranch}
                                selectBranchAdd={dispatchSelectBranchAdd}
                                deselectNodes={dispatchDeselectNodes}
                                selectOnlyOneNode={dispatchSelectOnlyOneNode}
                                deselectAllNodes={dispatchSelectAllNodes}
                                triggerManualRun={triggerExecution}
                                undo={undo}
                                redo={redo}
                                ranNodes={ranNodes}
                                integrationAccesses={integrationAccesses}
                            /> : null
                        }
                        {renderSidebar()}
                        {/*{nodeInstanceOutputState.status === AsyncStateStatus.PENDING ? <LoadingOverlay /> : null}*/}
                    </MainSection>
                </GraphUpdateContext.Provider>
            </NodeLibraryContextProvider>
        </Container>
    );
};
