import * as React from 'react';
import { DataType, ObjectDataType } from '@glueapp/graphexecution/lib/types/DataType';
import {
    InputField,
    InputValueEditorButton,
    ValueEditorContainer,
    ValueList
} from '@glueapp/component-library';
import styled, { ThemeContext } from 'styled-components';
import { ShapeTypeSelectorEditor } from './ShapeValueEditor';
import { useContext, useState } from 'react';
import { DeleteIcon } from '../../GraphList/DeleteIcon';
import { ThemeType } from '@glueapp/component-library';


const NewPropertyRow = styled.form`
    display: flex;
    flex-direction: row;
    
    &>*:first-child {
        flex-grow: 1;
    }
`;

interface ShapeObjectEditorProps {
    value: ObjectDataType;
    onChange: (value: ObjectDataType) => void;
}

export const PropertyItem = styled.li`
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const PropertyItemHeader = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-bottom: 2px;
`;

export const PropertyItemTitle = styled.h4`
    font-size: 14px;
    color: ${props => props.theme.textColor};
    margin: 0;
`;

const DeletePropertyButton = styled.button`
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: ${props => props.theme.backgroundColor};
    border: none;
    padding: 0;
    
    width: 24px;
    height: 24px;
    
    :hover {
        background: ${props => props.theme.deleteRed};
    }
`;


export const ShapeObjectEditor: React.FC<ShapeObjectEditorProps> = ({ value, onChange }) => {
    const [newPropertyName, setNewPropertyName] = useState<string>('');
    const theme = useContext<ThemeType>(ThemeContext);

    const changeProperty = (propertyName: string, newValue: DataType) => {
        onChange({
            ...value,
            properties: {
                ...value.properties,
                [propertyName]: newValue
            }
        });
    };
    const removeProperty = (propertyName: string) => {
        const copyOfProperties = { ...value.properties };
        delete copyOfProperties[propertyName];
        onChange({
            ...value,
            properties: copyOfProperties
        });
    };

    const addProperty = () => {
        if (newPropertyName !== '') {
            onChange({
                ...value,
                properties: {
                    ...value.properties,
                    [newPropertyName]: { kind: 'string' }
                }
            });
            setNewPropertyName('');
        }
    };

    return (
        <ValueEditorContainer>
            <ValueList>
                {Object.entries(value.properties).map(([propertyName, propertyType]) => (
                    <PropertyItem key={propertyName}>
                        <PropertyItemHeader>
                            <PropertyItemTitle>{propertyName}</PropertyItemTitle>
                            <DeletePropertyButton onClick={() => removeProperty(propertyName)}>
                                <DeleteIcon size={8} color={theme.primaryContrastColor} />
                            </DeletePropertyButton>
                        </PropertyItemHeader>
                        <ShapeTypeSelectorEditor value={propertyType} onChange={(newValue) => changeProperty(propertyName, newValue)} />
                    </PropertyItem>
                ))}
            </ValueList>
            <NewPropertyRow onSubmit={addProperty}>
                <InputField placeholder="New Property Name" value={newPropertyName} onChange={(e) => setNewPropertyName(e.target.value)} />
                <InputValueEditorButton disabled={newPropertyName === ''} onClick={addProperty}>Add Property</InputValueEditorButton>
            </NewPropertyRow>
        </ValueEditorContainer>
    );
};
