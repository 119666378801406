import * as React from 'react';
import styled, { css, keyframes, ThemeContext } from 'styled-components';

import { GraphBackgroundPattern } from '../BaseComponents/GraphBackgroundPattern';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { searchNodeTemplates } from './searchNodeTemplates';
import { KeyboardEvent } from 'react';
import { ThemeType } from '@glueapp/component-library';
import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { SubGraphNode } from '../graphComponents/SubGraphNode';
import { GraphNode } from '../graphComponents/GraphNode';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import {
    CallIntegrationRequest,
    CallIntegrationResponse
} from '@glueapp/graphexecution/lib/types/IntegrationExecutionAccess';
import { DRAW_CONFIG } from '../drawConfig.constant';

const fadeIn = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`;

const Container = styled.div` 
    display: grid;
    grid-template-columns: 200px minmax(200px, 1fr) 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
        "search search preview"
        "categories nodes preview";
    width: 60vw;
    height: 60vh;
    @media (max-width: 1000px) {
        width: 100vw;
        height: 80vh;
    }
    @media (min-width: 1001px) and (max-width: 1920px) {
        width: 80vw;
        height: 70vh;
    }
    background: ${props => props.theme.elevatedBackgroundColor};
    border-radius: 10px;
    animation: ${fadeIn} 0.1s ease-in-out;
    overflow: hidden;
`;


const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    border-right: 2px solid ${props => props.theme.separatorColor};
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const CategoryList = styled(List)`
  grid-area: categories;
`;

const NodesList = styled(List)`
  grid-area: nodes;
`;


export const SearchContainer = styled.div`
    grid-area: search;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 4px;
    border-bottom: 2px solid ${props => props.theme.separatorColor};
    border-right: 2px solid ${props => props.theme.separatorColor};
`;

export const SearchTitle = styled.h2`
    color: ${props => props.theme.textColor};
    padding-left: 8px;
    padding-right: 8px;
`;

export const SearchInput = styled.input`
    flex-grow: 1;
    color: ${props => props.theme.textColor};
    background: ${props => props.theme.backgroundColor};
    border: 1px solid rgb(60,60,60);
    height: 24px;
    padding: 4px;
`;

const CategoryHeader = styled.li`
    min-width: 200px;
    padding: 10px;
    padding-left: 2px;
    border-bottom: 1px solid ${props => props.theme.separatorColor};
    background-color: ${props => props.theme.subtleAccentColor};
    
    color: ${props => props.theme.textColor};
`;


interface ListItemProps {
    selected: boolean;
    selectedColor: string;
}

const ListItem = styled.li<ListItemProps>`
    min-width: 200px;
    padding: 10px;
    
    font-weight: bold;
    
    color: ${props => props.theme.textColor};
    
    cursor: pointer;
    
    ${props => props.selected ? css`background: ${props.selectedColor};` : ''}
`;

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    grid-area: preview;
`;

const PreviewTitle = styled.div`
    padding: 5px 5px 5px 10px;
    font-weight: bold;
    color: ${props => props.theme.textColor};
`;

const PreviewDescription = styled.div`
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    max-width: 400px;
    color: ${props => props.theme.textColor};
`;

const PreviewBody = styled.div`
    background: ${props => props.theme.backgroundColor};
    position: relative;
    flex: 1;
`;

const NodeLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
`;

interface Category {
    name: string;
    color: string;
}

interface Props {
    nodeTemplates: DataNode[];
    onNodeSelect: (nodeTemplate: DataNode) => void;
    integrationAccesses: IntegrationAccess[];
    callIntegration(integrationAccess: IntegrationAccess, requestConfig: CallIntegrationRequest): Promise<CallIntegrationResponse<any>>;
}

export const NodeLibrary:React.FC<Props> = ({ nodeTemplates, onNodeSelect, integrationAccesses, callIntegration }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const searchInputRef = useRef<HTMLInputElement|null>(null);
    const categoryHeaderRefs = useRef<{ [key: string]: HTMLLIElement }>({});
    const theme = useContext<ThemeType>(ThemeContext);

    useEffect(() => {
        const currentSearchInput = searchInputRef.current;
        if (currentSearchInput) {
            currentSearchInput.focus();
        }
    }, []);

    const [selectedNodeTemplateId, setSelectedNodeTemplateId] = useState<string|undefined>(undefined);
    const [selectedCategoryName, setSelectedCategory] = useState<string|undefined>(undefined);

    const [filteredNodes, setFilteredNodes] = useState<DataNode[]>([]);
    useEffect(() => {
        const searchResult = searchNodeTemplates(nodeTemplates, searchValue);
        setFilteredNodes(searchResult);
        setSelectedNodeTemplateId(searchResult[0]?.id)
    }, [nodeTemplates, searchValue]);

    const categories: Category[] = useMemo(() => {
        const dictionary: { [key: string]: string } = {};
        for(let template of filteredNodes) {
            dictionary[template.category] = template.color;
        }
        return Object.entries(dictionary).map(([name, color]) => ({ name, color }));
    }, [filteredNodes]);


    const filteredNodesByCategory: Map<string, DataNode[]> = useMemo(() => {
        const categoryToNodesMap: Map<string, DataNode[]> = new Map(Array.from(categories).map(({  name }) => [name, []]));
        filteredNodes.forEach(nodeTemplate => {
            const categoryNodeList = categoryToNodesMap.get(nodeTemplate.category);
            if(categoryNodeList) {
                categoryNodeList.push(nodeTemplate);
            }
        });
        return categoryToNodesMap;
    }, [filteredNodes, categories])

    const handleNodeClick = () => {
        if (selectedNodeTemplateId !== undefined) {
            const selectedNode: DataNode|undefined = nodeTemplates.find(nodeTemplate => selectedNodeTemplateId === nodeTemplate.id);
            if (selectedNode) {
                onNodeSelect(selectedNode);
            }
        }
    };

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case 'Enter': {
                event.preventDefault();
                if (selectedNodeTemplateId !== undefined) {
                    const selectedNode: DataNode|undefined = nodeTemplates.find(nodeTemplate => selectedNodeTemplateId === nodeTemplate.id);
                    if (selectedNode) {
                        onNodeSelect(selectedNode);
                    }
                }
                break;
            }

            case 'ArrowUp': {
                event.preventDefault();
                const currentlySelectedIndex = filteredNodes.findIndex(template => template.id === selectedNodeTemplateId);
                const newIndex = Math.abs(((currentlySelectedIndex ?? 0) - 1) % filteredNodes.length);
                setSelectedNodeTemplateId(filteredNodes[newIndex]?.id);
                break;
            }

            case 'ArrowDown': {
                event.preventDefault();
                const currentlySelectedIndex = filteredNodes.findIndex(template => template.id === selectedNodeTemplateId);
                const newIndex = Math.abs(((currentlySelectedIndex ?? 0) + 1) % filteredNodes.length);
                setSelectedNodeTemplateId(filteredNodes[newIndex]?.id);
                break;
            }
        }
    }, [selectedNodeTemplateId, nodeTemplates, filteredNodes, onNodeSelect]);


    const renderNodeListItem = (node: DataNode) => {
        const selected = node.id === selectedNodeTemplateId;
        return (
            <ListItem
                key={node.name}
                onMouseMove={() => setSelectedNodeTemplateId(node.id)}
                onClick={handleNodeClick}
                selected={selected}
                selectedColor={node.color}
            >
                {node.name}
            </ListItem>
        );
    };

    const renderCategory = (category: Category) => {
        const selected = category.name === selectedCategoryName;
        return (
            <ListItem
                key={category.name}
                selected={selected}
                selectedColor={category.color}
                onMouseMove={() => setSelectedCategory(category.name)}
            >
                {category.name}
            </ListItem>
        );
    };

    useLayoutEffect(() => {
        if (selectedCategoryName) {
            const categoryHeaderElement = categoryHeaderRefs.current[selectedCategoryName];
            if (categoryHeaderElement) {
                categoryHeaderElement.scrollIntoView({ behavior: 'auto' });
            }

            const firstNodeInCategory = filteredNodesByCategory.get(selectedCategoryName)?.[0];
            if (firstNodeInCategory) {
                setSelectedNodeTemplateId(firstNodeInCategory.id);
            }
        }
    }, [selectedCategoryName, filteredNodesByCategory]);


    const selectedNode: DataNode|undefined = nodeTemplates.find(nodeTemplate => selectedNodeTemplateId === nodeTemplate.id);

    const [nodeState, setNodeState] = useState({});
    useEffect(() => {
        setNodeState({});
    }, [selectedNode]);

    const renderNode = () => {
        if (!selectedNode) {
            return null;
        }
        if (selectedNode.hasSubGraph) {
            return (
                <SubGraphNode
                    selectedWidthMultiplier={1}
                    onClick={handleNodeClick}
                    theme={theme}
                    nodeInstance={{
                        id: -1,
                        nodeId: selectedNode.id,
                        inPorts: selectedNode.inPorts,
                        outPorts: selectedNode.outPorts,
                        selected: false,
                        hasSubGraph: true,
                        internalOutPorts: selectedNode.internalOutPorts,
                        internalInPorts: selectedNode.internalInPorts,
                        subGraphId: undefined,
                        portValues: {},
                        portUserDefinedTypes: {},
                        position: {
                            x: 0,
                            y: 0
                        },
                        state: nodeState,
                        width: DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM,
                        height: DRAW_CONFIG.SUB_GRAPH_NODE_HEIGHT_MINIMUM
                    }}
                    callIntegration={callIntegration}
                    integrationAccesses={integrationAccesses}
                    setInPortPosition={() => {}}
                    setOutPortPosition={() => {}}
                    setNodeState={setNodeState}
                    setNodeDimensions={() => {}}
                />
            );
        } else {
            return (
                <GraphNode
                    selectedWidthMultiplier={1}
                    onClick={handleNodeClick}
                    theme={theme}
                    nodeInstance={{
                        id: -1,
                        nodeId: selectedNode.id,
                        inPorts: selectedNode.inPorts,
                        outPorts: selectedNode.outPorts,
                        selected: false,
                        hasSubGraph: false,
                        subGraphId: undefined,
                        portValues: {},
                        portUserDefinedTypes: {},
                        position: {
                            x: 0,
                            y: 0
                        },
                        state: {}
                    }}
                    callIntegration={callIntegration}
                    integrationAccesses={integrationAccesses}
                    setInPortPosition={() => {}}
                    setOutPortPosition={() => {}}
                    setNodeState={() => {}}
                    setNodeShape={() => {}}
                    setNodeDimensions={() => {}}
                />
            );
        }
    }


    return (
        <Container
            onKeyDown={handleKeyDown}
        >
            <SearchContainer>
                <SearchTitle>Search:</SearchTitle>
                <SearchInput
                    ref={ref => {
                        searchInputRef.current = ref;
                    }}
                    value={searchValue}
                    aria-label={"Text field to search for blocks"}
                    onChange={event => setSearchValue(event.target.value)}
                />
            </SearchContainer>
            <CategoryList onWheel={e => e.stopPropagation()}>
                {categories.map(categoryName => renderCategory(categoryName))}
            </CategoryList>
            <NodesList onWheel={e => e.stopPropagation()}>
                {Array.from(filteredNodesByCategory).map(([category, nodes]) => {
                    return (
                        <React.Fragment key={category}>
                            <CategoryHeader ref={instance => {
                                if (instance) {
                                    categoryHeaderRefs.current[category] = instance
                                } else {
                                    delete categoryHeaderRefs.current[category];
                                }
                            }}>{category}</CategoryHeader>
                            {nodes.map(renderNodeListItem)}
                        </React.Fragment>
                    );
                })}
            </NodesList>
            <Preview>
                <PreviewTitle>{selectedNode ? selectedNode.name : 'Hover a node to preview'}</PreviewTitle>
                <PreviewDescription>{selectedNode ? selectedNode.description : '-'}</PreviewDescription>
                <PreviewBody>
                    <SVG>
                        <GraphBackgroundPattern />
                    </SVG>
                    <NodeLayer>
                        {renderNode()}
                    </NodeLayer>
                </PreviewBody>
            </Preview>
        </Container>
    );
};
