import React from 'react';

interface Props {
    animated?: boolean;
    animationDuration?: string;
    size?: number;
}

export const GraphIcon: React.FC<Props> = ({ animated = false, animationDuration = '1s', size = 200, ...props}) => (
    <svg width={size} viewBox="0 0 465 245" fill="none" {...props}>
        <defs>
            {animated ? <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%" >
                <stop stopColor="rgba(255, 255, 255, 0.5)">
                    <animate attributeName="offset" values="-2; 0" keyTimes="0; 1" dur={animationDuration} repeatCount="indefinite" />
                </stop>

                <stop stopColor="white">
                    <animate attributeName="offset" values="-1; 1" keyTimes="0; 1" dur={animationDuration} repeatCount="indefinite" />
                </stop>

                <stop stopColor="rgba(255, 255, 255, 0.5)">
                    <animate attributeName="offset" values="0; 2" keyTimes="0; 1" dur={animationDuration} repeatCount="indefinite" />
                </stop>

                <stop stopColor="white">
                    <animate attributeName="offset" values="1; 3" keyTimes="0; 1" dur={animationDuration} repeatCount="indefinite" />
                </stop>
            </linearGradient>: null}
        </defs>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 0C4.925 0 0 4.925 0 11v79c0 6.075 4.925 11 11 11h148c6.075 0 11-4.925 11-11V52c19.304 0 31.984 3.593 41.3 8.82 9.346 5.245 15.518 12.242 21.733 19.55l.865 1.018c5.894 6.942 12.006 14.142 20.93 19.604 9.431 5.772 21.812 9.508 40.172 9.508v29c-18.309 0-30.646 3.277-40.053 8.346-8.925 4.809-15.046 11.156-20.946 17.274l-.862.893c-6.204 6.423-12.369 12.578-21.723 17.199C202.072 187.828 189.349 191 170 191v-36c0-6.075-4.925-11-11-11H11c-6.075 0-11 4.925-11 11v79c0 6.075 4.925 11 11 11h148c6.075 0 11-4.925 11-11v-38c19.901 0 33.428-3.265 43.631-8.305 10.193-5.036 16.872-11.756 23.105-18.208l.741-.768c5.984-6.201 11.618-12.041 19.842-16.471 8.514-4.588 19.99-7.748 37.681-7.748V162c0 6.075 4.925 11 11 11h148c6.075 0 11-4.925 11-11V83c0-6.075-4.925-11-11-11H306c-6.075 0-11 4.925-11 11v22.5c-17.64 0-29.072-3.577-37.562-8.773-8.225-5.034-13.868-11.677-19.859-18.73l-.737-.867c-6.223-7.317-12.894-14.945-23.095-20.67C203.516 50.72 189.946 47 170 47V11c0-6.075-4.925-11-11-11H11zm148 5H11a6 6 0 0 0-6 6v79a6 6 0 0 0 6 6h148a6 6 0 0 0 6-6V11a6 6 0 0 0-6-6zm0 144H11a6 6 0 0 0-6 6v79a6 6 0 0 0 6 6h148a6 6 0 0 0 6-6v-79a6 6 0 0 0-6-6zm147-72h148a6 6 0 0 1 6 6v79a6 6 0 0 1-6 6H306a6 6 0 0 1-6-6V83a6 6 0 0 1 6-6z"
            fill={animated ? 'url("#gradient")' : 'white'}
        />
    </svg>
);
