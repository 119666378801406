import * as React from 'react';
import { ValueEditorContainer } from '@glueapp/component-library';
import { BooleanDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';

interface BooleanValueEditorProps {
    type: BooleanDataType;
    value: boolean;
    userDefinedType?: UserDefinedType;
    onChange: (value: boolean, updatedType?: UserDefinedType) => void;
}

export const BooleanValueEditor: React.FC<BooleanValueEditorProps> = ({ type, value, onChange }) => {
    return (
        <ValueEditorContainer>
            <input type='checkbox' checked={value} onChange={e => onChange(e.target.checked)} />
        </ValueEditorContainer>
    );
};
