import Axios, { AxiosInstance } from 'axios';
import {
    getAuthenticationCredentialsFromLocalStorage,
    useAuthentication
} from '../authentication/AuthenicationProvider';
import { useMemo } from 'react';


export const useAuthenticatedAxiosInstance = (baseURL: string): AxiosInstance => {
    const { credentialsAreAboutToExpire, reAuthenticate } = useAuthentication();

    const api = useMemo<AxiosInstance>(
        () => {
            const api = Axios.create({ baseURL });

            api.interceptors.request.use(async function (config) {
                if (credentialsAreAboutToExpire()) {
                    await reAuthenticate();
                }
                const credentials = getAuthenticationCredentialsFromLocalStorage();
                if (credentials) {
                    config.headers = {
                        ...config.headers,
                        "authorization": `Bearer ${credentials.idToken}`
                    };
                }
                return config;
            }, function (error) {
                return Promise.reject(error);
            });

            api.interceptors.response.use(async function (response) {
                if (response.status === 401) {
                }
                return response;
            }, async function (error) {
                if (error.response && error.response.status === 401) {
                    await reAuthenticate();
                }
                return Promise.reject(error);
            });

            return api;
        },
        [baseURL, credentialsAreAboutToExpire, reAuthenticate]
    );

    return api;
};