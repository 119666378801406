import React from 'react';
import styled from 'styled-components';
import { ActionButton } from '../base/ActionButton';
import { Webhook } from '../api/useWebhookApi';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
`;


const InputLabel = styled.h3`
    color: ${props => props.theme.textColor};
    padding: 4px;
`;

const WebhookDisplay = styled.div`
    color: ${props => props.theme.textColor};
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.primaryContrastColor};
`;


const Explanation = styled.p`
    color: ${props => props.theme.subtleContrastColor};
    padding: 4px;
    margin-top: 0;
    margin-bottom: 8px;
`;

interface Props {
    webhookCreatePending: boolean;
    webhook?: Webhook;
    createWebhook: () => void;
}

export const WebhookEditor: React.FC<Props> = ({
    webhookCreatePending,
    webhook,
    createWebhook
}) => {
    return (
        <InputContainer>
            <InputLabel>Webhook</InputLabel>
            {webhook ? <WebhookDisplay>{webhook?.url}</WebhookDisplay> : null}
            <Explanation>When this URL is called this node will be triggered</Explanation>
            <ActionButton disabled={webhookCreatePending} onClick={createWebhook}>
                {webhook ? 'Recreate Webhook' : 'Create Webhook'}
            </ActionButton>
        </InputContainer>
    );
};


