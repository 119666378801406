import { dictionaryFromNumberTuple } from '../../../frontend/src/GraphEditor/helpers/dictionaryFromTuple';
import { createAcceleratedConnectionStructure } from './helpers/createAcceleratedConnectionStructure';
import { DataConnection, DataGlueGraph, DataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';

function walkGraphForCycle(
    currentNode: DataNodeInstance,
    nodeDictionary: { [key: number]: DataNodeInstance },
    acceleratedConnections: { [key: number]: { [key: string]: DataConnection } },
    walkedNodeIds: number[]
): void {
    if (walkedNodeIds.includes(currentNode.id)) {
        throw new Error(`Node with ID: ${currentNode.id} was found twice while walking the graph, meaning there is a loop.`);
    }

    const nodeReceivingConnections = acceleratedConnections[currentNode.id];
    if (nodeReceivingConnections === undefined) {
        return;
    }

    for (let inPort of currentNode.inPorts) {
        const portConnection = nodeReceivingConnections[inPort.name];
        if (!portConnection) {
            continue;
        }
        const sendingNode = nodeDictionary[portConnection.sendingNodeInstanceId];
        if (!sendingNode) {
            continue;
        }
        walkGraphForCycle(sendingNode, nodeDictionary, acceleratedConnections, [...walkedNodeIds, currentNode.id]);
    }
}

export function isGraphCyclic(graph: DataGlueGraph): boolean {
    const acceleratedConnections = createAcceleratedConnectionStructure(graph.connections);
    const nodesDictionary = dictionaryFromNumberTuple(graph.nodeInstances, node => [node.id, node]);

    try {
        for(let node of graph.nodeInstances) {
            walkGraphForCycle(node, nodesDictionary, acceleratedConnections, []);
        }
        return false;
    } catch (e) {
        return true;
    }
}