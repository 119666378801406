import React from 'react';

interface Props {
    size?: number;
    color?: string;
}

export const DeleteIcon: React.FC<Props> = ({ size = 16, color = 'white', ...props}) => (
    <svg width={size} viewBox="0 0 10 10" fill="none" {...props}>
        <line
            x1={0}
            x2={10}
            y1={0}
            y2={10}
            stroke={color}
            strokeWidth={1}
        />
        <line
            x1={10}
            x2={0}
            y1={0}
            y2={10}
            stroke={color}
            strokeWidth={1}
        />
    </svg>
);
