import * as React from 'react';

import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import { ThemeType } from '@glueapp/component-library';
import { TopBar } from '../NavigationBar/TopBar';
import { KeyboardShortcutIcon } from '../base/KeyboardShortcutIcon';
import { ShortcutsSheet } from './ShortcutSheet/ShortcutsSheet';
import { ShortcutHint } from './ShortcutSheet/ShortcutHint';

const GraphName = styled.input`
    grid-area: name;
    flex-basis: 250px;
    flex-grow: 1;
    flex-shrink: 1;
    
    font-weight: bold;
    
    text-align: center;
    
    color: ${props => props.theme.textColor};
    outline: none;
    margin-top: 4px;
    margin-bottom: 4px;
    background: inherit;
    border: none;
    
    padding-top: 12px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 12px;
    
    border-radius: 8px;
    transition: background-color 0.2s ease;

        
    &:hover {
        background: ${props => props.theme.subtleAccentColor};
    }
    
    &:focus&:hover {
        background: ${props => props.theme.subtleAccentColor};
    }
    &:focus {
        background: ${props => props.theme.elevatedBackgroundColor};
    }    
`;

// Helps the user move the cursor between the button and the sheet without losing it
const HoverGapBridger = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 0;
    width: 80px;
    height: 50px;
`;

const ShortcutsButton = styled.button`
    position: relative;
    display: flex;
    background: ${props => props.theme.backgroundColor};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.subtleAccentColor};
    padding: 2px;
    outline: none;
    transition: background-color .2s ease;
    margin-right: 8px;
    
    &:hover {
        background: ${props => props.theme.subtleAccentColor};        
        cursor: pointer;
    }
    
    &:hover ${ShortcutsSheet} {
        display: flex;
    }
    &:hover ${HoverGapBridger} {
        display: block;
    }
`;


interface Props {
    graphName: string;
    className?: string;
    onGraphNameChange:  (name: string) => void;
}

export const GraphEditorTopBar: React.FC<Props> = ({ graphName, onGraphNameChange, className = '' }) =>  {
    const { primaryContrastColor } = useContext<ThemeType>(ThemeContext);

    let shortcutMetaKey = 'Ctrl';
    if (window.navigator.userAgent.includes('Mac')) {
        shortcutMetaKey = '⌘'
    }

    return (
        <TopBar
            className={className}
            renderCenter={() => (
                <GraphName value={graphName} onChange={e => onGraphNameChange(e.target.value)} />
            )}
            renderRightSection={() => (
                <>
                    <ShortcutsButton>
                        <KeyboardShortcutIcon size={32} color={primaryContrastColor} />
                        <HoverGapBridger />
                        <ShortcutsSheet>
                            <ShortcutHint keyText={'Tab'} description="Open the block library" />
                            <ShortcutHint keyText={'F'} description="Frame your view on the blocks you've selected. If you don't have any selected it will frame the whole workflow." />
                            <ShortcutHint keyText={'A'} description="Select all blocks in the workflow." />
                            <ShortcutHint keyText={`${shortcutMetaKey}+C`} description="Copy the selected blocks into your clipboard" />
                            <ShortcutHint keyText={`${shortcutMetaKey}+V`} description="Paste previously copied blocks into the graph. You can also paste things like numbers or text." />
                            <ShortcutHint keyText={'Escape'} description="Deselect all blocks." />
                            <ShortcutHint keyText={'Delete'} description="Delete selected blocks." />
                        </ShortcutsSheet>
                    </ShortcutsButton>
                </>
            )}
        />
    );
};
