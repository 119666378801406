import * as React from 'react';
import styled, { keyframes, ThemeContext } from 'styled-components';
import { MouseEvent, PointerEvent, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeType } from '@glueapp/component-library';
import {
    DataNodeInstance, NodeInstanceDataPort,
    NodeInstanceState
} from '@glueapp/graphexecution/lib/types/Graph';
import { useNodeFromNodeInstance } from '../../NodeLibraryContextProvider';
import { Vector } from '../../helpers/Vector';
import { NodeStateContextProvider } from '@glueapp/graphexecution/lib/types/NodeInstanceState';
import {
    CallIntegrationRequest,
    CallIntegrationResponse
} from '@glueapp/graphexecution/lib/types/IntegrationExecutionAccess';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { adjustHue, darken } from 'polished';
import { InPort, InPortEventHandlers, OutPort, OutPortEventHandlers } from './Ports';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { ActionButton } from '../../../base/ActionButton';
import { ExecutionError } from '@glueapp/graphexecution/lib';
import { ExecutionErrorDisplay } from './ExecutionErrorDisplay';
import { useRerenderOnElementResize } from '../helpers/useRerenderOnElementResize';

interface NodeContainerProps {
    color: string;
    position: Vector;
}

const NodeContainer = styled.div.attrs<NodeContainerProps>(props => ({
    style: {
        transform: `translate(${props.position.x+'px'}, ${props.position.y+'px'})`
    }
}))<NodeContainerProps>`
    background-color: ${props => props.color};
    background: ${props => props.color};
    min-width: 150px;
    color: white;
    position: absolute;
    border-radius: 10px;
    pointer-events: all;
    user-select: none;
`;


interface SelectedBorderOverlayProps {
    borderWidth: number;
    borderColor: string;
}

const SelectedBorderOverlay = styled.div<SelectedBorderOverlayProps>`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    border: ${props => props.borderWidth}px solid ${props => props.borderColor};
`;


const SubGraphNodeNameLabel = styled.div`
    position: absolute;
    pointer-events: none;
    text-align: right;
    color: ${props => props.theme.subtleContrastColor};
    right: 4px;
    white-space: nowrap;
    top: -22px;
`;


const highlightFade = keyframes`
    0% {opacity: 0}
    10% {opacity: 1}
    100% {opacity: 0}
`;

const HighlightOverlay = styled.div`
    position: absolute;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    animation: ${highlightFade} 2s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

const NodeBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;


const NodeInterfaceContainerAndEventInsulator = styled.div`
    user-select: auto;
    pointer-events: all;
`;

const NodeTitle = styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 1.2em;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 12px;
`;

const InPortList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
`;

const OutPortList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    margin-bottom: 8px;
`;

const ArrowIcon = styled.svg`
    width: 8px;
    padding-left: 4px;
    transition: transform .2s ease;
    
    ${ActionButton}:hover & {
        transform: translateX(4px);
    }
`;


interface Props extends InPortEventHandlers, OutPortEventHandlers {
    nodeInstance: DataNodeInstance;
    handleMouseDown?: (event: PointerEvent, nodeInstance: DataNodeInstance) => void;
    handleMouseUp?: (event: PointerEvent, nodeInstance: DataNodeInstance) => void;
    subGraphNodeName?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>, nodeInstance: DataNodeInstance) => void;
    onDoubleClick?: (event: MouseEvent<HTMLDivElement>, nodeInstance: DataNodeInstance) => void;
    onEditClick?: () => void;
    theme: ThemeType;
    outputValues?: { [key: string]: DataValue};
    selectedWidthMultiplier?: number;
    onEnterSubGraphClicked?: (nodeInstanceId: number) => void;
    runTimes?: Set<number>;
    onOutPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    onInPortMouseUp?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    onInPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    integrationAccesses: IntegrationAccess[];
    callIntegration(integrationAccess: IntegrationAccess, requestConfig: CallIntegrationRequest): Promise<CallIntegrationResponse<any>>;
    setInPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    setOutPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    setNodeDimensions: (nodeId: number, dimensions: Vector) => void;
    setNodeState: (id: number, state: NodeInstanceState) => void;
    setNodeShape: (id: number, shape: { inPorts: NodeInstanceDataPort[], outPorts: NodeInstanceDataPort[]}) => void;
    triggerManualRun?: (nodeInstanceId: number) => void;
    executionError?: ExecutionError;
}

const BaseGraphNode: React.FC<Props> = ({
    nodeInstance,
    handleMouseDown,
    handleMouseUp,
    subGraphNodeName,
    onClick,
    onDoubleClick,
    theme,
    outputValues,
    selectedWidthMultiplier = 1,
    onOutPortMouseDown,
    onInPortMouseUp,
    onInPortMouseDown,
    runTimes,
    integrationAccesses,
    callIntegration,
    setInPortPosition,
    setOutPortPosition,
    setNodeDimensions,
    setNodeState,
    setNodeShape,
    triggerManualRun,
    executionError
}) => {
    const fullBodySizeElementRef = useRef<HTMLDivElement>(null);
    const [measuredNodeWidth, setMeasuredNodeWidth] = useState<number>(0);
    const [measuredNodeHeight, setMeasuredNodeHeight] = useState<number>(0);
    const { subtleContrastColor } = useContext<ThemeType>(ThemeContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        const nodeContainerElement = fullBodySizeElementRef.current;
        if (nodeContainerElement) {
            setMeasuredNodeWidth(nodeContainerElement.scrollWidth);
            setMeasuredNodeHeight(nodeContainerElement.scrollHeight);
        }
    });

    useEffect(() => {
        setNodeDimensions(nodeInstance.id, { x: measuredNodeWidth, y: measuredNodeHeight });
    }, [measuredNodeWidth, measuredNodeHeight, setNodeDimensions, nodeInstance.id]);

    const { elementRef: nodeInterfaceContainerRef } = useRerenderOnElementResize();

    const node = useNodeFromNodeInstance(nodeInstance);
    const inPorts = nodeInstance.inPorts;
    const outPorts = nodeInstance.outPorts;


    const renderNodeInterfaceComponent = () => {
        const NodeInterfaceComponent = node.interfaceComponent;
        if (!NodeInterfaceComponent) {
            return null;
        }

        if (node.integration) {
            const integrationAccess = integrationAccesses.find(ia => ia.provider === node.integration?.provider);
            if (!integrationAccess) {
                return null;
            }

            // TODO it might be worth it to optimize these props a bit
            return (
                <NodeInterfaceComponent
                    integration={{
                        callIntegration: (request: CallIntegrationRequest) => callIntegration(integrationAccess, request)
                    }}
                    nodeShape={{
                        nodeInPorts: node.inPorts,
                        nodeOutPorts: node.outPorts,
                        nodeInstanceInPorts: nodeInstance.inPorts,
                        nodeInstanceOutPorts: nodeInstance.outPorts
                    }}
                    renderSideBar={() => {}}
                    updateShape={shape => setNodeShape(nodeInstance.id, shape)}
                />
            );
        } else {
            return (
                <NodeInterfaceComponent
                    integration={undefined}
                    nodeShape={{
                        nodeInPorts: node.inPorts,
                        nodeOutPorts: node.outPorts,
                        nodeInstanceInPorts: nodeInstance.inPorts,
                        nodeInstanceOutPorts: nodeInstance.outPorts
                    }}
                    renderSideBar={() => {}}
                    updateShape={shape => setNodeShape(nodeInstance.id, shape)}
                />
            );
        }
    }

    const renderManualTrigger = () => {
        if(node && node.id === 'Manual Trigger') {
            return (
                <ActionButton onClick={(e) => {
                    if(triggerManualRun) {
                        triggerManualRun(nodeInstance.id);
                    }
                }}>
                    Trigger Workflow
                    <ArrowIcon viewBox="0 0 6 10">
                        <polyline
                            points="1 1, 5 5, 1 9"
                            fill="none"
                            stroke={subtleContrastColor}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </ArrowIcon>
                </ActionButton>
            );
        } else {
            return null;
        }
    }

    return (
        <NodeContainer
            position={nodeInstance.position}
            color={node.color}

            onClick={e => { e.preventDefault(); e.stopPropagation(); onClick && onClick(e, nodeInstance); }}
            onDoubleClick={e => onDoubleClick && onDoubleClick(e, nodeInstance)}
            onPointerDown={event => handleMouseDown && handleMouseDown(event, nodeInstance)}
            onPointerUp={event => handleMouseUp && handleMouseUp(event, nodeInstance)}
        >
            <SelectedBorderOverlay
                ref={fullBodySizeElementRef}

                borderWidth={nodeInstance.selected ? 2 * selectedWidthMultiplier : 1}
                borderColor={nodeInstance.selected ? theme.primaryContrastColor : '#3a3d42'}
            />
            {runTimes ? Array.from(runTimes).map(runTime => (
                <HighlightOverlay
                    key={runTime}
                />
            )) : null}
            <SubGraphNodeNameLabel>
                {nodeInstance.subGraphId !== undefined ? ` [${subGraphNodeName}]` : ''}
            </SubGraphNodeNameLabel>
            {executionError ? (
                <ExecutionErrorDisplay position={{x: 0, y: measuredNodeHeight + 8 }}>
                    {executionError?.error}
                </ExecutionErrorDisplay>
            ) : null}
            <NodeTitle>
                {node.name}
            </NodeTitle>
            <NodeBody>
                <InPortList>
                    {inPorts.map((port, index) => (
                        <InPort
                            key={index}
                            port={port}
                            nodeInstance={nodeInstance}
                            theme={theme}
                            onInPortMouseDown={onInPortMouseDown}
                            onInPortMouseUp={onInPortMouseUp}
                            setPortPosition={setInPortPosition}
                        />
                    ))}
                </InPortList>
                <NodeInterfaceContainerAndEventInsulator
                    ref={nodeInterfaceContainerRef}
                    onClick={e => { e.stopPropagation(); }}
                    // onDoubleClick={e => { e.stopPropagation(); }}
                    onKeyDown={e => { e.stopPropagation(); }}
                    onPointerDown={e => { e.stopPropagation(); }}
                    onPointerUp={e => { e.stopPropagation(); }}
                    onMouseDown={e => { e.stopPropagation(); }}
                    onMouseUp={e => { e.stopPropagation(); }}
                >
                    <NodeStateContextProvider
                        nodeInstanceState={nodeInstance.state}
                        setNodeInstanceState={(state: NodeInstanceState) => setNodeState(nodeInstance.id, state)}
                    >
                        {renderNodeInterfaceComponent()}
                    </NodeStateContextProvider>
                    {renderManualTrigger()}
                </NodeInterfaceContainerAndEventInsulator>
                <OutPortList>
                    {outPorts.map((port, index) => (
                        <OutPort
                            key={index}
                            port={port}
                            nodeInstance={nodeInstance}
                            theme={theme}
                            outputValue={outputValues?.[port.name]}
                            onOutPortMouseDown={onOutPortMouseDown}
                            setPortPosition={setOutPortPosition}
                        />
                    ))}
                </OutPortList>
            </NodeBody>
        </NodeContainer>
    );
}

export const GraphNode = React.memo(BaseGraphNode);
