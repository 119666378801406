import * as React from 'react';
import styled from 'styled-components';
import { KeyIcon } from './KeyIcon';


export const HintRow = styled.div`
    padding: 4px;
    display: flex;
    width: 500px;
    justify-content: space-between;
    align-items: center;
`;

export const HintText = styled.p`
    width: 400px;
    text-align: left;
        
`;

interface Props {
    keyText: string;
    description: string;
}

export const ShortcutHint: React.FC<Props> = ({ keyText, description}) => {
    return (
        <HintRow>
            <KeyIcon label={keyText} />
            <HintText>{description}</HintText>
        </HintRow>
    );
};
