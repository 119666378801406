import * as React from 'react';
import { ArrayDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { Title, ValueEditorContainer } from '@glueapp/component-library';
import { ShapeTypeSelectorEditor } from './ShapeValueEditor';

interface ArrayValueEditorProps {
    value: ArrayDataType;
    onChange: (value: ArrayDataType) => void;
}

export const ShapeArrayEditor: React.FC<ArrayValueEditorProps> = ({ value, onChange }) => {
    return (
        <ValueEditorContainer>
            <Title>
                List of
            </Title>
            <ShapeTypeSelectorEditor value={value.subType} onChange={newValue => onChange({ kind: 'array', subType: newValue })} />
        </ValueEditorContainer>
    );
};
