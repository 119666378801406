import * as React from 'react';
import { useCallback } from 'react';
import { ChangeEvent } from 'react';
import { SelectField, ValueEditorContainer } from '@glueapp/component-library';
import { DataType, getStringRepresentationFromType, UnionDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { DataValue, getDefaultValueForDataType, matchesDataType } from '@glueapp/graphexecution/lib/types/DataValue';
import { renderEditField } from './InputValueEditor';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';


interface UnionValueEditorProps {
    type: UnionDataType;
    value: DataValue;
    userDefinedType?: UserDefinedType;
    onChange: (value: DataValue, updatedType?: UserDefinedType) => void;
}


export const UnionValueEditor: React.FC<UnionValueEditorProps> = ({
    type,
    value,
    userDefinedType,
    onChange
}) => {
    const handleSelectChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const index = parseInt(event.target.value, 10);
        const matchingOption = type.subTypes[index];
        if (matchingOption) {
            const newValue = (!matchesDataType(value, matchingOption)) ? getDefaultValueForDataType(matchingOption) : value;
            onChange(newValue, { kind: 'union', selectedTypeIndex: index });
        }
    }, [type, onChange, value]);

    const selectedOption: DataType = (userDefinedType && userDefinedType.kind === 'union') ?
        (type.subTypes[userDefinedType.selectedTypeIndex] ?? type.subTypes[0]) : type.subTypes[0];

    return (
        <ValueEditorContainer>
            <SelectField value={userDefinedType?.kind === 'union' ? userDefinedType.selectedTypeIndex : 0} onChange={handleSelectChange}>
                {type.subTypes.map((subType, index) => (
                    <option
                        key={index}
                        value={index}
                    >
                        {getStringRepresentationFromType(subType)}
                    </option>
                ))}
            </SelectField>
            {renderEditField(selectedOption, value, userDefinedType, onChange)}
        </ValueEditorContainer>
    );
};
