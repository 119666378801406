import styled from 'styled-components';
import { GraphEditorTopBar } from './GraphEditorTopBar';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    display: grid;
    grid-template-rows: 54px 1fr;
    grid-template-areas: 
        "topbar"
        "main"
`;

export const StyledTopBar = styled(GraphEditorTopBar)` 
    grid-area: topbar;
`;

export const SideBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    
    opacity: 0.4;
    transition: opacity 0.1s ease-in-out;
   
    &:hover {
        opacity: 1;
        pointer-events: auto;
    }
    
    background-color: ${props => props.theme.elevatedBackgroundColor};
    border-right: 2px solid ${props => props.theme.separatorColor};
    
    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.backgroundColor};
      border: 1px solid ${props => props.theme.subtleAccentColor};
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.backgroundColor};
      border: 0 none #ffffff;
      border-radius: 53px;
    }
    ::-webkit-scrollbar-track:hover {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-track:active {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    overflow-y: scroll;
`;

export const MainSection = styled.div`
    grid-area: main;
    position: relative;
    overflow: hidden;
`;
