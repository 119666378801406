import { getVectorLength, subtractVectors, Vector } from '../../helpers/Vector';
import { DataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';


export interface Bounds {
    topLeft: Vector;
    bottomRight: Vector;
}

export const boundsFrom2Vectors = (a: Vector, b: Vector): Bounds => {
    return {
        topLeft: {
            x: Math.min(a.x, b.x),
            y: Math.min(a.y, b.y)
        },
        bottomRight: {
            x: Math.max(a.x, b.x),
            y: Math.max(a.y, b.y)
        }
    }
}

export const computeNodeBounds = (node: DataNodeInstance): Bounds => {
    return {
        topLeft: {
            x: node.position.x,
            y: node.position.y
        },
        bottomRight: {
            x: node.position.x + (node.width ?? 0),
            y: node.position.y + (node.height ?? 0)
        }
    }
};


export const mergeBounds = (boundsA: Bounds, boundsB: Bounds): Bounds => ({
    topLeft: { x: Math.min(boundsA.topLeft.x, boundsB.topLeft.x), y: Math.min(boundsA.topLeft.y, boundsB.topLeft.y)},
    bottomRight: { x: Math.max(boundsA.bottomRight.x, boundsB.bottomRight.x), y: Math.max(boundsA.bottomRight.y, boundsB.bottomRight.y)},
});

export const mergeBoundsList = (boundsList: [Bounds, ...Bounds[]]): Bounds => boundsList.reduce(mergeBounds);


export const getBoundsSize = (bounds: Bounds): number => getVectorLength(subtractVectors(bounds.topLeft, bounds.bottomRight));

export const getBoundsWidth = (bounds: Bounds): number => bounds.bottomRight.x - bounds.topLeft.x;
export const getBoundsHeight = (bounds: Bounds): number => bounds.bottomRight.y - bounds.topLeft.y;

export const doBoundsIntersect = (a: Bounds, b: Bounds): boolean =>
    a.topLeft.x < b.bottomRight.x && b.topLeft.x < a.bottomRight.x && a.topLeft.y < b.bottomRight.y && b.topLeft.y < a.bottomRight.y;

export const isBoundsInsideOfBounds = (a: Bounds, b: Bounds): boolean =>
    isPointInBounds(a.topLeft, b) && isPointInBounds(a.bottomRight, b);

export const isPointInBounds = (point: Vector, bounds: Bounds): boolean =>
    point.x > bounds.topLeft.x && point.x < bounds.bottomRight.x &&
    point.y > bounds.topLeft.y && point.y < bounds.bottomRight.y;