import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.backgroundColor + 'AA'};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    background-color: ${props => props.theme.backgroundColor};
    border: 2px solid ${props => props.theme.separatorColor};
    border-radius: 8px;
    
    padding: 16px;
    max-width: 500px;
    min-width: 300px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    
    box-shadow: 0 0 10px 5px rgba(0,0,0,0.25);
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

const Message = styled.h2`
    color: ${props => props.theme.textColor};
    margin-top: 0;
    text-align: center;
    margin-bottom: 16px;
`;


interface Props {
    message: string;
    confirmationLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export const Prompt: React.FC<Props> = ({ message, confirmationLabel = 'OK', cancelLabel = 'Cancel', onConfirm, onCancel }) => {
    return (
        <Container onClick={onCancel}>
            <Box>
                <Message>{message}</Message>
                <ButtonRow>
                    <Button hoverColor={'#b0b0b0'} onClick={onCancel}>{cancelLabel}</Button>
                    <Button hoverColor={'#e23842'} onClick={onConfirm}>{confirmationLabel}</Button>
                </ButtonRow>
            </Box>
        </Container>
    );
};
