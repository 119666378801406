import { useAuthenticatedAxiosInstance } from './useAuthenticatedAxiosInstance';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';

export const useIntegrationAuthorizationApi = () => {
    const api = useAuthenticatedAxiosInstance(`${getEnvironmentConfig().integrationServiceUrl}`);

    const startAuthorization = useCallback(
        async (): Promise<{ authorizationAttemptId: string }> => {
            const result: AxiosResponse = await api.post(
                '/authorize-integration',
                null,
                { params: { successUrl: window.location.href } }
            );
            return result.data;
        },
        [api]
    );


    return {
        startAuthorization
    }
};
