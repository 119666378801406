import * as React from 'react';
import { SCurve } from '../BaseComponents/SCurve';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Vector } from '../../helpers/Vector';
import { DataConnection, DataNodeInstance, NodeInstanceDataPort } from '@glueapp/graphexecution/lib/types/Graph';
import { getColorFromDataType } from '../../dataLayer/conversions/getColorFromDataType';
import { ConnectionValidation } from '@glueapp/graphexecution/lib/validation/validateGraph';
import { ThemeType } from '@glueapp/component-library';
import { ThemeContext } from 'styled-components';

interface Props {
    connection: DataConnection;
    startPosition: Vector;
    endPosition: Vector;
    sendingNodeInstance: DataNodeInstance|undefined;
    receivingNodeInstance: DataNodeInstance|undefined;
    onMouseDown: (event: MouseEvent, connection: DataConnection) => void;
    validation?: ConnectionValidation;
    onHoverChange: (connectionId: number, hovered: boolean) => void;
}

const BaseGraphConnection: React.FC<Props> = ({
    connection,
    startPosition,
    endPosition,
    sendingNodeInstance,
    receivingNodeInstance,
    onMouseDown,
    validation,
    onHoverChange,
}) => {
    const [hovered, setHovered] = useState<boolean>(false);
    useEffect(() => {
        onHoverChange(connection.id, hovered);
    }, [connection.id, hovered, onHoverChange]);

    const { primaryContrastColor } = useContext<ThemeType>(ThemeContext);
    // Nodes
    if (!sendingNodeInstance)
        return null;

    if (!receivingNodeInstance)
        return null;

    const isTriggerConnection = sendingNodeInstance.outPorts.find(port => port.name === connection.sendingPortName)?.type?.kind === 'trigger';

    //Sending port
    let sendingPort: NodeInstanceDataPort|undefined;

    switch (connection.sendingPortType) {
        case 'internal':
            if (!sendingNodeInstance.hasSubGraph)
                return null;
            sendingPort = sendingNodeInstance.internalOutPorts.find(port => port.name === connection.sendingPortName);
            if (!sendingPort)
                return null;
            break;
        case 'external': {
            sendingPort = sendingNodeInstance.outPorts.find(port => port.name === connection.sendingPortName);
            if (!sendingPort)
                return null;
            break;
        }
    }
    if (!sendingPort) {
        return null;
    }

    const color = (!validation || validation.valid) ? getColorFromDataType(sendingPort.type) : 'red';

    return (
        <>
            <SCurve
                startPosition={startPosition}
                endPosition={endPosition}
                strokeColor={isTriggerConnection ? primaryContrastColor : color}
                strokeWidth={isTriggerConnection ? (hovered ? 6 : 3) : (hovered ? 5 : 2)}
                onMouseDown={e => onMouseDown(e, connection)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            />
        </>
    );
};

export const GraphConnection = React.memo(BaseGraphConnection);
