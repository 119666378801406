import { useAuthenticatedAxiosInstance } from './useAuthenticatedAxiosInstance';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';

export interface Webhook {
    id: string;
    url: string;
    nodeInstanceId: number;
}

export const useWebhookApi = () => {
    const api = useAuthenticatedAxiosInstance(`${getEnvironmentConfig().webhookServiceUrl}`);

    const getWebhooksForGraph = useCallback(
        async (graphId: string): Promise<Webhook[]> => {
            const result: AxiosResponse = await api.get(
                `/get-graph-webhooks/${graphId}`
            );
            return result.data.webhooks;
        },
        [api]
    );

    const createWebhook = useCallback(
        async (graphId: string, nodeInstanceId: number): Promise<Webhook> => {
            const result: AxiosResponse = await api.post(
                `/create-webhook`,
                { graphId, nodeInstanceId }
            );
            return result.data.webhook;
        },
        [api]
    );

    return {
        getWebhooksForGraph,
        createWebhook
    }
};
