import React, { useCallback } from "react";
import { getEnvironmentConfig } from "../environmentConfig";
import { ActionButton } from "../base/ActionButton";
import { useIntegrationAuthorizationApi } from "../api/useIntegrationAuthorizationApi";
import { GlueIntegration } from '@glueapp/graphexecution/lib/types/Integration';

interface Props {
    integration: GlueIntegration<any, any, any>;
    label: string;
}

export const IntegrationAuthorizer: React.FC<Props> = ({ integration, label }) => {
    const { startAuthorization } = useIntegrationAuthorizationApi();

    const authorizationFlow = useCallback(() => {
        let aborted = false;
        async function runFlow() {
            const { authorizationAttemptId } = await startAuthorization();
            if (!aborted) {
                window.location.href = `${getEnvironmentConfig().integrationServiceUrl}/authorize-integration/run/${integration.provider}/${authorizationAttemptId}`;
            }
        }
        runFlow()
        return () => { aborted = true; };
    }, [startAuthorization, integration]);

    return (
        <ActionButton onClick={authorizationFlow}>
            {label}
        </ActionButton>
    );
}
