import * as React from 'react';
import { StringValueEditor } from './StringValueEditor';
import { NumberValueEditor } from './NumberValueEditor';
import { BooleanValueEditor } from './BooleanValueEditor';
import { ObjectValueEditor } from './ObjectValueEditor';
import { ArrayValueEditor } from './ArrayValueEditor';
import { DataType } from '@glueapp/graphexecution/lib/types/DataType';
import { DataValue, ShapeValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { UnionValueEditor } from './UnionValueEditor';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';
import { StringLiteralValueEditor } from './StringLiteralValueEditor';
import { NumberLiteralValueEditor } from './NumberLiteralValueEditor';
import { ShapeValueEditor } from '../ShapeValueEditor/ShapeValueEditor';
import { ReactNode } from 'react';


interface Props {
    type: DataType;
    value: DataValue;
    userDefinedType?: UserDefinedType;
    onChange: (value: DataValue, updatedType?: UserDefinedType) => void;
}


export const renderEditField = (
    type: DataType,
    value: DataValue,
    userDefinedType: UserDefinedType|undefined,
    onChange: (value: DataValue, updatedType: UserDefinedType|undefined) => void
): ReactNode|void => {
    switch(type.kind) {
        case 'generic':
            if (type.resolvedType) {
                return renderEditField(type.resolvedType, value, userDefinedType, onChange);
            }
            break;
        case 'shape':
            return (
                <ShapeValueEditor
                    type={type}
                    value={value as ShapeValue}
                    onChange={onChange}
                />
            );
        case 'string':
            return (
                <StringValueEditor
                    type={type}
                    value={value as string}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
        case 'number':
            return (
                <NumberValueEditor
                    type={type}
                    value={value as number}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
        case 'boolean':
            return (
                <BooleanValueEditor
                    type={type}
                    value={value as boolean}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
        case 'stringLiteral':
            return (
                <StringLiteralValueEditor
                    type={type}
                />
            );
        case 'numberLiteral':
            return (
                <NumberLiteralValueEditor
                    type={type}
                />
            );
        case 'union':
            return (
                <UnionValueEditor
                    type={type}
                    value={value}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
        case 'object':
            return (
                <ObjectValueEditor
                    type={type}
                    value={value as { [key: string]: DataValue }}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
        case 'array':
            return (
                <ArrayValueEditor
                    type={type}
                    value={value as DataValue[]}
                    userDefinedType={userDefinedType}
                    onChange={onChange}
                />
            );
    }
};

export const InputValueEditor: React.FC<Props> = (props) => {
    return (
        <>
            {renderEditField(props.type, props.value, props.userDefinedType, props.onChange)}
        </>
    )
};
