import * as React from 'react';
import { useContext } from 'react';
import { ThemeType } from '@glueapp/component-library';
import { ThemeContext } from 'styled-components';

export function GraphBackgroundPattern() {
    const themeContext = useContext<ThemeType>(ThemeContext);
    return (
        <>
            <defs>
                <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
                    <path d="M 8 0 L 0 0 0 8" fill="none" stroke={themeContext.subtleAccentColor} strokeWidth="0.5"/>
                </pattern>
                <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                    <rect width="80" height="80" fill="url(#smallGrid)"/>
                    <path d="M 80 0 L 0 0 0 80" fill="none" stroke={themeContext.subtleAccentColor} strokeWidth="1"/>
                </pattern>
            </defs>;
            <rect width="100%" height="100%" fill="url(#grid)"/>
        </>
    );
}
