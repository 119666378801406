import { useCallback, useEffect, useState } from 'react';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { useIntegrationAccessApi } from '../api/useIntegrationAccessApi';

export const useIntegrationAccesses = () => {
    const { getAllIntegrationAccesses, deleteIntegrationAccess: deleteIntegrationAccessApi } = useIntegrationAccessApi();

    const [integrationAccesses, setIntegrationAccesses] = useState<IntegrationAccess[]|undefined>(undefined);
    const [invalidationCounter, setInvalidationCounter] = useState(0);

    useEffect(() => {
        let aborted = false;
        const performCall = async () => {
            const retrievedIntegrationAccesses = await getAllIntegrationAccesses();
            if(!aborted) {
                setIntegrationAccesses(retrievedIntegrationAccesses);
            }
        }
        performCall();

        return () => { aborted = true; };
    }, [invalidationCounter, getAllIntegrationAccesses]);


    const deleteIntegrationAccess = useCallback((integrationAccess: IntegrationAccess) => {
        async function runFlow() {
            await deleteIntegrationAccessApi(integrationAccess);
            setInvalidationCounter(invalidationCounter + 1);
        }
        runFlow()
    }, [deleteIntegrationAccessApi, invalidationCounter]);

    return {
        integrationAccesses,
        deleteIntegrationAccess
    }
}
