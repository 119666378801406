import * as React from 'react';
import {
    InputValueEditorButton,
    Title,
    ValueEditorContainer,
    ValueItemIndex,
    ValueList,
    ValueListItem
} from '@glueapp/component-library';
import styled from 'styled-components';
import { ArrayDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { DataValue, getDefaultValueForDataType } from '@glueapp/graphexecution/lib/types/DataValue';
import { renderEditField } from './InputValueEditor';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';


const DeleteItemButton = styled(InputValueEditorButton)`
    flex-grow: 0;
    margin-right: 16px;
`;

interface ArrayValueEditorProps {
    type: ArrayDataType;
    value: DataValue[];
    userDefinedType?: UserDefinedType;
    onChange: (value: DataValue[], updatedType?: UserDefinedType) => void;
}

export const ArrayValueEditor: React.FC<ArrayValueEditorProps> = ({ type, value, userDefinedType, onChange }) => {
    return (
        <ValueEditorContainer>
            <Title>
                List
            </Title>
            <ValueList>
                {
                    value.map((element, index) => (
                        <ValueListItem key={index}>
                            <ValueItemIndex>
                                {index}:
                            </ValueItemIndex>
                            <DeleteItemButton onClick={() => onChange([...value.slice(0, index), ...value.slice(index+1)])}>-</DeleteItemButton>
                            {renderEditField(
                                type.subType,
                                element,
                                userDefinedType,
                                (changedValue) => onChange([
                                    ...value.slice(0, index),
                                    changedValue,
                                    ...value.slice(index+1)
                                ])
                            )}
                        </ValueListItem>
                    ))
                }
            </ValueList>
            <InputValueEditorButton onClick={() => onChange([...value, getDefaultValueForDataType(type.subType)])}>+</InputValueEditorButton>
        </ValueEditorContainer>
    );
};
