export interface Vector {
    x: number;
    y: number;
}

export const addVectors = (a: Vector, b: Vector): Vector => {
    return {
        x: a.x + b.x,
        y: a.y + b.y
    };
};


export const subtractVectors = (a: Vector, b: Vector): Vector => {
    return {
        x: a.x - b.x,
        y: a.y - b.y
    };
};


export const multiplyVector = (vector: Vector, scalar: number): Vector => {
    return {
        x: vector.x * scalar,
        y: vector.y * scalar
    };
};

export const getVectorLength = (vector: Vector): number => {
    return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
};

export const normalizeVector = (vector: Vector): Vector => {
    const length = getVectorLength(vector);
    return length ? multiplyVector(vector, 1 / length) : { x: 0, y: 0 };
};

export const interpolateVectors = (a: Vector, b: Vector, blend: number): Vector => {
    return addVectors(multiplyVector(a, blend), multiplyVector(b, 1 - blend));
};
