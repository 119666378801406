import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GraphListLayout } from './GraphList/GraphListLayout';
import { GraphEditorLayout } from './GraphEditor/GraphEditorLayout';
import { LoginPage } from './authentication/LoginPage';
import { LoginSuccessHandler } from './authentication/LoginSuccessHandler';

export function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={GraphListLayout} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/login-success" component={LoginSuccessHandler} />
            <Route
                exact
                path="/graph/:graphId"
                render={(props) => <GraphEditorLayout graphId={props.match.params.graphId} />}
            />
        </Switch>
    );
}
