import { DataGlueGraph } from '@glueapp/graphexecution/lib/types/Graph';
import React, { Dispatch, Reducer, ReducerAction, useContext } from 'react';
import { GraphReducerActions } from './actions';

export type GraphUpdateContextType = Dispatch<ReducerAction<Reducer<DataGlueGraph|undefined, GraphReducerActions>>>

export const GraphUpdateContext = React.createContext<GraphUpdateContextType>(() => {});

export const useGraphUpdateDispatch = (): GraphUpdateContextType => {
    return useContext(GraphUpdateContext);
};