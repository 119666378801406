import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuthentication } from './AuthenicationProvider';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const LoginSuccessHandler = () => {
    const { setAuthenticationCredentials } = useAuthentication();
    const query = useQuery();

    const idToken = query.get('idToken');
    const accessToken = query.get('accessToken');
    const refreshToken = query.get('refreshToken');

    if (idToken && accessToken && refreshToken) {
        setAuthenticationCredentials(idToken, accessToken, refreshToken);
    }

    return (
        <Redirect to="/" />
    );
};
