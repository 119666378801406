import { useCallback } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';

export const useLoginApi = () => {
    const refreshLogin = useCallback(
        async (refreshToken: string): Promise<{ idToken: string, accessToken: string }> => {
            const result: AxiosResponse = await Axios.post(
                `${getEnvironmentConfig().authenticationServiceUrl}/login-refresh`,
                {
                    refreshToken
                }
            );
            return result.data;
        },
        []
    );


    return {
        refreshLogin
    }
};
