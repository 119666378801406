import { Vector } from '../../helpers/Vector';

export const screenToWorldCoordinate = (screenPosition: Vector, scrollPosition: Vector, zoomLevel: number): Vector => ({
    x: (screenPosition.x + scrollPosition.x) / zoomLevel,
    y: (screenPosition.y + scrollPosition.y) / zoomLevel
});

export const worldToScreenCoordinate = (worldPosition: Vector, scrollPosition: Vector, zoomLevel: number): Vector => ({
    x: worldPosition.x * zoomLevel - scrollPosition.x,
    y: worldPosition.y * zoomLevel - scrollPosition.y
});
