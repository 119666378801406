import { useCallback, useReducer } from 'react';

export enum AsyncStateStatus {
    INITIAL = 'INITIAL',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
}
interface UseAsyncStateState<T> {
    data: T;
    status: AsyncStateStatus;
    error?: Error;
}
type UseAsyncStateAction<T> =
    { type: AsyncStateStatus.INITIAL}|
    { payload: T, type: AsyncStateStatus.SUCCESS, }|
    { type: AsyncStateStatus.PENDING}|
    { type: AsyncStateStatus.FAILED, error: Error };

type UseAsyncStateReducer<T> = (prevState: UseAsyncStateState<T>, action: UseAsyncStateAction<T>) => UseAsyncStateState<T>;

export interface UseAsyncStateReturnValue<T> {
    data: T;
    status: AsyncStateStatus;
    error: Error|undefined;
    reset: () => void;
    setPending: () => void;
    setError: (error: Error) => void;
    setSuccess: (payload: T) => void;
}

export function useAsyncState<T>(initialValue: T): UseAsyncStateReturnValue<T> {
    const [state, dispatch] = useReducer<UseAsyncStateReducer<T>>(
        (state, action: UseAsyncStateAction<T>) => {
            switch (action.type) {
                case AsyncStateStatus.INITIAL:
                    return {
                        status: AsyncStateStatus.INITIAL,
                        data: initialValue,
                        error: undefined
                    };
                case AsyncStateStatus.PENDING:
                    return {
                        status: AsyncStateStatus.PENDING,
                        error: undefined,
                        data: state.data
                    };
                case AsyncStateStatus.FAILED:
                    return {
                        status: AsyncStateStatus.FAILED,
                        data: initialValue,
                        error: action.error
                    };
                case AsyncStateStatus.SUCCESS:
                    return {
                        status: AsyncStateStatus.SUCCESS,
                        data: action.payload,
                        error: undefined
                    };
                default:
                    return state;
            }
        },
        { data: initialValue, status: AsyncStateStatus.INITIAL, error: undefined }
    );

    const reset = useCallback(() => dispatch({ type: AsyncStateStatus.INITIAL }), []);
    const setPending = useCallback(() => dispatch({ type: AsyncStateStatus.PENDING }), []);
    const setError = useCallback((error: Error) => dispatch({ type: AsyncStateStatus.FAILED, error: error }), []);
    const setSuccess = useCallback((payload: T) => dispatch({ type: AsyncStateStatus.SUCCESS, payload: payload }), []);

    return {
        data: state.data,
        status: state.status,
        error: state.error,
        reset,
        setPending,
        setError,
        setSuccess
    };
}
