import {
    ADD_CONNECTION,
    REMOVE_CONNECTION,
    ADD_NODE,
    REMOVE_NODES,
    SELECT_NODES,
    DESELECT_NODES,
    SELECT_ONLY_ONE_NODE,
    DESELECT_ALL_NODES,
    GraphReducerActions,
    MOVE_NODES,
    REFRAME_SUB_GRAPH_NODE,
    MOVE_NODES_END,
    SET_GRAPH,
    REFRAME_SUB_GRAPH_NODE_END,
    SET_NODE_DIMENSIONS,
    SET_PORT_VALUE_AND_TYPE,
    SELECT_BRANCH_ADD,
    SELECT_BRANCH,
    ADD_VARIABLE,
    DELETE_VARIABLE,
    SET_SELECTED_VARIABLE,
    UPDATE_VARIABLE,
    MERGE_IN_GRAPH,
    SET_NODE_STATE,
    SET_NODE_SHAPE,
    SET_NODE_SELECTED_INTEGRATION_ACCESS
} from './actions';
import { getHighestIdFromObjectsWithIds } from './drawingLayer/helpers/getHighestIdFromObjectsWithIds';
import { Bounds, computeNodeBounds, getBoundsSize, isBoundsInsideOfBounds } from './drawingLayer/helpers/bounds';
import { isNodeWithinListOfSubGraphNodes, isSubGraphNode } from './drawingLayer/helpers/subGraphHelpers';
import { DataConnection, DataGlueGraph, DataNodeInstance, SubGraphDataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';
import { addVectors, subtractVectors } from './helpers/Vector';
import { NODE_LIBRARY_DICTIONARY } from '@glueapp/graphexecution/lib/nodeLibrary';
import { getDefaultValueForDataType } from '@glueapp/graphexecution/lib/types/DataValue';
import { dictionaryFromStringTuple, dictionaryFromNumberTuple } from './helpers/dictionaryFromTuple';
import { isGraphCyclic } from './isGraphCyclic';
import { createAcceleratedConnectionStructure } from './helpers/createAcceleratedConnectionStructure';
import { DRAW_CONFIG } from './drawingLayer/drawConfig.constant';

function getNodeIdsInBranch(
    currentNode: DataNodeInstance,
    allNodes: { [key: number]: DataNodeInstance },
    acceleratedConnections: { [key: number]: { [key: string]: DataConnection } }
): number[] {
    const nodeReceivingConnections = acceleratedConnections[currentNode.id];
    if (nodeReceivingConnections === undefined) {
        return [currentNode.id];
    }
    return [
        currentNode.id,
        ...currentNode.inPorts.flatMap(port => {
            const portConnection = nodeReceivingConnections[port.name];
            if (!portConnection) {
                return [];
            }
            const sendingNode = allNodes[portConnection.sendingNodeInstanceId];
            if (!sendingNode) {
                return [];
            }
            return getNodeIdsInBranch(sendingNode, allNodes, acceleratedConnections);
        })
    ];
}


function isSendingNodeWithinReceivingNodesSubGraphUpTheChain(
    sendingNode: DataNodeInstance,
    receivingNode: DataNodeInstance,
    allNodes: { [key: number]: DataNodeInstance }
): boolean {
    const receivingNodeSubGraphId = receivingNode.subGraphId;
    if (receivingNodeSubGraphId === undefined) {
        return false;
    }
    let currentSubGraphNode: DataNodeInstance = allNodes[receivingNodeSubGraphId];
    while (currentSubGraphNode.subGraphId !== undefined) {
        currentSubGraphNode = allNodes[currentSubGraphNode.subGraphId]
        if (sendingNode.subGraphId === currentSubGraphNode.id) {
            return true;
        }
    }
    return false;
}


function updateSubGraphRelationships(graph: DataGlueGraph): DataGlueGraph {
    const subGraphNodes = graph.nodeInstances.filter(isSubGraphNode);

    const subGraphNodeBoundsSortedBySize: { nodeInstance: SubGraphDataNodeInstance, bounds: Bounds, boundsSize: number }[] =
        subGraphNodes.map(nodeInstance => {
                const bounds: Bounds = computeNodeBounds(nodeInstance);
                return { nodeInstance, bounds , boundsSize: getBoundsSize(bounds) };
            })
            .sort((a, b) => a.boundsSize - b.boundsSize);

    const updatedNodeInstances: DataNodeInstance[] = graph.nodeInstances.map(nodeInstance => {
        const nodeBounds = computeNodeBounds(nodeInstance);
        const containingSubGraphNodeBounds = subGraphNodeBoundsSortedBySize.find(
            ({ nodeInstance: subGraphNode, bounds: subGraphNodeBounds }) =>
                nodeInstance.id !== subGraphNode.id && isBoundsInsideOfBounds(nodeBounds, subGraphNodeBounds)
        );
        return {
            ...nodeInstance,
            subGraphId: containingSubGraphNodeBounds?.nodeInstance.id
        };
    });

    const subGraphNodeIdDepthMap: Map<number, number> =
        new Map(subGraphNodeBoundsSortedBySize.map((boundNodePair, index, array) => [boundNodePair.nodeInstance.id, (array.length - index) + 1]));
    const depthSortedNodeInstances = updatedNodeInstances.sort(
        (a, b) =>
            ((a.subGraphId && subGraphNodeIdDepthMap.get(a.subGraphId)) ?? 0) -
            ((b.subGraphId && subGraphNodeIdDepthMap.get(b.subGraphId)) ?? 0)
    );

    const updatedNodeIDDictionary: { [key: number]: DataNodeInstance } = dictionaryFromStringTuple(updatedNodeInstances, node => [node.id, node]);

    const updatedConnections: DataConnection[] = graph.connections.filter(connection => {
        const sendingNode = updatedNodeIDDictionary[connection.sendingNodeInstanceId];
        const receivingNode = updatedNodeIDDictionary[connection.receivingNodeInstanceId];
        return sendingNode.subGraphId === undefined ||
            sendingNode.subGraphId === receivingNode.subGraphId ||
            (connection.receivingPortType === 'internal' && sendingNode.subGraphId === receivingNode.id) ||
            isSendingNodeWithinReceivingNodesSubGraphUpTheChain(sendingNode, receivingNode, updatedNodeIDDictionary)
    });

    return {
        nodeInstances: depthSortedNodeInstances,
        connections: updatedConnections,
        variables: graph.variables
    };
}

export const graphReducer = (state: DataGlueGraph|undefined, action: GraphReducerActions): DataGlueGraph|undefined => {
    if (state === undefined) {
        if(action.type === SET_GRAPH) {
            return action.graph
        } else {
            return undefined;
        }
    }
    switch (action.type) {
        case SET_GRAPH:
            return action.graph;
        case ADD_NODE: {
            const newId = (getHighestIdFromObjectsWithIds(state.nodeInstances) || 0 ) + 1;

            const { position, nodeTemplateId } = action;

            const nodeTemplate = NODE_LIBRARY_DICTIONARY[nodeTemplateId];

            const newNode: DataNodeInstance = {
                id: newId,
                nodeId: nodeTemplate.id,
                inPorts: nodeTemplate.inPorts,
                outPorts: nodeTemplate.outPorts,
                selected: false,
                subGraphId: action.subGraphId,
                portValues: dictionaryFromStringTuple(nodeTemplate.inPorts, inPort => [inPort.name, getDefaultValueForDataType(inPort.type)]),
                portUserDefinedTypes: {},
                ...(nodeTemplate.hasSubGraph ? {
                    hasSubGraph: true,
                    internalOutPorts: nodeTemplate.internalOutPorts ?? [],
                    internalInPorts: nodeTemplate.internalInPorts ?? [],
                    width: DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM,
                    height: DRAW_CONFIG.SUB_GRAPH_NODE_HEIGHT_MINIMUM
                }: {
                    hasSubGraph: false
                }),
                state: {},
                position: position
            };
            newNode.position = subtractVectors(newNode.position,
                nodeTemplate.hasSubGraph ? {
                    x: DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM / 2,
                    y: DRAW_CONFIG.SUB_GRAPH_NODE_HEIGHT_MINIMUM / 2
                } : {
                    x: 200,
                    y: 100
                })

            return updateSubGraphRelationships({
                connections: state.connections,
                nodeInstances: [...state.nodeInstances, newNode],
                variables: state.variables
            });
        }

        case MERGE_IN_GRAPH: {
            const highestNodeInstanceIdInGraph = (getHighestIdFromObjectsWithIds(state.nodeInstances) || 0 );
            const highestConnectionIdInGraph = (getHighestIdFromObjectsWithIds(state.connections) || 0 );

            const { nodes, connections, deselectOld } = action;

            const oldIdToNewIdMap: { [key: number]: number } = Object.fromEntries(
                nodes.map((node, index) => [node.id, highestNodeInstanceIdInGraph + 1 + index])
            );

            const newNodesWithUpdatedIds: DataNodeInstance[] = nodes.map(node => ({
                ...node,
                id: oldIdToNewIdMap[node.id]
            }));

            const newConnectionsWithUpdatedIds: DataConnection[] = connections.map((connection, index) => ({
                ...connection,
                id: highestConnectionIdInGraph + 1 + index,
                sendingNodeInstanceId: oldIdToNewIdMap[connection.sendingNodeInstanceId],
                receivingNodeInstanceId: oldIdToNewIdMap[connection.receivingNodeInstanceId]
            }));

            let oldGraphNodes = state.nodeInstances;
            if (deselectOld) {
                oldGraphNodes = state.nodeInstances.map(node => ({ ...node, selected: false }));
            }

            return updateSubGraphRelationships({
                connections: [...state.connections, ...newConnectionsWithUpdatedIds],
                nodeInstances: [...oldGraphNodes, ...newNodesWithUpdatedIds],
                variables: state.variables
            });
        }

        case SET_NODE_STATE: {
            return {
                connections: state.connections,
                nodeInstances: state.nodeInstances.map(nodeInstance => {
                    if (nodeInstance.id === action.id) {
                        return {
                            ...nodeInstance,
                            state: action.state
                        };
                    } else {
                        return nodeInstance;
                    }
                }),
                variables: state.variables
            }
        }

        case SET_NODE_SHAPE: {
            return {
                connections: state.connections,
                nodeInstances: state.nodeInstances.map(nodeInstance => {
                    if (nodeInstance.id === action.id) {
                        return {
                            ...nodeInstance,
                            inPorts: action.shape.inPorts,
                            outPorts: action.shape.outPorts
                        };
                    } else {
                        return nodeInstance;
                    }
                }),
                variables: state.variables
            }
        }

        case MOVE_NODES: {
            const { delta } = action;

            // Filter out any moves of nodes inside a subGraph that is included in the move
            const nodesDictionary = dictionaryFromStringTuple(state.nodeInstances, node => [node.id, node]);
            const selectedNodes = dictionaryFromNumberTuple(state.nodeInstances.filter(node => node.selected), node => [node.id, node]);
            const nodesIncludedInMove = new Set(state.nodeInstances.filter(
                nodeInstance =>
                    selectedNodes[nodeInstance.id] ||
                    isNodeWithinListOfSubGraphNodes(nodeInstance, selectedNodes, nodesDictionary)
            ).map(nodeInstance => nodeInstance.id));

            return {
                connections: state.connections,
                nodeInstances: state.nodeInstances.map(nodeInstance => {
                    if (nodesIncludedInMove.has(nodeInstance.id)) {
                        return {
                            ...nodeInstance,
                            position: addVectors(nodeInstance.position, delta)
                        };
                    } else {
                        return nodeInstance;
                    }
                }),
                variables: state.variables
            }
        }

        case MOVE_NODES_END: {
            // TODO Fix a bug here where the graph breaks if a node connected to a sub graph node externally gets moved into that subgraph node
            return updateSubGraphRelationships(state);
        }

        case SET_PORT_VALUE_AND_TYPE: {
            const { nodeInstanceId, port, value, userDefinedType } = action;
            const index: number = state.nodeInstances.findIndex(nodeInstance => nodeInstance.id === nodeInstanceId);
            const nodeInstance = state.nodeInstances[index];
            if (index !== -1 && nodeInstance !== undefined) {
                const updatedNodeInstance: DataNodeInstance = {
                    ...nodeInstance,
                    portValues: {
                        ...nodeInstance.portValues,
                        [port.name]: value
                    }
                }
                if (userDefinedType) {
                    switch (userDefinedType.kind) {
                        case 'union': {
                            updatedNodeInstance.portUserDefinedTypes = {
                                ...updatedNodeInstance.portUserDefinedTypes,
                                [port.name]: userDefinedType
                            }
                        }
                    }
                }
                return {
                    nodeInstances: [
                        ...state.nodeInstances.slice(0, index),
                        updatedNodeInstance,
                        ...state.nodeInstances.slice(index + 1)
                    ],
                    connections: state.connections,
                    variables: state.variables
                };
            }
            return state;
        }

        case REFRAME_SUB_GRAPH_NODE: {
            const { nodeId, position, width, height } = action;

            const { nodeInstances } = state;
            const index = nodeInstances.findIndex(nodeInstance => nodeInstance.id === nodeId);
            const reframedNode = nodeInstances[index];
            if (index !== -1 && reframedNode.hasSubGraph) {
                const newNode: SubGraphDataNodeInstance = {
                    ...reframedNode,
                    position: position,
                    width: width,
                    height: height
                };

                return {
                    connections: state.connections,
                    nodeInstances: [
                        ...nodeInstances.slice(0, index),
                        newNode,
                        ...nodeInstances.slice(index + 1)
                    ],
                    variables: state.variables
                };
            }
            return state;
        }

        case REFRAME_SUB_GRAPH_NODE_END: {
            return updateSubGraphRelationships(state);
        }

        case REMOVE_NODES: {
            const ids: number[] = action.ids;
            if (ids.length > 0) {
                return updateSubGraphRelationships({
                    nodeInstances: state.nodeInstances.filter(nodeInstance => !ids.includes(nodeInstance.id)),
                    connections: state.connections.filter(
                        (connection: DataConnection) =>
                            !ids.includes(connection.receivingNodeInstanceId) && !ids.includes(connection.sendingNodeInstanceId)
                    ),
                    variables: state.variables
                });
            }
            return state;
        }


        case ADD_CONNECTION: {
            const newConnection: DataConnection = {
                ...action.connection,
                id: getHighestIdFromObjectsWithIds(state.connections) + 1
            };
            const filteredConnections = state.connections.filter(
                connection =>
                    !(
                        connection.receivingNodeInstanceId === newConnection.receivingNodeInstanceId &&
                        connection.receivingPortName === newConnection.receivingPortName
                    )
            );

            const updatedGraph = updateSubGraphRelationships({
                nodeInstances: state.nodeInstances,
                connections: [...filteredConnections, newConnection],
                variables: state.variables
            });

            if (isGraphCyclic(updatedGraph)) {
                return state;
            }
            return updatedGraph;
        }

        case REMOVE_CONNECTION: {
            const { index } = action;
            const connections = state.connections;

            const removedConnection = connections[index];

            if (removedConnection) {
                return {
                    nodeInstances: state.nodeInstances,
                    connections: [...connections.slice(0, index), ...connections.slice(index + 1)],
                    variables: state.variables
                };
            }
            return state;
        }

        /////////////////////////////////////////////
        ///////////////// SELECTION /////////////////
        /////////////////////////////////////////////
        case SELECT_NODES: {
            const ids: number[] = action.ids;

            const newNodes: DataNodeInstance[] = state.nodeInstances.map(node => (ids.includes(node.id) && !node.selected) ? { ...node, selected: true} : node);

            if (ids.length > 0) {
                return {
                    nodeInstances: newNodes,
                    connections: state.connections,
                    variables: state.variables
                };
            }

            return state;
        }

        case SELECT_BRANCH: {
            const acceleratedConnections = createAcceleratedConnectionStructure(state.connections);
            const nodesDictionary = dictionaryFromNumberTuple(state.nodeInstances, node => [node.id, node]);
            const branchRootNode = nodesDictionary[action.id];
            const nodeIdsInBranch = new Set(getNodeIdsInBranch(branchRootNode, nodesDictionary, acceleratedConnections));

            return {
                nodeInstances: state.nodeInstances.map(
                    nodeInstance => nodeIdsInBranch.has(nodeInstance.id)
                        ? { ...nodeInstance, selected: true }
                        : {...nodeInstance, selected: false }
                ),
                connections: state.connections,
                variables: state.variables
            };
        }

        case SELECT_BRANCH_ADD: {
            const acceleratedConnections = createAcceleratedConnectionStructure(state.connections);
            const nodesDictionary = dictionaryFromNumberTuple(state.nodeInstances, node => [node.id, node]);
            const branchRootNode = nodesDictionary[action.id];
            const nodeIdsInBranch = new Set(getNodeIdsInBranch(branchRootNode, nodesDictionary, acceleratedConnections));

            return {
                nodeInstances: state.nodeInstances.map(
                    nodeInstance => nodeIdsInBranch.has(nodeInstance.id)
                        ? { ...nodeInstance, selected: true }
                        : nodeInstance
                ),
                connections: state.connections,
                variables: state.variables
            };
        }


        case DESELECT_NODES: {
            const ids: number[] = action.ids;

            const newNodes: DataNodeInstance[] = state.nodeInstances.map(node => (ids.includes(node.id) && node.selected) ? { ...node, selected: false} : node);

            if (ids.length > 0) {
                return {
                    nodeInstances: newNodes,
                    connections: state.connections,
                    variables: state.variables
                };
            }
            return state;
        }
        case SELECT_ONLY_ONE_NODE: {
            const id: number = action.id;
            return {
                nodeInstances: state.nodeInstances.map(node => {
                    if (id === node.id) {
                        return { ...node, selected: true}
                    } else {
                        if (node.selected) {
                            return { ...node, selected: false };
                        } else {
                            return node
                        }
                    }
                }),
                connections: state.connections,
                variables: state.variables
            };
        }
        case DESELECT_ALL_NODES: {
            const anySelectedNodes = state.nodeInstances.some(node => node.selected);
            if (anySelectedNodes) {
                return {
                    nodeInstances: state.nodeInstances.map(node => (node.selected ? { ...node, selected: false } : node)),
                    connections: state.connections,
                    variables: state.variables
                }
            } else {
                return state;
            }

        }

        /////////////////////////////////////////////
        ///////////////// VARIABLES /////////////////
        /////////////////////////////////////////////
        case ADD_VARIABLE: {
            const {newVariable } = action;
            const doesVariableAlreadyExist = state.variables.find(variable => variable.name === newVariable.name);
            if (doesVariableAlreadyExist) {
                return state;
            }

            return {
                nodeInstances: state.nodeInstances,
                connections: state.connections,
                variables: [
                    ...state.variables,
                    newVariable
                ]
            };
        }
        case UPDATE_VARIABLE: {
            const { oldName, variable } = action;
            const index = state.variables.findIndex(v => v.name === oldName);
            if (index === -1) {
                return state;
            }

            return {
                nodeInstances: state.nodeInstances.map(nodeInstance =>
                    nodeInstance.selectedVariable === oldName ? { ...nodeInstance, selectedVariable: variable.name } : nodeInstance
                ),
                connections: state.connections,
                variables: [
                    ...state.variables.slice(0, index),
                    { ...variable },
                    ...state.variables.slice(index + 1)
                ]
            };
        }
        case DELETE_VARIABLE: {
            const { name } = action;
            const index = state.variables.findIndex(variable => variable.name === name);
            if (index === -1) {
                return state;
            }

            return {
                nodeInstances: state.nodeInstances,
                connections: state.connections,
                variables: [
                    ...state.variables.slice(0, index),
                    ...state.variables.slice(index + 1)
                ]
            };
        }

        case SET_NODE_SELECTED_INTEGRATION_ACCESS: {
            return {
                connections: state.connections,
                nodeInstances: state.nodeInstances.map(nodeInstance => {
                    if (nodeInstance.id === action.id) {
                        return {
                            ...nodeInstance,
                            selectedIntegrationAccess: action.integrationAccess
                        };
                    } else {
                        return nodeInstance;
                    }
                }),
                variables: state.variables
            };
        }

        case SET_SELECTED_VARIABLE: {
            const { name: variableName, nodeInstanceId } = action;
            const index = state.nodeInstances.findIndex(nodeInstance => nodeInstance.id === nodeInstanceId);
            if (index === -1) {
                return state;
            }
            if (variableName) {
                const variable = state.variables.find(variable => variable.name === variableName);
                if (!variable) {
                    return state;
                }
            }

            return {
                nodeInstances: [
                    ...state.nodeInstances.slice(0, index),
                    { ...state.nodeInstances[index], selectedVariable: variableName },
                    ...state.nodeInstances.slice(index + 1)
                ],
                connections: state.connections,
                variables: state.variables
            };
        }

        case SET_NODE_DIMENSIONS: {
            const { nodeId, dimensions } = action;
            const index = state.nodeInstances.findIndex(nodeInstance => nodeInstance.id === nodeId);
            return {
                nodeInstances: [
                    ...state.nodeInstances.slice(0, index),
                    { ...state.nodeInstances[index], width: dimensions.x, height: dimensions.y },
                    ...state.nodeInstances.slice(index + 1)
                ],
                connections: state.connections,
                variables: state.variables
            }
        }

        default:
            return state;
    }
};
