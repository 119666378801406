import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { DRAW_CONFIG } from './drawConfig.constant';

import {
    MouseEvent,
    PointerEvent,
    KeyboardEvent,
    useRef,
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { GraphConnection } from './graphComponents/GraphConnection';
import { GraphBackgroundPattern } from './BaseComponents/GraphBackgroundPattern';
import { interpolateVectors, Vector } from '../helpers/Vector';
import { ShowNodeLibraryButton } from './navigationLayer/ShowNodeLibraryButton';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';
import { useNodeDrag } from './useNodeDrag';
import { useConnectionDrag } from './useConnectionDrag';
import { useViewDrag } from './useViewDrag';
import { useSubGraphReframe } from './useSubGraphReframe';
import { useGraphFraming } from './useGraphFraming';
import { useNodeLibrary } from './useNodeLibrary';
import { useZooming } from './useZooming';
import { CentralGraphOverlayMessage, NavigationLayer } from './navigationLayer/NavigationLayer';
import { ThemeType } from '@glueapp/component-library';
import {
    DataConnection,
    DataGlueGraph,
    DataNodeInstance, NodeInstanceDataPort,
    NodeInstanceState
} from '@glueapp/graphexecution/lib/types/Graph';
import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { useNodeLibraryContext } from '../NodeLibraryContextProvider';
import { ExecutionResult, ExecutionError } from '@glueapp/graphexecution/lib/executeGraph/executeGraph';
import { ValidationResult } from '@glueapp/graphexecution/lib/validation/validateGraph';
import { dictionaryFromStringTuple } from '../helpers/dictionaryFromTuple';
import { NodeRunRecordDictionary } from '../useGraphExecution';
import { useCopyAndPaste } from './useCopyAndPaste';
import { useRectangleSelection } from './useRectangleSelection';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { GraphNode } from './graphComponents/GraphNode';
import { useIntegrationAccessApi } from '../../api/useIntegrationAccessApi';
import { SubGraphNode } from './graphComponents/SubGraphNode';
import { useGraphUpdateDispatch } from '../GraphUpdateContext';
import { setNodeDimensions, setNodeShape, setNodeState } from '../actions';
import { GraphMessage } from './BaseComponents/GraphMessage';
import { getColorFromDataType } from '../dataLayer/conversions/getColorFromDataType';
import { getStringRepresentationFromType } from '@glueapp/graphexecution/lib/types/DataType';

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    overflow: scroll;
    
    ::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.backgroundColor};
      border: 1px solid ${props => props.theme.subtleAccentColor};
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-thumb:active {
      background: ${props => props.theme.subtleAccentColor};
    }
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.backgroundColor};
      border: 0 none #ffffff;
      border-radius: 53px;
    }
    ::-webkit-scrollbar-track:hover {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-track:active {
      background: ${props => props.theme.elevatedBackgroundColor};
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
`;

interface ScaleContainerProps {
    width: number;
    height: number;
}

interface PositioningContainerProps {
    width: number;
    height: number;
    scale: number;
}

export const ScaleContainer = styled.div<ScaleContainerProps>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    overflow: hidden;
    user-select: none;
`;


export const PositioningContainer = styled.div<PositioningContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    transform: scale(${props => props.scale});
    transform-origin: 0 0;
    user-select: none;
    pointer-events: none;
`;


const SVG = styled.svg`
    user-select: none;
    background-color: ${props => props.theme.backgroundColor};
`;


export type NodePortPositions = {
    [key: number]: {
        inPortPositions: { [key: string]: Vector }
        outPortPositions: { [key: string]: Vector }
    }
};

interface Props {
    // Data
    graph: DataGlueGraph;
    nodeTemplates: DataNode[];
    nodeDictionary: { [key: number]: DataNodeInstance };
    integrationAccesses: IntegrationAccess[];
    executionResult?: ExecutionResult;
    executionError?: ExecutionError;
    validationResult?: ValidationResult;
    ranNodes: NodeRunRecordDictionary;
    // Methods
    addConnection: (connection: Omit<DataConnection, 'id'>) => void;
    removeConnection: (index: number) => void;
    addNode: (nodeTemplate: DataNode, position: Vector, subGraphId?: number) => void;
    mergeInGraph: (nodes: DataNodeInstance[], connections: DataConnection[], deselectOld: boolean) => void;
    moveNodes: (delta: Vector) => void
    reframeNode: (nodeId: number, position: Vector, width: number, height: number) => void;
    reframeNodeEnd: () => void;
    moveNodesEnd: () => void;
    selectNodes: (nodeIds: number[]) => void;
    selectBranch: (nodeId: number) => void;
    selectBranchAdd: (nodeId: number) => void;
    deselectNodes: (nodeIds: number[]) => void;
    selectOnlyOneNode: (nodeId: number) => void;
    deselectAllNodes: () => void;
    removeNodes: (nodeId: number[]) => void;

    triggerManualRun: (nodeInstanceId: number) => void;

    undo: () => void;
    redo: () => void;
}


export const GraphEditorDrawing: React.FC<Props> = ({
    graph,
    nodeTemplates,
    nodeDictionary,
    integrationAccesses,
    executionResult,
    executionError,
    validationResult,
    ranNodes,
    addConnection,
    removeConnection,
    addNode,
    mergeInGraph,
    moveNodes,
    reframeNode,
    reframeNodeEnd,
    moveNodesEnd,
    selectNodes,
    selectBranch,
    selectBranchAdd,
    selectOnlyOneNode,
    deselectAllNodes,
    removeNodes,
    triggerManualRun,
    undo,
    redo
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const theme = useContext<ThemeType>(ThemeContext);
    const nodeLibraryFromContext = useNodeLibraryContext();
    const { callIntegration } = useIntegrationAccessApi();

    const dispatchGraphUpdate = useGraphUpdateDispatch();

    const latestMousePosition = useRef<Vector>({ x: 0, y: 0 });

    const connectionValidationDictionary = dictionaryFromStringTuple(validationResult?.connectionValidations ?? [], validation => [validation.id, validation]);

    const [activeSubGraphId, setActiveSubGraphId] = useState<number|undefined>(undefined);
    const handleSubGraphSelection = useCallback((nodeInstanceId: number) => {
        setActiveSubGraphId(nodeInstanceId);
    }, []);

    useEffect(() => {
        const containerElement = containerRef.current;
        if (containerElement) {
            containerElement.scrollTo(DRAW_CONFIG.BASE_SIZE / 2, DRAW_CONFIG.BASE_SIZE / 2);
        }
    }, []);

    const { zoomLevel, frameBoundsInView } = useZooming(latestMousePosition, containerRef);

    const [nodePortPositions, setNodePortPositions] = useState<NodePortPositions>({});

    const zoomLevelRef = useRef<number>(zoomLevel);
    zoomLevelRef.current = zoomLevel;

    const setInPortPosition = useCallback((nodeInstanceId: number, portName: string, position: Vector) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const containerRect = containerElement.getBoundingClientRect();
            setNodePortPositions(prevPositions => {
                const offsetPosition = {
                    x: (position.x - containerRect.x + containerElement.scrollLeft)/zoomLevelRef.current,
                    y: (position.y - containerRect.y + containerElement.scrollTop)/zoomLevelRef.current
                };
                const newPositions = {
                    ...prevPositions
                };
                if (newPositions[nodeInstanceId]) {
                    newPositions[nodeInstanceId].inPortPositions[portName] = offsetPosition;
                } else {
                    newPositions[nodeInstanceId] = {
                        inPortPositions: {
                            [portName]: offsetPosition
                        },
                        outPortPositions: {}
                    }
                }
                return newPositions;
            });
        }
    }, []);

    const setOutPortPosition = useCallback((nodeInstanceId: number, portName: string, position: Vector) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            setNodePortPositions(prevPositions => {
                const newPositions = {
                    ...prevPositions
                };
                const offsetPosition = {
                    x: (position.x + containerElement.scrollLeft)/zoomLevelRef.current,
                    y: (position.y + containerElement.scrollTop)/zoomLevelRef.current
                };

                if (newPositions[nodeInstanceId]) {
                    newPositions[nodeInstanceId].outPortPositions[portName] = offsetPosition;
                } else {
                    newPositions[nodeInstanceId] = {
                        outPortPositions: {
                            [portName]: offsetPosition
                        },
                        inPortPositions: {}
                    }
                }
                return newPositions;
            });
        }
    }, []);

    const {
        viewDrag,
        setViewDrag,
        handleViewDragStart,
        handleViewDragMove,
        handleViewDragEnd
    } = useViewDrag(containerRef, svgRef);

    const {
        nodeDrag,
        setNodeDrag,
        startNodeDrag,
        updateNodeDrag
    } = useNodeDrag(moveNodes, containerRef, zoomLevel);


    const {
        isConnectionDragActive,
        setConnectionDrag,
        handleConnectionDragMove,
        handleOutPortMouseDown,
        handleInPortMouseUp,
        handleInPortMouseDown,
        handleConnectionMouseDown,
        drawConnectionDrag
    } = useConnectionDrag(graph, nodePortPositions, addConnection, removeConnection, containerRef, zoomLevel, setViewDrag);


    const {
        isReframeActive,
        onReframeStart,
        onReframeUpdate,
        onReframeEnd
    } = useSubGraphReframe(reframeNode, reframeNodeEnd, containerRef, zoomLevel);

    const {
        reframe
    } = useGraphFraming(graph, frameBoundsInView);

    const {
        nodeLibrary,
        showNodeLibrary,
        toggleNodeLibrary,
        hideNodeLibrary,
        renderNodeLibrary
    } = useNodeLibrary(addNode, nodeTemplates, containerRef, zoomLevel, activeSubGraphId, integrationAccesses, callIntegration);

    useCopyAndPaste(containerRef, zoomLevel, graph, mergeInGraph);

    const {
        handleSelectionStart,
        handleSelectionMove,
        handleRectangleSelectionEnd,
        rectangleSelectionActive,
        drawRectangleSelection
    } = useRectangleSelection(graph, containerRef, zoomLevel, selectNodes);

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>|KeyboardEvent<SVGElement>) => {
        if (event.ctrlKey || event.metaKey) {
            switch (event.key) {
                case 'z': {
                    undo();
                    break;
                }
                case 'y': {
                    redo();
                    break;
                }
            }
        }
        switch (event.key) {
            case 'Tab': {
                event.preventDefault();
                if(isConnectionDragActive || nodeDrag || viewDrag) {
                    return;
                }
                showNodeLibrary(latestMousePosition.current);
                break;
            }
            case 'Backspace':
            case 'Delete': {
                event.preventDefault();

                removeNodes(graph.nodeInstances.filter(node => node.selected).map(node => node.id));
                break;
            }
            case 'f': {
                reframe();
                break;
            }
            case 'a': {
                selectNodes(graph.nodeInstances.map(node => node.id));
                break;
            }
            case 'Escape': {
                event.preventDefault();
                deselectAllNodes();
                hideNodeLibrary();
                break;
            }
        }
    };

    const [hoveredConnectionId, setHoveredConnectionId] = useState<number|undefined>(undefined);
    const handleConnectionHover = useCallback((connectionId: number, hovered: boolean) => {
        if (hovered) {
            setHoveredConnectionId(connectionId);
        } else {
            setHoveredConnectionId(undefined);
        }
    }, []);

    // This code has a lot of duplication with the code inside the GraphConnection
    // Once the Connections are lifted out into HTML this can be re-integrated into the GraphConnection
    const drawConnectionHoverMessage = () => {
        if (hoveredConnectionId) {
            const connection = graph.connections.find(connection => connection.id === hoveredConnectionId);
            if (connection) {
                const sendingNodeInstance = nodeDictionary[connection.sendingNodeInstanceId];
                if (sendingNodeInstance) {
                    const startPosition = nodePortPositions[connection.sendingNodeInstanceId]?.outPortPositions[connection.sendingPortName];
                    const endPosition = nodePortPositions[connection.receivingNodeInstanceId]?.inPortPositions[connection.receivingPortName];
                    const validation = connectionValidationDictionary[hoveredConnectionId];
                    const messagePosition = interpolateVectors(startPosition, endPosition, 0.5);
                    let sendingPort: NodeInstanceDataPort|undefined;

                    switch (connection.sendingPortType) {
                        case 'internal':
                            if (!sendingNodeInstance.hasSubGraph)
                                return null;
                            sendingPort = sendingNodeInstance.internalOutPorts.find(port => port.name === connection.sendingPortName);
                            if (!sendingPort)
                                return null;
                            break;
                        case 'external': {
                            sendingPort = sendingNodeInstance.outPorts.find(port => port.name === connection.sendingPortName);
                            if (!sendingPort)
                                return null;
                            break;
                        }
                    }
                    if (validation && !validation.valid && validation.errorMessage) {
                        return (
                            <GraphMessage
                                position={messagePosition}
                                color={'white'}
                                backgroundColor={theme.errorColor}
                            >
                                {validation.errorMessage}
                            </GraphMessage>
                        );
                    } else if(sendingPort?.type) {
                        const isTriggerConnection = sendingPort.type.kind === 'trigger';
                        const color = (!validation || validation.valid) ? getColorFromDataType(sendingPort?.type) : 'red';
                        return (
                            <GraphMessage
                                position={messagePosition}
                                color={isTriggerConnection ? theme.primaryContrastColor: 'white'}
                                backgroundColor={isTriggerConnection ? theme.backgroundColor: color}
                            >
                                {getStringRepresentationFromType(sendingPort.type)}
                            </GraphMessage>
                        );
                    }
                }
            }
        }
        return null;
    }

    const drawConnection = (connection: DataConnection) => {
        const startPosition = nodePortPositions[connection.sendingNodeInstanceId]?.outPortPositions[connection.sendingPortName];
        const endPosition = nodePortPositions[connection.receivingNodeInstanceId]?.inPortPositions[connection.receivingPortName];

        if (startPosition && endPosition) {
            return (
                <GraphConnection
                    key={connection.receivingPortName+connection.receivingNodeInstanceId+connection.sendingNodeInstanceId}
                    startPosition={startPosition}
                    endPosition={endPosition}
                    connection={connection}
                    sendingNodeInstance={nodeDictionary[connection.sendingNodeInstanceId]}
                    receivingNodeInstance={nodeDictionary[connection.receivingNodeInstanceId]}
                    onMouseDown={handleConnectionMouseDown}
                    validation={connectionValidationDictionary[connection.id]}
                    onHoverChange={handleConnectionHover}
                />
            );
        }
        return null;
    };

    const [dragCheck, setDragCheck] = useState<number|null>(null);
    const handleGraphMouseDown = (event: PointerEvent<SVGElement>|PointerEvent<HTMLDivElement>) => {
        switch (event.button) {
            case 2: {
                if (nodeLibrary.browsing) {
                    hideNodeLibrary();
                } else {
                    showNodeLibrary(latestMousePosition.current);
                }
                break;
            }
            default: {
                if(event.shiftKey) {
                    handleSelectionStart(event);
                } else {
                    handleViewDragStart(event);
                    setDragCheck(0);
                }
            }
        }
    }
    // Relay methods
    const handleGraphMouseMove = (event: MouseEvent<SVGElement>|MouseEvent<HTMLDivElement>) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            latestMousePosition.current = getInElementPositionFromEvent(event, containerElement);
        }
        if (nodeDrag) {
            updateNodeDrag(event);
        } else if (isConnectionDragActive) {
            handleConnectionDragMove(event);
        } else if (viewDrag) {
            handleViewDragMove(event);
        } else if (isReframeActive) {
            onReframeUpdate(event);
        }

        if (rectangleSelectionActive) {
            handleSelectionMove(event);
        }

        if(dragCheck !== null) {
            setDragCheck(dragCheck+1);
        }
    };

    const handleGraphMouseUp = (event: PointerEvent) => {
        event.preventDefault();
        if (nodeDrag) {
            moveNodesEnd();
        }

        if (rectangleSelectionActive) {
            handleRectangleSelectionEnd();
        }

        setNodeDrag(null);
        setConnectionDrag(null);
        handleViewDragEnd(event);
        if (isReframeActive) {
            onReframeEnd();
        }
    };

    const handleGraphClick = (e: any) => {
        if (dragCheck !== null && dragCheck < 4) {
            deselectAllNodes();
        }
        setDragCheck(null);

        if (nodeLibrary.browsing) {
            hideNodeLibrary();
        }
    };

    const handleNodeClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        return false;
    }, []);

    const handleNodeMouseDown = useCallback((event: PointerEvent, eventNode: DataNodeInstance) => {
        event.stopPropagation();
        const svgElement = svgRef.current;
        if (svgElement) {
            svgElement.setPointerCapture(event.pointerId);
        }
        switch (event.button) {
            // Main (left) mouse button
            case 0: {
                if (event.shiftKey) {
                    if (!eventNode.selected) {
                        selectNodes([eventNode.id]);
                    }
                } else if(!eventNode.selected) {
                    selectOnlyOneNode(eventNode.id);
                }
                break;
            }

            // Auxiliary (middle) mouse button
            case 1: {
                if (event.shiftKey) {
                    selectBranchAdd(eventNode.id);
                } else {
                    selectBranch(eventNode.id);
                }
                break;
            }
        }

        startNodeDrag(event);
    }, [startNodeDrag, selectNodes, selectBranch, selectBranchAdd, selectOnlyOneNode]);

    const dispatchSetNodeDimensions = useCallback(
        (nodeId: number, dimensions: Vector) => dispatchGraphUpdate(setNodeDimensions(nodeId, dimensions)
    ), [dispatchGraphUpdate])

    const dispatchSetNodeState = useCallback(
        (id: number, state: NodeInstanceState) => dispatchGraphUpdate(setNodeState(id, state)
    ), [dispatchGraphUpdate])

    const dispatchSetNodeShape = useCallback(
        (id: number, shape: { inPorts: NodeInstanceDataPort[], outPorts: NodeInstanceDataPort[]}) => dispatchGraphUpdate(setNodeShape(id, shape))
    , [dispatchGraphUpdate])

    const drawNode = (nodeInstance: DataNodeInstance) => {
        let subGraphNodeName = undefined;
        if (nodeInstance.subGraphId) {
            const subGraphNodeInstance = nodeDictionary[nodeInstance.subGraphId];
            if (subGraphNodeInstance) {
                subGraphNodeName = nodeLibraryFromContext[subGraphNodeInstance.nodeId]?.name;
            }
        }
        const nodeError = (executionError?.nodeInstanceId === nodeInstance.id) ? executionError : undefined;
        if (nodeInstance.hasSubGraph) {
            return (
                <SubGraphNode
                    key={nodeInstance.id}
                    nodeInstance={nodeInstance}
                    // selectedWidthMultiplier={Math.max(1 / zoomLevel, 1)}
                    selectedWidthMultiplier={1}
                    onClick={handleNodeClick}
                    theme={theme}
                    outputValues={executionResult?.[nodeInstance.id]}
                    handleMouseDown={handleNodeMouseDown}
                    onOutPortMouseDown={handleOutPortMouseDown}

                    subGraphNodeName={subGraphNodeName}

                    onInternalOutPortMouseDown={handleOutPortMouseDown}
                    onInternalInPortMouseUp={handleInPortMouseUp}
                    onInternalInPortMouseDown={handleInPortMouseDown}

                    onInPortMouseUp={handleInPortMouseUp}
                    onInPortMouseDown={handleInPortMouseDown}

                    onReframeMouseDown={onReframeStart}
                    onReframeMouseUp={onReframeEnd}

                    integrationAccesses={integrationAccesses}
                    callIntegration={callIntegration}
                    setInPortPosition={setInPortPosition}
                    setOutPortPosition={setOutPortPosition}
                    setNodeDimensions={dispatchSetNodeDimensions}
                    setNodeState={dispatchSetNodeState}
                    executionError={nodeError}
                />
            );
        } else {
            return (
                <GraphNode
                    key={nodeInstance.id}
                    // selectedWidthMultiplier={Math.max(1 / zoomLevel, 1)}
                    selectedWidthMultiplier={1}
                    onClick={handleNodeClick}
                    nodeInstance={nodeInstance}
                    subGraphNodeName={subGraphNodeName}
                    theme={theme}
                    outputValues={executionResult?.[nodeInstance.id]}
                    handleMouseDown={handleNodeMouseDown}
                    onOutPortMouseDown={handleOutPortMouseDown}
                    onInPortMouseUp={handleInPortMouseUp}
                    onInPortMouseDown={handleInPortMouseDown}
                    onEnterSubGraphClicked={handleSubGraphSelection}
                    runTimes={ranNodes[nodeInstance.id]}
                    integrationAccesses={integrationAccesses}
                    callIntegration={callIntegration}
                    setInPortPosition={setInPortPosition}
                    setOutPortPosition={setOutPortPosition}
                    setNodeDimensions={dispatchSetNodeDimensions}
                    setNodeState={dispatchSetNodeState}
                    triggerManualRun={triggerManualRun}
                    setNodeShape={dispatchSetNodeShape}
                    executionError={nodeError}
                />
            );
        }
    }
    return (
        <>
            <Container
                ref={containerRef}
                tabIndex={0}

                onPointerDown={handleGraphMouseDown}
                onPointerMove={handleGraphMouseMove}
                onPointerUp={handleGraphMouseUp}
                onKeyDown={handleKeyDown}
            >
                <ScaleContainer width={DRAW_CONFIG.BASE_SIZE * zoomLevel} height={DRAW_CONFIG.BASE_SIZE * zoomLevel}>
                    <SVG
                        ref={svgRef}
                        viewBox={`0 0 ${DRAW_CONFIG.BASE_SIZE} ${DRAW_CONFIG.BASE_SIZE}`}
                        onClick={handleGraphClick}
                    >
                        <GraphBackgroundPattern />
                        {drawConnectionDrag()}
                        {graph.connections.map(drawConnection)}
                        {drawRectangleSelection()}
                    </SVG>
                    <PositioningContainer width={DRAW_CONFIG.BASE_SIZE} height={DRAW_CONFIG.BASE_SIZE} scale={zoomLevel}>
                        {graph.nodeInstances.map(drawNode)}
                        {drawConnectionHoverMessage()}
                    </PositioningContainer>
                </ScaleContainer>
            </Container>
            {renderNodeLibrary()}
            <NavigationLayer>
                {
                    (graph.nodeInstances.length === 0 && !nodeLibrary.browsing)
                        ? <CentralGraphOverlayMessage>No Blocks in the Workflow yet. Right click or press TAB to open the Block Library</CentralGraphOverlayMessage>
                        : null
                }
                <ShowNodeLibraryButton
                    onNodeLibraryButtonClick={() => toggleNodeLibrary({ x: 500, y: 100 })}
                />
            </NavigationLayer>
        </>
    );
}
