import React from 'react';

interface Props {
    color?: string;
    size?: number;
}

export const KeyboardShortcutIcon: React.FC<Props> = ({ size = 44, color = 'black', ...props}) => (
    <svg width={size} height={size} viewBox="0 0 44 44" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 5a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V8a3 3 0 00-3-3H6zm12 2H6v12h12V7zM26 5a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V8a3 3 0 00-3-3H26zm12 2H26v12h12V7zM12 24a3 3 0 00-3 3v12a3 3 0 003 3h20a3 3 0 003-3V27a3 3 0 00-3-3H12zm19 2H12v12h19V26z"
            fill={color}
            fillOpacity={0.7}
        />
        <path d="M13.367 13.848h-2.9l1.447-3.99 1.453 3.99z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 5a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V8a3 3 0 00-3-3H7zm2.324 12l.803-2.227h3.574L14.516 17h1.154l-3.264-8.531h-.984L8.164 17h1.16z"
            fill={color}
        />
        <path
            d="M15.115 35.08v-6.685h1.313c.836.007 1.48.28 1.933.82.457.535.686 1.287.686 2.256v.498c0 .996-.242 1.763-.727 2.303-.48.538-1.154.808-2.021.808h-1.184zM23.09 30.914c.297-.32.664-.48 1.101-.48.454 0 .813.152 1.079.457.265.3.414.726.445 1.277v.082h-3.176c.07-.574.254-1.02.55-1.336z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 24a3 3 0 00-3 3v10a3 3 0 003 3h18a3 3 0 003-3V27a3 3 0 00-3-3H13zm.99 3.469V36h2.362c.765-.008 1.435-.174 2.01-.498a3.254 3.254 0 001.33-1.395c.312-.605.468-1.31.468-2.115v-.545c-.004-.785-.162-1.48-.474-2.086a3.31 3.31 0 00-1.319-1.4c-.57-.328-1.226-.492-1.969-.492H13.99zm8.239 7.804c.539.563 1.238.844 2.097.844 1.063 0 1.86-.408 2.39-1.224l-.661-.516c-.2.258-.432.465-.698.621-.265.156-.59.234-.972.234-.54 0-.985-.191-1.336-.574-.348-.386-.53-.892-.545-1.517h4.295v-.451c0-1.012-.229-1.79-.686-2.333-.457-.543-1.097-.814-1.922-.814-.503 0-.972.14-1.406.422-.43.277-.765.664-1.008 1.16-.238.492-.357 1.053-.357 1.682v.199c0 .945.27 1.701.808 2.267zm5.923.727h1.084v-9h-1.084v9zM27 5a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V8a3 3 0 00-3-3H27zm4.115 8.232h3.58v-.92h-3.58V9.396h4.16v-.926H29.99V17h1.125v-3.768z"
            fill={color}
        />
    </svg>
);
