import * as React from 'react';
import { Vector } from '../../helpers/Vector';

interface Props {
    startPosition: Vector;
    endPosition: Vector;
    handleClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onMouseDown?: (event: React.MouseEvent) => void;
    strokeColor?: string;
    strokeWidth?: number;
}

export const SCurve = ({
    startPosition,
    endPosition,
    handleClick,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    strokeColor = 'black',
    strokeWidth = 3
}: Props) => {
    const xDistance = endPosition.x - startPosition.x;
    const bezierOffset = xDistance * 0.6;

    return (
        <path
            onClick={handleClick}
            d={`M${startPosition.x} ${startPosition.y}
                C${startPosition.x + bezierOffset} ${startPosition.y}
                ${endPosition.x - bezierOffset} ${endPosition.y}
                ${endPosition.x} ${endPosition.y}`}
            stroke={strokeColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
        />
    );
};
