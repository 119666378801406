import styled  from 'styled-components';
import { RainbowBackgroundAnimation } from './GlueRainbowBackgroundAnimation';




export const ActionButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: ${props => props.theme.textColor};
    font-weight: bold;
    background: ${props => props.theme.backgroundColor};
    border-radius: 8px;
    border: 2px solid ${props => props.theme.primaryContrastColor};
    min-width: 120px;
    padding: 8px;
    outline: none;
    transition: background-color .2s ease;
    
    &:disabled {
        background: ${props => props.theme.subtleAccentColor};
        cursor: not-allowed;
    }
    
    &:hover {
        ${RainbowBackgroundAnimation}
           
        &:disabled {
            background: ${props => props.theme.subtleContrastColor};
        }
    }
`;
