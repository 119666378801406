import { Vector } from './helpers/Vector';
import {
    DataConnection,
    DataGlueGraph,
    DataNodeInstance,
    GlueVariable,
    NodeInstanceDataPort, NodeInstanceState,
    UserDefinedType
} from '@glueapp/graphexecution/lib/types/Graph';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';

export const SET_GRAPH = 'SET_GRAPH';
export const ADD_NODE = 'ADD_NODE';
export const MERGE_IN_GRAPH = 'MERGE_IN_GRAPH';
export const MOVE_NODES = 'MOVE_NODES';
export const SET_NODE_STATE = 'SET_NODE_STATE';
export const SET_NODE_SHAPE = 'SET_NODE_SHAPE';
export const MOVE_NODES_END = 'MOVE_NODES_END';
export const SET_NODE_SELECTED_INTEGRATION_ACCESS = 'SET_NODE_SELECTED_INTEGRATION_ACCESS';
export const REMOVE_NODES = 'REMOVE_NODES';
export const SET_PORT_VALUE_AND_TYPE = 'SET_PORT_VALUE_AND_TYPE';

export const REFRAME_SUB_GRAPH_NODE = 'REFRAME_SUB_GRAPH_NODE';
export const REFRAME_SUB_GRAPH_NODE_END = 'REFRAME_SUB_GRAPH_NODE_END';
export const SET_NODE_DIMENSIONS = 'SET_NODE_DIMENSIONS';

export const ADD_CONNECTION = 'ADD_CONNECTION';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';

export const SELECT_NODES = 'SELECT_NODES';
export const SELECT_BRANCH = 'SELECT_BRANCH';
export const SELECT_BRANCH_ADD = 'SELECT_BRANCH_ADD';
export const DESELECT_NODES = 'DESELECT_NODES';
export const SELECT_ONLY_ONE_NODE = 'SELECT_ONLY_ONE_NODE';
export const DESELECT_ALL_NODES = 'DESELECT_ALL_NODES';

export const ADD_VARIABLE = 'ADD_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';

export const SET_SELECTED_VARIABLE = 'SET_SELECTED_VARIABLE';


export const setGraph = (graph: DataGlueGraph):
    { type: 'SET_GRAPH', graph: DataGlueGraph } => ({
    type: SET_GRAPH,
    graph
});


export const setNodeState = (id: number, state: NodeInstanceState):
    { type: 'SET_NODE_STATE', id: number, state: NodeInstanceState } => ({
    type: SET_NODE_STATE,
    id,
    state
});

export const setNodeShape = (id: number, shape: { inPorts: NodeInstanceDataPort[], outPorts: NodeInstanceDataPort[]}):
    { type: 'SET_NODE_SHAPE', id: number, shape:  { inPorts: NodeInstanceDataPort[], outPorts: NodeInstanceDataPort[]} } => ({
    type: SET_NODE_SHAPE,
    id,
    shape
});

export const moveNodes = (delta: Vector):
    { type: 'MOVE_NODES', delta: Vector } => ({
    type: MOVE_NODES,
    delta
});

export const setNodeSelectedIntegrationAccess = (id: number, integrationAccess: IntegrationAccess):
    { type: 'SET_NODE_SELECTED_INTEGRATION_ACCESS', id: number, integrationAccess: IntegrationAccess } => ({
    type: SET_NODE_SELECTED_INTEGRATION_ACCESS,
    id,
    integrationAccess
});

export const moveNodesEnd = ():
    { type: 'MOVE_NODES_END' } => ({
    type: MOVE_NODES_END
});

export const addConnection = (
    connection: Omit<DataConnection, 'id'>
): { type: "ADD_CONNECTION", connection: Omit<DataConnection, 'id'> } => ({
    type: ADD_CONNECTION,
    connection
});

export const removeConnection = (index: number):
    { type: "REMOVE_CONNECTION", index: number } => ({
    type: REMOVE_CONNECTION,
    index
});

export const addNode = (nodeTemplateId: string, position: Vector, subGraphId?: number):
    { type: "ADD_NODE", nodeTemplateId: string, position: Vector, subGraphId?: number } => ({
    type: ADD_NODE,
    nodeTemplateId,
    subGraphId,
    position
});


export const mergeInGraph = (nodes: DataNodeInstance[], connections: DataConnection[], deselectOld: boolean):
    { type: "MERGE_IN_GRAPH", nodes: DataNodeInstance[], connections: DataConnection[], deselectOld: boolean } => ({
    type: MERGE_IN_GRAPH,
    nodes,
    connections,
    deselectOld
});

export const removeNode = (ids: number[]):
{ type: "REMOVE_NODES", ids: number[] } => ({
    type: REMOVE_NODES,
    ids
});

export const setPortValueAndType = (nodeInstanceId: number, port: NodeInstanceDataPort, value: DataValue, userDefinedType?: UserDefinedType):
{ type: "SET_PORT_VALUE_AND_TYPE", nodeInstanceId: number, port: NodeInstanceDataPort, value: DataValue, userDefinedType?: UserDefinedType } => ({
    type: SET_PORT_VALUE_AND_TYPE,
    nodeInstanceId,
    port,
    value,
    userDefinedType
});

export const reframeSubGraphNode = (
    nodeId: number,
    position: Vector,
    width: number,
    height: number
): {
    type: "REFRAME_SUB_GRAPH_NODE",
    nodeId: number,
    position: Vector,
    width: number,
    height: number
} => ({
    type: REFRAME_SUB_GRAPH_NODE,
    nodeId,
    position,
    width,
    height
});

export const reframeSubGraphNodeEnd = (): {
    type: "REFRAME_SUB_GRAPH_NODE_END"
} => ({
    type: REFRAME_SUB_GRAPH_NODE_END
});

export const setNodeDimensions = (nodeId: number, dimensions: Vector): {
    type: "SET_NODE_DIMENSIONS",
    nodeId: number,
    dimensions: Vector
} => ({
    type: SET_NODE_DIMENSIONS,
    nodeId,
    dimensions
});

export const selectBranch = (id: number):
    { type: "SELECT_BRANCH", id: number }  => ({
    type: SELECT_BRANCH,
    id
});

export const selectBranchAdd = (id: number):
    { type: "SELECT_BRANCH_ADD", id: number }  => ({
    type: SELECT_BRANCH_ADD,
    id
});

export const selectNodes = (ids: number[]):
    { type: "SELECT_NODES", ids: number[] }  => ({
    type: SELECT_NODES,
    ids
});


export const deselectNodes = (ids: number[]):
    { type: "DESELECT_NODES", ids: number[] }  => ({
    type: DESELECT_NODES,
    ids
});

export const selectOnlyOneNode = (id: number): { type: "SELECT_ONLY_ONE_NODE", id: number }  => ({
    type: SELECT_ONLY_ONE_NODE,
    id
});

export const deselectAllNodes = (): { type: "DESELECT_ALL_NODES" } => ({
    type: DESELECT_ALL_NODES
});

export const addVariable = (newVariable: GlueVariable): {
    type: "ADD_VARIABLE", newVariable: GlueVariable
} => ({
    type: ADD_VARIABLE,
    newVariable
});

export const updateVariable = (oldName: string, variable: GlueVariable): {
    type: "UPDATE_VARIABLE", oldName: string, variable: GlueVariable
} => ({
    type: UPDATE_VARIABLE,
    oldName,
    variable
});
export const deleteVariable = (name: string): { type: "DELETE_VARIABLE", name: string } => ({
    type: DELETE_VARIABLE,
    name
});

export const setSelectedVariable = (name: string|undefined, nodeInstanceId: number): { type: "SET_SELECTED_VARIABLE", name: string|undefined, nodeInstanceId: number } => ({
    type: SET_SELECTED_VARIABLE,
    name,
    nodeInstanceId
});

export type GraphReducerActions =
    ReturnType<typeof setGraph> |
    ReturnType<typeof setNodeState> |
    ReturnType<typeof setNodeShape> |
    ReturnType<typeof moveNodes> |
    ReturnType<typeof moveNodesEnd> |
    ReturnType<typeof setNodeSelectedIntegrationAccess> |
    ReturnType<typeof addConnection> |
    ReturnType<typeof removeConnection> |
    ReturnType<typeof reframeSubGraphNode> |
    ReturnType<typeof reframeSubGraphNodeEnd> |
    ReturnType<typeof setNodeDimensions> |
    ReturnType<typeof addNode> |
    ReturnType<typeof mergeInGraph> |
    ReturnType<typeof removeNode> |
    ReturnType<typeof setPortValueAndType> |
    ReturnType<typeof selectNodes> |
    ReturnType<typeof selectBranch> |
    ReturnType<typeof selectBranchAdd> |
    ReturnType<typeof deselectNodes> |
    ReturnType<typeof selectOnlyOneNode> |
    ReturnType<typeof deselectAllNodes>|
    ReturnType<typeof addVariable> |
    ReturnType<typeof updateVariable> |
    ReturnType<typeof deleteVariable> |
    ReturnType<typeof setSelectedVariable>;

export const actionsIgnoredForUndoRedo: Set<string> = new Set([SET_GRAPH, MOVE_NODES, SET_NODE_STATE, SET_NODE_SHAPE, REFRAME_SUB_GRAPH_NODE, SET_NODE_DIMENSIONS]);