import { DataNodeInstance, SubGraphDataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';

export function isNodeWithinListOfSubGraphNodes(
    node: DataNodeInstance,
    relevantSubGraphNodes: { [key: number]: DataNodeInstance },
    allNodes: { [key: number]: DataNodeInstance }
) {
    let currentNode: DataNodeInstance = node;
    while (currentNode.subGraphId !== undefined) {
        if (relevantSubGraphNodes[currentNode.subGraphId] !== undefined) {
            return true;
        }
        currentNode = allNodes[currentNode.subGraphId]
    }
    return false;
}


export function isSubGraphNode(DataNodeInstance: DataNodeInstance): DataNodeInstance is SubGraphDataNodeInstance {
    return DataNodeInstance.hasSubGraph;
}