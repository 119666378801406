import * as React from 'react';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    color?: string;
    className?: string;
}

export const TriggerArrowIcon = React.forwardRef<HTMLDivElement, Props>((
    { color = 'none', className , ...restProps},
    ref
) => {
    return (
        <div ref={ref} className={className ?? ''} {...restProps}>
            <svg style={{ display: 'block' }} width={12} viewBox="0 0 120 142">
                <path
                    d="M28 0L70 56H0V86H70L28 142H65.5L118.75 71L65.5 0H28Z"
                    fill={color}
                />
            </svg>
        </div>
    );
});
