// Creates a Map of Receiving NodeInstanceId -> Map of ReceivingPortName -> Connection
import { DataConnection } from '@glueapp/graphexecution/lib/types/Graph';

export function createAcceleratedConnectionStructure(connections: DataConnection[]): { [key: number]: { [key: string]: DataConnection } } {
    const acceleratedConnections: { [key: number]: { [key: string]: DataConnection } } = {};
    for (const connection of connections) {
        if (acceleratedConnections[connection.receivingNodeInstanceId] === undefined) {
            acceleratedConnections[connection.receivingNodeInstanceId] = {};
        }
        acceleratedConnections[connection.receivingNodeInstanceId][connection.receivingPortName] = connection;
    }
    return acceleratedConnections;
}