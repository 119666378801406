import * as React from 'react';
import styled, { keyframes }  from 'styled-components';
import { MouseEvent, useLayoutEffect, useRef, useState } from 'react';
import { ThemeType } from '@glueapp/component-library';
import {
    ConnectionPortType,
    DataNodeInstance,
    NodeInstanceDataPort
} from '@glueapp/graphexecution/lib/types/Graph';
import { getColorFromDataType } from '../../dataLayer/conversions/getColorFromDataType';
import { Vector } from '../../helpers/Vector';
import { TriggerArrowIcon } from '../BaseComponents/TriggerArrowIconHTML';
import { getStringRepresentationFromType } from '@glueapp/graphexecution/lib/types/DataType';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { OutputValue } from './OutputValue';

const Port = styled.li`
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
`;

const InPortTypeAnchor = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
`;

const OutPortTypeAnchor = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
`;

const FadeIn = keyframes`
    from {opacity: 0;}
    to { opacity: 1 }
`;


const PortTypeDisplay = styled.div`
    position: absolute;
    top: 0;
    background-color: ${p => p.theme.backgroundColor};
    color: ${p => p.theme.primaryContrastColor};
    white-space: nowrap;
    padding: 4px;
    pointer-events: none;
    transform: translateY(-50%);
    border-radius: 8px;
    opacity: 0;
    border: 2px solid ${props => props.theme.separatorColor};
    animation: ${FadeIn} 0.2s ease forwards;
    animation-delay: 0.3s;
`;

const InPortTypeDisplay = styled(PortTypeDisplay)`
    right: 16px;
`

const OutPortTypeDisplay = styled(PortTypeDisplay)`
    left: 16px;
`


interface PortCircleProps {
    color: string;
}

const InPortCircle = styled.div<PortCircleProps>`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    text-align: left;
    background-color: ${props => props.color};
    border: 1px solid #3a3d42;
    &:hover {
        transform: translateY(-50%) scale(1.25);
    }
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
`;

const OutPortCircle = styled.div<PortCircleProps>`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${props => props.color};
    border: 1px solid #3a3d42;
    &:hover {
        transform: translateY(-50%) scale(1.25);
    }
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
`;

const InPortTriggerArrow = styled(TriggerArrowIcon)`
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
        transform: translateY(-50%) scale(1.25);
    }
`;

const OutPortTriggerArrow = styled(TriggerArrowIcon)`
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translateY(-50%);
  
    &:hover {
        transform: translateY(-50%) scale(1.25);
    }
`;

export interface InPortEventHandlers {
    onInPortMouseUp?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
    onInPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
}

export interface InPortProps extends InPortEventHandlers {
    port: NodeInstanceDataPort;
    nodeInstance: DataNodeInstance;
    theme: ThemeType;
    setPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    portType?: ConnectionPortType;
}

export const InPort: React.FC<InPortProps> = ({
    port,
    nodeInstance,
    theme,
    onInPortMouseDown,
    onInPortMouseUp,
    setPortPosition,
    portType = 'external'
}) => {
    const iconElementRef = useRef<HTMLDivElement|null>(null);
    const setPortPositionRef = useRef(setPortPosition);
    const [hovered, setHovered] = useState<boolean>(false);

    useLayoutEffect(() => {
        const element = iconElementRef.current;
        if(element) {
            const boundingRect = element.getBoundingClientRect();
            setPortPositionRef.current(nodeInstance.id, port.name, { x: boundingRect.x + boundingRect.width/2, y: boundingRect.y + boundingRect.height/2 });
        }
    });

    let icon;
    switch (port.type?.kind) {
        case 'trigger':
            icon = (
                <InPortTriggerArrow
                    ref={iconElementRef}
                    color={theme.primaryContrastColor}
                    aria-label={`Input ${port.name} of ${nodeInstance.nodeId}`}
                    onPointerDown={(e: MouseEvent) => {e.stopPropagation(); onInPortMouseDown && onInPortMouseDown(e, nodeInstance, port.name, portType);}}
                    onPointerUp={(e: MouseEvent) => {e.stopPropagation(); onInPortMouseUp && onInPortMouseUp(e, nodeInstance, port.name, portType);}}
                />
            );
            break;
        default: {
            icon = <InPortCircle
                ref={iconElementRef}
                color={getColorFromDataType(port.type)}
                aria-label={`Input ${port.name} of ${nodeInstance.nodeId}`}
                onPointerDown={(e: MouseEvent) => {e.stopPropagation(); onInPortMouseDown && onInPortMouseDown(e, nodeInstance, port.name, portType);}}
                onPointerUp={(e: MouseEvent) => {e.stopPropagation(); onInPortMouseUp && onInPortMouseUp(e, nodeInstance, port.name, portType);}}
            />;
        }
    }

    return (
        <Port
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {port.name}
            {icon}
            <InPortTypeAnchor>
                {hovered ? (
                    <InPortTypeDisplay>
                        {port.type ? getStringRepresentationFromType(port.type): 'unknown'}
                    </InPortTypeDisplay>
                ): null}
            </InPortTypeAnchor>
        </Port>
    );
};


export interface OutPortEventHandlers {
    onOutPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
}

export interface OutPortProps extends OutPortEventHandlers {
    port: NodeInstanceDataPort;
    nodeInstance: DataNodeInstance;
    outputValue?: DataValue;
    theme: ThemeType;
    setPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    portType?: ConnectionPortType;
}

export const OutPort: React.FC<OutPortProps> = ({
    port,
    nodeInstance,
    theme,
    outputValue,
    onOutPortMouseDown,
    setPortPosition,
    portType = 'external'
}) => {
    const iconElementRef = useRef<HTMLDivElement|null>(null);
    const setPortPositionRef = useRef(setPortPosition);
    const [hovered, setHovered] = useState<boolean>(false);

    useLayoutEffect(() => {
        const element = iconElementRef.current;
        if(element) {
            const boundingRect = element.getBoundingClientRect();
            setPortPositionRef.current(nodeInstance.id, port.name, { x: boundingRect.x + boundingRect.width/2, y: boundingRect.y + boundingRect.height/2 - 54 });
        }
    });

    let icon;
    switch (port.type?.kind) {
        case 'trigger':
            icon = (
                <OutPortTriggerArrow
                    ref={iconElementRef} color={theme.primaryContrastColor}
                    aria-label={`Output ${port.name} of ${nodeInstance.nodeId}`}
                    onPointerDown={event => {
                        event.stopPropagation();
                        onOutPortMouseDown && onOutPortMouseDown(event, nodeInstance, port.name, portType);
                    }}
                />
            );
            break;
        default: {
            icon = (
                <OutPortCircle
                    ref={iconElementRef}
                    color={getColorFromDataType(port.type)}
                    aria-label={`Output ${port.name} of ${nodeInstance.nodeId}`}
                    onPointerDown={event => {
                        event.stopPropagation();
                        onOutPortMouseDown && onOutPortMouseDown(event, nodeInstance, port.name, portType);
                    }}
                />
            );
        }
    }
    return (
        <Port
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {icon}
            {port.name}
            <OutPortTypeAnchor>
                {hovered && outputValue === undefined ? (
                    <OutPortTypeDisplay>
                        {port.type ? getStringRepresentationFromType(port.type): 'unknown'}
                    </OutPortTypeDisplay>
                ): null}
                {outputValue !== undefined ? (
                    <OutputValue outPort={port} result={outputValue} />
                ) : null}
            </OutPortTypeAnchor>
        </Port>
    );
};
