import * as React from 'react';
import { InputField, ValueEditorContainer } from '@glueapp/component-library';
import { NumberDataType } from '@glueapp/graphexecution/lib/types/DataType';
import { UserDefinedType } from '@glueapp/graphexecution/lib/types/Graph';

interface NumberValueEditorProps {
    type: NumberDataType;
    value: number;
    userDefinedType?: UserDefinedType;
    onChange: (value: number, updatedType?: UserDefinedType) => void;
}

export const NumberValueEditor: React.FC<NumberValueEditorProps> = ({ type, value, onChange }) => {
    return (
        <ValueEditorContainer>
            <InputField type='number' value={value} onChange={e => onChange(parseFloat(e.target.value))} />
        </ValueEditorContainer>
    );
};