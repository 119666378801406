import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { DataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';
import React, { useContext, useMemo } from 'react';
import { dictionaryFromStringTuple } from './helpers/dictionaryFromTuple';

export type NodeLibraryContextType = { [key: string]: DataNode };

const NodeLibraryContext = React.createContext<NodeLibraryContextType>({});

export const NodeLibraryContextProvider: React.FC<{ nodeLibrary: DataNode[]}> = ({ nodeLibrary, children }) => {
    const nodeLibraryIDDictionary: NodeLibraryContextType = useMemo(
        () => {
            return dictionaryFromStringTuple(nodeLibrary, node => [node.id, node]);
        },
        [nodeLibrary]
    );

    return (
        <NodeLibraryContext.Provider value={nodeLibraryIDDictionary}>
            {children}
        </NodeLibraryContext.Provider>
    );
};


export const useNodeFromNodeInstance = (nodeInstance: DataNodeInstance): DataNode => {
    const nodeLibraryIDDictionary = useContext(NodeLibraryContext);
    const node = nodeLibraryIDDictionary[nodeInstance.nodeId];
    if (node === undefined) {
        console.log(nodeInstance);
        throw new Error (`Unable to find node with ID: ${nodeInstance.nodeId} in node library`);
    }
    return node;
};

export const useNodeLibraryContext = (): NodeLibraryContextType => {
    return useContext(NodeLibraryContext);
};