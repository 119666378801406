import { useCallback, useEffect, useRef } from 'react';
import { Bounds, computeNodeBounds, mergeBoundsList } from './helpers/bounds';
import { DataGlueGraph } from '@glueapp/graphexecution/lib/types/Graph';

export function useGraphFraming(
    graph: DataGlueGraph,
    frameBoundsInView: (bounds: Bounds) => void
) {
    const frameWholeGraph = useCallback(() => {
        const nodeBounds = graph.nodeInstances.map(node => computeNodeBounds(node));
        if (nodeBounds.length > 0) {
            const boundsOfGraph = mergeBoundsList(nodeBounds as [Bounds, ...Bounds[]]);
            frameBoundsInView(boundsOfGraph);
        }
    }, [frameBoundsInView, graph]);


    const hasBeenRunRef = useRef<boolean>(false);
    useEffect(() => {
        if(!hasBeenRunRef.current) {
            frameWholeGraph();
            hasBeenRunRef.current = true;
        }
    }, [frameWholeGraph])


    const reframe = (): void => {
        const selectedNodeBounds = graph.nodeInstances.filter(node => node.selected).map(node => computeNodeBounds(node));
        if (selectedNodeBounds.length > 0) {
            const boundsOfGraph = mergeBoundsList(selectedNodeBounds as [Bounds, ...Bounds[]]);
            frameBoundsInView(boundsOfGraph);
        } else if(graph.nodeInstances.length > 0) {
            frameWholeGraph();
        }
    }

    return { reframe };
}