import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ActionButton } from '../../../base/ActionButton';
import { ThemeType } from '@glueapp/component-library';


interface Props {
    onNodeLibraryButtonClick: () => void;
}


const StyledButton = styled(ActionButton)`
    position: absolute;
    bottom: 24px;
    right: 24px;
    flex-direction: column;
    min-width: 80px;
    pointer-events: all;
`;

export const ShowNodeLibraryButton: React.FC<Props> = (props) => {
    const { primaryContrastColor } = useContext<ThemeType>(ThemeContext);
    return (
        <StyledButton onClick={props.onNodeLibraryButtonClick}>
            <svg width="28" height="28" viewBox="0 0 500 500" fill="none">
                <rect x="35" y="50" width="368" height="158" rx="50" stroke={primaryContrastColor}  strokeWidth="24"/>
                <circle cx="35" cy="129" r="48" fill={primaryContrastColor} />
                <circle cx="403" cy="129" r="48" fill={primaryContrastColor} />
                <rect x="66" y="171" width="368" height="158" rx="50" stroke={primaryContrastColor}  strokeWidth="24"/>
                <circle cx="66" cy="250" r="48" fill={primaryContrastColor} />
                <circle cx="434" cy="250" r="48" fill={primaryContrastColor} />
                <rect x="97" y="292" width="368" height="158" rx="50" stroke={primaryContrastColor}  strokeWidth="24"/>
                <circle cx="97" cy="371" r="48" fill={primaryContrastColor} />
                <circle cx="465" cy="371" r="48" fill={primaryContrastColor} />
            </svg>
            Blocks
        </StyledButton>
    );
};
