import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ActionButton } from './ActionButton';
import { ThemeType } from '@glueapp/component-library';

interface IconProps {
    size: number;
}

const ArrowIconSVG = styled.svg<IconProps>`
    width: ${props => props.size}px;
    transition: transform .2s ease;
    
    ${ActionButton}:hover &{
        transform: translateX(4px);
    }
`;

interface Props {
    size?: number;
}

export const ArrowIcon: React.FC<Props> = ({size = 16, ...props}) => {
    const theme = useContext<ThemeType>(ThemeContext);
    return (
        <ArrowIconSVG size={size} viewBox="0 0 10 10">
            <polyline
                points="3 1, 7 5, 3 9"
                fill="none"
                stroke={theme.primaryContrastColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </ArrowIconSVG>
    );
};
