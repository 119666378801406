import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
    GlueVariable
} from '@glueapp/graphexecution/lib/types/Graph';
import { InputField, InputValueEditorButton, SelectField, Title } from '@glueapp/component-library';
import { Button } from '../base/Button';
import { ThemeType } from '@glueapp/component-library';
import { VariableTypeInput } from './VariableTypeInput';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
`;

const VariableForm = styled.div`
    color: ${props => props.theme.textColor};
    background: ${props => props.theme.elevatedBackgroundColor};
    border: 1px solid ${props => props.theme.separatorColor};
    font-size: 16px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`;

const ButtonRow = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const VariableSelectField = styled(SelectField)`
    flex: 1;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const VariableSelectionRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const AddVariableSection = styled.section`
    margin-top: 16px;
`;

interface Props {
    variables: GlueVariable[];
    selectedVariable?: GlueVariable;
    setSelectedVariable: (name: string|undefined) => void;
    addVariable: (newVariable: GlueVariable) => void;
    updateVariable: (oldName: string, variable: GlueVariable) => void;
    deleteVariable: (name: string) => void;
}

export const VariableEditor: React.FC<Props> = ({
    variables,
    selectedVariable,
    setSelectedVariable,
    addVariable,
    updateVariable,
    deleteVariable
}) => {
    const theme = useContext<ThemeType>(ThemeContext);

    const [editVariableDraft, setEditVariableDraft] = useState<GlueVariable|undefined>(undefined);
    const [newVariableDraft, setNewVariableDraft] = useState<GlueVariable|undefined>(undefined);



    const renderCurrentVariableSection = () => {
        if (selectedVariable && editVariableDraft) {
            const handleSubmit = (e: FormEvent) => {
                e.preventDefault();
                if (
                    editVariableDraft.name.length < 1
                ) {
                    return;
                }

                updateVariable(selectedVariable.name, editVariableDraft);
                setEditVariableDraft(undefined);
            }

            const createButtonDisabled = editVariableDraft.name.length < 1;
            return (
                <VariableForm onSubmit={handleSubmit}>
                    <Title>Edit variable: {selectedVariable.name}</Title>
                    <InputField
                        placeholder={'Variable Name'}
                        value={editVariableDraft.name}
                        onChange={e => setEditVariableDraft({ ...editVariableDraft, name: e.target.value })}
                    />
                    <VariableTypeInput
                        dataType={editVariableDraft.type}
                        typeDetermination={editVariableDraft.typeDetermination}
                        onChange={(typeDetermination, dataType) =>
                            setEditVariableDraft({ ...editVariableDraft, typeDetermination, type: dataType })
                        }
                    />
                    <ButtonRow>
                        <Button
                            onClick={() => setEditVariableDraft(undefined)}
                            hoverColor={theme.negativeAccentColor}
                        >
                            Discard
                        </Button>
                        <Button
                            disabled={createButtonDisabled}
                            title={createButtonDisabled ? 'Make sure the variable name is filled' : ''}
                            onClick={handleSubmit}
                        >
                            Update
                        </Button>
                    </ButtonRow>
                </VariableForm>
            );
        } else {
            const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
                const newSelectedVariable = variables.find(variable => variable.name === event.target.value);
                if (newSelectedVariable) {
                    setSelectedVariable(newSelectedVariable.name);
                }
            }

            return (
                <VariableSelectionRow>
                    <VariableSelectField disabled={variables.length === 0} value={selectedVariable?.name} onChange={handleSelect}>
                        {selectedVariable === undefined ? (
                            <option selected disabled style={{ display: 'none' }}>
                                {variables.length === 0 ? 'No variables created yet' : 'Select a variable'}
                            </option>
                        ) : null}
                        {variables.map(variable => {
                            return (
                                <option value={variable.name} key={variable.name}>
                                    {variable.name}
                                </option>
                            );
                        })}
                    </VariableSelectField>
                    <InputValueEditorButton
                        disabled={!selectedVariable}
                        onClick={() => setEditVariableDraft(selectedVariable)}
                    >
                        Edit
                    </InputValueEditorButton>
                </VariableSelectionRow>
            );
        }
    }

    const renderCreateVariableForm = () => {
        if (newVariableDraft) {
            const handleSubmit = (e: FormEvent) => {
                e.preventDefault();
                if (
                    newVariableDraft.name.length < 1 ||
                    variables.find(variable => variable.name === newVariableDraft.name)
                ) {
                    return;
                }

                addVariable(newVariableDraft);
                setSelectedVariable(newVariableDraft.name);
                setNewVariableDraft(undefined);
            }

            const createButtonDisabled = newVariableDraft.name.length < 1;
            return (
                <VariableForm onSubmit={handleSubmit}>
                    <Title>New Variable</Title>
                    <InputField
                        placeholder={'Variable Name'}
                        value={newVariableDraft.name}
                        onChange={e => setNewVariableDraft({ ...newVariableDraft, name: e.target.value })}
                    />
                    <VariableTypeInput
                        dataType={newVariableDraft.type}
                        typeDetermination={newVariableDraft.typeDetermination}
                        onChange={(typeDetermination, dataType) =>
                            setNewVariableDraft({ ...newVariableDraft, typeDetermination, type: dataType })
                        }
                    />
                    <ButtonRow>
                        <Button
                            onClick={() => setNewVariableDraft(undefined)}
                            hoverColor={theme.negativeAccentColor}
                        >
                            Discard
                        </Button>
                        <Button
                            disabled={createButtonDisabled}
                            title={createButtonDisabled ? 'Make sure the variable name is filled' : ''}
                            onClick={handleSubmit}
                        >
                            Create
                        </Button>
                    </ButtonRow>
                </VariableForm>
            );
        } else {
            return (
                <Button
                    onClick={() => setNewVariableDraft({ name: '', type: undefined, typeDetermination: 'INFERRED'})}
                >
                    New Variable
                </Button>
            );
        }
    }

    return (
        <InputContainer>
            {newVariableDraft ? null : renderCurrentVariableSection()}
            <AddVariableSection>
                {renderCreateVariableForm()}
            </AddVariableSection>
        </InputContainer>
    );
};


