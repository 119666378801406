import { MutableRefObject, useState } from 'react';
import { Vector } from '../helpers/Vector';
import { screenToWorldCoordinate } from './helpers/coordinateSystemMapping';
import { NodeLibrary } from './NodeLibrary/NodeLibrary';
import * as React from 'react';
import styled from 'styled-components';
import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import {
    CallIntegrationRequest,
    CallIntegrationResponse
} from '@glueapp/graphexecution/lib/types/IntegrationExecutionAccess';


const LibraryLayer = styled.div`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LibraryWrapper = styled.div`
    border: 2px solid ${props => props.theme.separatorColor};
    border-radius: 10px;
    pointer-events: auto;
    box-shadow: 0 0 34px 0 rgba(0,0,0,0.21);
`;

export function useNodeLibrary(
    addNode: (nodeTemplate: DataNode, position: Vector,subGraphId?: number) => void,
    nodeTemplates: DataNode[],
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number,
    activeSubGraphId: number|undefined,
    integrationAccesses: IntegrationAccess[],
    callIntegration: (integrationAccess: IntegrationAccess, requestConfig: CallIntegrationRequest) => Promise<CallIntegrationResponse<any>>
) {

    const [nodeLibrary, setNodeLibrary] = useState<{ browsing: boolean}>({ browsing: false});
    const handleNodeLibraryNodeSelect = (nodeTemplate: DataNode) => {
        const containerElement = containerRef.current;
        if (containerElement) {
            addNode(
                nodeTemplate,
                screenToWorldCoordinate(
                    { x: containerElement.clientWidth / 2, y: containerElement.clientHeight / 2 },
                    {
                        x: containerElement.scrollLeft,
                        y: containerElement.scrollTop
                    },
                    zoomLevel
                ),
                activeSubGraphId
            );
            setNodeLibrary({ browsing: false });
        }
    };
    const renderNodeLibrary = () => {
        if (nodeLibrary.browsing) {
            return (
                <LibraryLayer onContextMenu={e => { e.preventDefault(); return false; }}>
                    <LibraryWrapper>
                        <NodeLibrary
                            onNodeSelect={selectedNodeTemplate => {
                                handleNodeLibraryNodeSelect(selectedNodeTemplate);
                                const containerElement = containerRef.current;
                                if (containerElement) {
                                    containerElement.focus();
                                }
                            }}
                            nodeTemplates={nodeTemplates}
                            integrationAccesses={integrationAccesses}
                            callIntegration={callIntegration}
                        />
                    </LibraryWrapper>
                </LibraryLayer>
            );
        }
        return null;
    };

    const showNodeLibrary = (position: Vector) => {
        setNodeLibrary({ browsing: true });
    }
    const hideNodeLibrary = () => {
        setNodeLibrary({ browsing: false });
    }

    const toggleNodeLibrary = (position: Vector) => {
        setNodeLibrary({ browsing: !nodeLibrary.browsing });
    }

    return {
        nodeLibrary,
        showNodeLibrary,
        toggleNodeLibrary,
        hideNodeLibrary,
        renderNodeLibrary
    }
}