import React, { ChangeEvent } from "react";
import { GlueIntegration, IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { FlexBox, InputValueEditorButton, SelectField } from '@glueapp/component-library';
import { IntegrationAuthorizer } from './IntegrationAuthorizer';
import { DataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';
import { useGraphUpdateDispatch } from '../GraphEditor/GraphUpdateContext';
import { setNodeSelectedIntegrationAccess } from '../GraphEditor/actions';

interface Props {
    nodeInstance: DataNodeInstance;
    integration: GlueIntegration<any, any, any>;
    integrationAccesses: IntegrationAccess[];
    deleteIntegrationAccess: (integrationAccess: IntegrationAccess) => void;
}

export const IntegrationEditor: React.FC<Props> = ({
    nodeInstance,
    integration,
    integrationAccesses,
    deleteIntegrationAccess
}) => {
    const dispatchGraphUpdate = useGraphUpdateDispatch();

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedAccess = integrationAccesses.find(ia => ia.id === event.target.value);
        if (selectedAccess) {
            dispatchGraphUpdate(setNodeSelectedIntegrationAccess(nodeInstance.id, selectedAccess));
        }
    };

    const renderIntegrationSelect = () => {
        if(integrationAccesses.length) {
            const selectedIntegrationAccess = nodeInstance.selectedIntegrationAccess ?? integrationAccesses[0];
            return (
                <FlexBox paddingBottom={1}>
                    <SelectField
                        disabled={selectedIntegrationAccess === undefined}
                        value={selectedIntegrationAccess.id}
                        onChange={handleSelect}
                    >
                        {integrationAccesses.map(ia => (
                            <option value={ia.id} key={ia.id}>
                                {integration.renderOptionLabel(ia.metaData)}
                            </option>
                        ))}
                    </SelectField>
                    <InputValueEditorButton
                        onClick={() => deleteIntegrationAccess(selectedIntegrationAccess)}
                    >
                        Remove
                    </InputValueEditorButton>
                </FlexBox>
            );
        }
    }

    return (
        <FlexBox flexDirection="column" paddingBottom={4}>
            {renderIntegrationSelect()}
            <IntegrationAuthorizer
                integration={integration}
                label={integrationAccesses.length === 0 ? 'Authorize' : 'Authorize another Account'}
            />
        </FlexBox>
    )
}
