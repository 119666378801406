import styled from 'styled-components';
import { Vector } from '../../helpers/Vector';

interface Props {
    position: Vector;
}
export const ExecutionErrorDisplay = styled.div<Props>`
    position: absolute;
    left: ${props => props.position.x}px;
    top: ${props => props.position.y}px;
    width: 100%;
    pointer-events: all;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: white;
    background-color: ${props => props.theme.negativeAccentColor};
    padding: 4px;
    border-radius: 4px;
`