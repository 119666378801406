import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { MouseEvent, PointerEvent } from 'react';
import { ThemeType } from '@glueapp/component-library';
import {
    ConnectionPortType,
    DataNodeInstance,
    NodeInstanceState, SubGraphDataNodeInstance
} from '@glueapp/graphexecution/lib/types/Graph';
import { useNodeFromNodeInstance } from '../../NodeLibraryContextProvider';
import { Vector } from '../../helpers/Vector';
import { NodeStateContextProvider } from '@glueapp/graphexecution/lib/types/NodeInstanceState';
import {
    CallIntegrationRequest,
    CallIntegrationResponse
} from '@glueapp/graphexecution/lib/types/IntegrationExecutionAccess';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { darken } from 'polished';
import { InPort, InPortEventHandlers, OutPort, OutPortEventHandlers } from './Ports';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { ExecutionError } from '@glueapp/graphexecution/lib';
import { ExecutionErrorDisplay } from './ExecutionErrorDisplay';

export type ReframeDirection = 'LEFT'|'BOTTOM_LEFT'|'BOTTOM'|'BOTTOM_RIGHT'|'RIGHT';


interface NodeContainerProps {
    position: Vector;
    width?: number;
    height?: number;
}

const NodeContainer = styled.div.attrs<NodeContainerProps>(props => ({
    style: {
        transform: `translate(${props.position.x+'px'}, ${props.position.y+'px'})`,
        width: props.width ? props.width+'px' : 'auto',
        height: props.height ? props.height+'px' : 'auto'
    }
}))<NodeContainerProps>`
    color: white;
    position: absolute;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
`;


interface SelectedBorderOverlayProps {
    borderWidth: number;
    borderColor: string;
}

const SelectedBorderOverlay = styled.div<SelectedBorderOverlayProps>`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    border: ${props => props.borderWidth}px solid ${props => props.borderColor};
`;


const SubGraphNodeNameLabel = styled.div`
    position: absolute;
    pointer-events: none;
    text-align: right;
    color: ${props => props.theme.subtleContrastColor};
    white-space: nowrap;
    right: 4px;
    top: -22px;
`;

const highlightFade = keyframes`
    0% {opacity: 0}
    10% {opacity: 1}
    100% {opacity: 0}
`;

const HighlightOverlay = styled.div`
    position: absolute;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 0;
    animation: ${highlightFade} 2s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

interface SectionProps {
    color: string;
}

const NodeSection = styled.div<SectionProps>`
    background-color: ${props => props.color};
    background: -webkit-radial-gradient(left top, ${props => props.color}, ${props => darken(0.1, props.color)});
    display: flex;
    flex-direction: column;
`
const SectionBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const VerticalSection = styled.div<{ alignment: 'flex-start'|'flex-end' }>`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.alignment};
`;

const NodeSubGraphSection = styled(NodeSection)<SectionProps>`
    opacity: 0.15;
    flex: 1;
    pointer-events: none;
`;

const LeftSection = styled(NodeSection)<SectionProps>`
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    pointer-events: all;
`;

const RightSection = styled(NodeSection)<SectionProps>`
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 36px;
    pointer-events: all;
`;


const NodeInterfaceEventInsulator = styled.div`
    user-select: auto;
    pointer-events: all;
`;

const NodeTitle = styled.h2`
    margin: 0;
    font-weight: 500;
    font-size: 1.2em;
    padding-top: 8px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 12px;
`;

const InPortList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
`;

const OutPortList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    margin-bottom: 8px;
`;

const InternalInPortList = styled(InPortList)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const InternalOutPortList = styled(OutPortList)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

const VerticalResizer = styled.div`
    cursor: ew-resize;
    flex: 1;
    position: relative;
`;

const VerticalResizerHandle = styled.div`
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
`

const LeftVerticalResizerHandle = styled(VerticalResizerHandle)`
    left: -15px;
`;

const RightVerticalResizerHandle = styled(VerticalResizerHandle)`
    right: -15px;
`;

const CornerResizer = styled.div`
    width: 40px;
    height: 40px;
    position: relative;
`;

const BottomLeftCornerResizerIcon = styled.svg`
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
`

const BottomRightCornerResizerIcon = styled.svg`
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 30px;
    height: 30px;
`

const BottomLeftCornerResizerHandle = styled.div`
    cursor: sw-resize;
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 55px;
    height: 55px;
`

const BottomRightCornerResizerHandle = styled.div`
    cursor: se-resize;
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 55px;
    height: 55px;
`

const BottomResizer = styled.div`
    position: absolute;
    bottom: -15px;
    left: 30px;
    height: 30px;
    width: calc(100% - 60px);
    cursor: ns-resize;
    pointer-events: all;
`;



interface Props extends InPortEventHandlers, OutPortEventHandlers {
    nodeInstance: SubGraphDataNodeInstance;
    handleMouseDown?: (event: PointerEvent, nodeInstance: DataNodeInstance) => void;
    handleMouseUp?: (event: PointerEvent, nodeInstance: DataNodeInstance) => void;
    subGraphNodeName?: string;
    onClick?: (event: MouseEvent<HTMLDivElement>, nodeInstance: DataNodeInstance) => void;
    onDoubleClick?: (event: MouseEvent<HTMLDivElement>, nodeInstance: DataNodeInstance) => void;
    theme: ThemeType;
    outputValues?: { [key: string]: DataValue};
    selectedWidthMultiplier?: number;
    onEnterSubGraphClicked?: (nodeInstanceId: number) => void;
    runTimes?: Set<number>;
    onOutPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    onInternalOutPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
    onInPortMouseUp?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    onInternalInPortMouseUp?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
    onInPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string) => void;
    onInternalInPortMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, portName: string, portType: ConnectionPortType) => void;
    onReframeMouseDown?: (event: MouseEvent, nodeInstance: DataNodeInstance, direction: ReframeDirection) => void;
    onReframeMouseUp?: (event: MouseEvent) => void;
    integrationAccesses: IntegrationAccess[];

    callIntegration(integrationAccess: IntegrationAccess, requestConfig: CallIntegrationRequest): Promise<CallIntegrationResponse<any>>;

    setInPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    setOutPortPosition: (nodeInstanceId: number, portName: string, position: Vector) => void;
    setNodeDimensions: (nodeId: number, dimensions: Vector) => void;
    setNodeState: (id: number, state: NodeInstanceState) => void;
    executionError?: ExecutionError;
}

const BaseSubGraphNode: React.FC<Props> = ({
    nodeInstance,
    handleMouseDown,
    handleMouseUp,
    subGraphNodeName,
    onClick,
    onDoubleClick,
    theme,
    outputValues,
    selectedWidthMultiplier = 1,
    onOutPortMouseDown,
    onInPortMouseUp,
    onInPortMouseDown,
    onInternalInPortMouseDown,
    onInternalInPortMouseUp,
    onInternalOutPortMouseDown,
    onReframeMouseDown,
    onReframeMouseUp,
    runTimes,
    integrationAccesses,
    callIntegration,
    setInPortPosition,
    setOutPortPosition,
    // Currently unused and a blocker for actually adding custom UI to the sub graph nodes
    setNodeDimensions,
    setNodeState,
    executionError
}) => {
    const node = useNodeFromNodeInstance(nodeInstance);
    const inPorts = nodeInstance.inPorts;
    const outPorts = nodeInstance.outPorts;

    const renderNodeInterfaceComponent = () => {
        const NodeInterfaceComponent = node.interfaceComponent;
        if (!NodeInterfaceComponent) {
            return null;
        }

        if (node.integration) {
            const integrationAccess = integrationAccesses.find(ia => ia.provider === node.integration?.provider);
            if (!integrationAccess) {
                return null;
            }
            return (
                <NodeInterfaceComponent
                    integration={{
                        callIntegration: (request: CallIntegrationRequest) => callIntegration(integrationAccess, request)
                    }}
                    nodeShape={{
                        nodeInPorts: node.inPorts,
                        nodeOutPorts: node.outPorts,
                        nodeInstanceInPorts: nodeInstance.inPorts,
                        nodeInstanceOutPorts: nodeInstance.outPorts
                    }}
                    renderSideBar={() => {}}
                    updateShape={() => {}}
                />
            );
        } else {
            return (
                <NodeInterfaceComponent
                    integration={undefined}
                    nodeShape={{
                        nodeInPorts: node.inPorts,
                        nodeOutPorts: node.outPorts,
                        nodeInstanceInPorts: nodeInstance.inPorts,
                        nodeInstanceOutPorts: nodeInstance.outPorts
                    }}
                    renderSideBar={() => {}}
                    updateShape={() => {}}
                />
            );
        }
    }


    return (
        <NodeContainer
            key={nodeInstance.id}
            position={nodeInstance.position}
            width={nodeInstance.width}
            height={nodeInstance.height}
        >
            <SelectedBorderOverlay
                borderWidth={nodeInstance.selected ? 3 * selectedWidthMultiplier : 1}
                borderColor={nodeInstance.selected ? theme.primaryContrastColor : '#3a3d42'}
            />
            {runTimes ? Array.from(runTimes).map(runTime => (
                <HighlightOverlay
                    key={runTime}
                />
            )) : null}
            {executionError ? (
                <ExecutionErrorDisplay position={{x: 0, y: (nodeInstance.height ?? 0) + 8 }}>
                    {executionError?.error}
                </ExecutionErrorDisplay>
            ) : null}
            <SubGraphNodeNameLabel>
                {nodeInstance.subGraphId !== undefined ? ` [${subGraphNodeName}]` : ''}
            </SubGraphNodeNameLabel>
            <LeftSection
                color={node.color}
                onClick={e => { e.preventDefault(); e.stopPropagation(); onClick && onClick(e, nodeInstance); }}
                onDoubleClick={e => onDoubleClick && onDoubleClick(e, nodeInstance)}
                onPointerDown={event => handleMouseDown && handleMouseDown(event, nodeInstance)}
                onPointerUp={event => handleMouseUp && handleMouseUp(event, nodeInstance)}
            >
                <NodeTitle>
                    {node.name}
                </NodeTitle>
                <SectionBody>
                    <VerticalSection alignment="flex-start">
                        <InPortList>
                            {inPorts.map((port, index) => (
                                <InPort
                                    key={index}
                                    port={port}
                                    nodeInstance={nodeInstance}
                                    theme={theme}
                                    onInPortMouseDown={onInPortMouseDown}
                                    onInPortMouseUp={onInPortMouseUp}
                                    setPortPosition={setInPortPosition}
                                />
                            ))}
                        </InPortList>
                        <VerticalResizer
                            onPointerDown={e => { e.stopPropagation(); onReframeMouseDown?.(e, nodeInstance, 'LEFT'); }}
                            onPointerUp={e => { e.stopPropagation(); onReframeMouseUp?.(e); }}
                        >
                            <LeftVerticalResizerHandle />
                        </VerticalResizer>
                        <CornerResizer
                            onPointerDown={e => { e.stopPropagation(); onReframeMouseDown?.(e, nodeInstance, 'BOTTOM_LEFT'); }}
                            onPointerUp={e => { e.stopPropagation(); onReframeMouseUp?.(e); }}
                        >
                            <BottomLeftCornerResizerIcon viewBox="0 0 20 20">
                                <polygon points="2,2 2,18 18,18" stroke="#F0F1f588" fill="none"/>
                            </BottomLeftCornerResizerIcon>
                            <BottomLeftCornerResizerHandle />
                        </CornerResizer>
                    </VerticalSection>
                    <NodeInterfaceEventInsulator
                        onClick={e => { e.stopPropagation(); }}
                        // onDoubleClick={e => { e.stopPropagation(); }}
                        onPointerDown={e => { e.stopPropagation(); }}
                        onPointerUp={e => { e.stopPropagation(); }}
                        onMouseDown={e => { e.stopPropagation(); }}
                        onMouseUp={e => { e.stopPropagation(); }}
                    >
                        <NodeStateContextProvider
                            nodeInstanceState={nodeInstance.state}
                            setNodeInstanceState={(state: NodeInstanceState) => setNodeState(nodeInstance.id, state)}
                        >
                            {renderNodeInterfaceComponent()}
                        </NodeStateContextProvider>
                    </NodeInterfaceEventInsulator>
                    <InternalOutPortList>
                        {nodeInstance.internalOutPorts.map((port, index) => (
                            <OutPort
                                key={index}
                                port={port}
                                nodeInstance={nodeInstance}
                                theme={theme}
                                onOutPortMouseDown={onInternalOutPortMouseDown}
                                setPortPosition={setOutPortPosition}
                                portType={'internal'}
                            />
                        ))}
                    </InternalOutPortList>
                </SectionBody>
            </LeftSection>
            <NodeSubGraphSection color={node.color} />
            <RightSection
                color={node.color}
                onClick={e => { e.preventDefault(); e.stopPropagation(); onClick && onClick(e, nodeInstance); }}
                onDoubleClick={e => onDoubleClick && onDoubleClick(e, nodeInstance)}
                onPointerDown={event => handleMouseDown && handleMouseDown(event, nodeInstance)}
                onPointerUp={event => handleMouseUp && handleMouseUp(event, nodeInstance)}
            >
                <SectionBody>
                    <InternalInPortList>
                        {nodeInstance.internalInPorts.map((port, index) => (
                            <InPort
                                key={index}
                                port={port}
                                nodeInstance={nodeInstance}
                                theme={theme}
                                onInPortMouseDown={onInternalInPortMouseDown}
                                onInPortMouseUp={onInternalInPortMouseUp}
                                setPortPosition={setInPortPosition}
                                portType={'internal'}
                            />
                        ))}
                    </InternalInPortList>
                    <VerticalSection alignment="flex-end">
                        <OutPortList>
                            {outPorts.map((port, index) => (
                                <OutPort
                                    key={index}
                                    port={port}
                                    nodeInstance={nodeInstance}
                                    theme={theme}
                                    outputValue={outputValues?.[port.name]}
                                    onOutPortMouseDown={onOutPortMouseDown}
                                    setPortPosition={setOutPortPosition}
                                />
                            ))}
                        </OutPortList>
                        <VerticalResizer
                            onPointerDown={e => { e.stopPropagation(); onReframeMouseDown?.(e, nodeInstance, 'RIGHT'); }}
                            onPointerUp={e => { e.stopPropagation(); onReframeMouseUp?.(e); }}
                        >
                            <RightVerticalResizerHandle />
                        </VerticalResizer>
                        <CornerResizer
                            onPointerDown={e => { e.stopPropagation(); onReframeMouseDown?.(e, nodeInstance, 'BOTTOM_RIGHT'); }}
                            onPointerUp={e => { e.stopPropagation(); onReframeMouseUp?.(e); }}
                        >
                            <BottomRightCornerResizerIcon viewBox="0 0 20 20">
                                <polygon points="2,18 18,18 18,2" stroke="#F0F1f588" fill="none"/>
                            </BottomRightCornerResizerIcon>
                            <BottomRightCornerResizerHandle />
                        </CornerResizer>
                    </VerticalSection>
                </SectionBody>
            </RightSection>
            <BottomResizer
                onPointerDown={e => { e.stopPropagation(); onReframeMouseDown?.(e, nodeInstance, 'BOTTOM'); }}
                onPointerUp={e => { e.stopPropagation(); onReframeMouseUp?.(e); }}
            />
        </NodeContainer>
    );
}

export const SubGraphNode = React.memo(BaseSubGraphNode);
