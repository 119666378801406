import React, { useCallback } from 'react';
import styled from 'styled-components';
import { PortValueEditor } from './PortValueEditor';
import {
    DataConnection,
    DataNodeInstance, GlueVariable,
    NodeInstanceDataPort,
    UserDefinedType
} from '@glueapp/graphexecution/lib/types/Graph';
import { DataNode } from '@glueapp/graphexecution/lib/types/Node';
import { DataValue } from '@glueapp/graphexecution/lib/types/DataValue';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { SetVariable } from '@glueapp/graphexecution/lib/nodeLibrary/Variables/SetVariable';
import { GetVariable } from '@glueapp/graphexecution/lib/nodeLibrary/Variables/GetVariable';
import { VariableEditor } from './VariableEditor';
import { IntegrationEditor } from './IntegrationEditor';
import { WebhookEditor } from './WebhookEditor';
import { Webhook } from '../api/useWebhookApi';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
`;


interface Props {
    node: DataNode;
    nodeInstance: DataNodeInstance;
    connections: DataConnection[];
    integrationAccesses: IntegrationAccess[];
    deleteIntegrationAccess: (integrationAccess: IntegrationAccess) => void;
    onValueChange: (nodeInstanceId: number, port: NodeInstanceDataPort, value: DataValue, userDefinedType?: UserDefinedType) => void;
    variables: GlueVariable[];
    selectedVariable?: GlueVariable;
    setSelectedVariable: (name: string|undefined, nodeInstanceId: number) => void;
    addVariable: (newVariable: GlueVariable) => void;
    updateVariable: (oldName: string, variable: GlueVariable) => void;
    deleteVariable: (name: string) => void;
    webhookCreatePending: boolean;
    webhook?: Webhook;
    createWebhook: (nodeInstanceId: number) => void;
}

interface NodeInstanceLabelProps {
    backgroundColor: string;
}

const NodeInstanceLabel = styled.div<NodeInstanceLabelProps>`
    background: ${props => props.backgroundColor};
    color: white;
    font-size: 24px;
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
`;

export const NodeInstanceEditor: React.FC<Props> = ({
    node,
    onValueChange,
    nodeInstance,
    connections,
    integrationAccesses,
    deleteIntegrationAccess,
    variables,
    setSelectedVariable,
    addVariable,
    updateVariable,
    deleteVariable,
    webhookCreatePending,
    webhook,
    createWebhook
}) => {
    const nodeInstanceId = nodeInstance.id;

    const handlePortChange = useCallback((inPort: NodeInstanceDataPort, value: DataValue, type?: UserDefinedType) => {
        onValueChange(nodeInstanceId, inPort, value, type);
    }, [onValueChange, nodeInstanceId]);

    const renderInPorts = () => nodeInstance.inPorts.map((inPort) => {
        const portHasConnection = connections.some(connection =>
            connection.receivingNodeInstanceId === nodeInstance.id && connection.receivingPortName === inPort.name
        );

        return (
            <PortValueEditor
                key={inPort.name}
                inPort={inPort}
                portValue={nodeInstance.portValues[inPort.name]}
                portUserDefinedType={nodeInstance.portUserDefinedTypes[inPort.name]}
                onValueChange={handlePortChange}
                hasConnection={portHasConnection}
            />
        );
    });

    const renderContent = () => {
        switch (node.id) {
            case SetVariable.id:
            case GetVariable.id: {
                return (
                    <VariableEditor
                        variables={variables}
                        selectedVariable={nodeInstance.selectedVariable ? variables.find(variable => variable.name === nodeInstance.selectedVariable) : undefined}
                        setSelectedVariable={(name: string|undefined) => setSelectedVariable(name, nodeInstanceId)}
                        addVariable={addVariable}
                        updateVariable={updateVariable}
                        deleteVariable={deleteVariable}
                    />
                )
            }
            default: {
                if (node.integration) {
                    return (
                        <>
                            <IntegrationEditor
                                nodeInstance={nodeInstance}
                                integration={node.integration}
                                integrationAccesses={integrationAccesses}
                                deleteIntegrationAccess={deleteIntegrationAccess}
                            />
                            {integrationAccesses.length ? renderInPorts() : null}
                        </>
                    )
                } else {
                    switch (node.executionType) {
                        case 'Trigger':
                            switch (node.triggerType) {
                                case 'Manual':
                                    return null;
                                case 'Timer':
                                    return null;
                                case 'Webhook':
                                    return (
                                        <WebhookEditor
                                            webhook={webhook}
                                            webhookCreatePending={webhookCreatePending}
                                            createWebhook={() => createWebhook(nodeInstanceId)}
                                        />
                                    );
                                case 'Integration':
                                    return null;
                            }
                        break;
                        case 'Effect':
                        case 'Pure': {
                            return renderInPorts();
                        }
                    }
                }
            }
        }
    };

    return (
        <Container>
            <NodeInstanceLabel backgroundColor={node.color}>{node.name}</NodeInstanceLabel>
            {renderContent()}
        </Container>
    );
};
