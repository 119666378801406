import { useAuthenticatedAxiosInstance } from './useAuthenticatedAxiosInstance';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { getEnvironmentConfig } from '../environmentConfig';
import { IntegrationAccess } from '@glueapp/graphexecution/lib/types/Integration';
import { CallIntegrationRequest, CallIntegrationResponse } from '@glueapp/graphexecution/lib/types/IntegrationExecutionAccess';


export const useIntegrationAccessApi = () => {
    const api = useAuthenticatedAxiosInstance(`${getEnvironmentConfig().integrationServiceUrl}`);

    const getAllIntegrationAccesses = useCallback(
        async (): Promise<IntegrationAccess[]> => {
            const result: AxiosResponse = await api.get(
                `/integrations`
            );
            return result.data.integrationAccesses;
        },
        [api]
    );

    const deleteIntegrationAccess = useCallback(
        async (integrationAccess: IntegrationAccess): Promise<void> => {
            await api.delete(
                `/integration/${integrationAccess.id}`
            )
        },
        [api]
    );

    const callIntegration = useCallback(
        async <T>(integrationAccess: IntegrationAccess, requestConfig: CallIntegrationRequest): Promise<CallIntegrationResponse<T>> => {
            const params = new URLSearchParams();
            if(!requestConfig.url) {
                throw new Error(`Node using Integration: ${integrationAccess.provider} called callIntegration without a URL`);
            }
            params.append('integrationUrl', requestConfig.url);
            const response = await api.request(
                {
                    ...requestConfig,
                    url: `/call-integration/${integrationAccess.id}`,
                    params: params
                }
            );
            return {
                data: response.data,
                status: response.status
            };
        },
        [api]
    );

    return {
        getAllIntegrationAccesses,
        deleteIntegrationAccess,
        callIntegration
    }
};
