import { MouseEvent, MutableRefObject, useCallback, useRef, useState } from 'react';
import { Vector } from '../helpers/Vector';
import { getInElementPositionFromEvent } from './helpers/getElementInPositionFromEvent';
import { ReframeDirection } from './graphComponents/SubGraphNode';
import { screenToWorldCoordinate } from './helpers/coordinateSystemMapping';
import { DRAW_CONFIG } from './drawConfig.constant';
import { DataNodeInstance } from '@glueapp/graphexecution/lib/types/Graph';


export interface ActiveReframe {
    startPosition: Vector;
    currentPosition: Vector;
    nodeId: number;
    nodeStartPosition: Vector;
    nodeStartWidth: number;
    nodeStartHeight: number;
    direction: ReframeDirection;
}

export function useSubGraphReframe(
    reframeNode: (nodeId: number, position: Vector, width: number, height: number) => void,
    reframeNodeEnd: () => void,
    containerRef: MutableRefObject<HTMLDivElement|null>,
    zoomLevel: number
) {
    const zoomLevelRef = useRef<number>(zoomLevel);
    zoomLevelRef.current = zoomLevel;

    const subGraphReframe = useRef<ActiveReframe|null>(null);
    const [isReframeActive, setIsReframeActive] = useState<boolean>(false);
    const onReframeStart = useCallback((event: MouseEvent, node: DataNodeInstance, direction: ReframeDirection) => {
        const containerElement = containerRef.current;
        if (containerElement && node.hasSubGraph) {
            // Start drag of new connection based on old.
            const startPosition = screenToWorldCoordinate(
                getInElementPositionFromEvent(event, containerElement),
                {
                    x: containerElement.scrollLeft,
                    y: containerElement.scrollTop
                },
                zoomLevelRef.current
            );
            subGraphReframe.current = {
                startPosition: startPosition,
                currentPosition: startPosition,
                direction: direction,
                nodeId: node.id,
                nodeStartPosition: { x: node.position.x, y: node.position.y},
                nodeStartWidth: node.width ?? DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM,
                nodeStartHeight: node.height ?? DRAW_CONFIG.SUB_GRAPH_NODE_HEIGHT_MINIMUM
            };
            setIsReframeActive(true);
        }
    }, [containerRef]);

    const onReframeUpdate = useCallback((event: MouseEvent) => {
        const containerElement = containerRef.current;
        const currentReframe = subGraphReframe.current;
        if (containerElement && currentReframe) {
            // Start drag of new connection based on old.
            const startPosition = screenToWorldCoordinate(
                getInElementPositionFromEvent(event, containerElement),
                {
                    x: containerElement.scrollLeft,
                    y: containerElement.scrollTop
                },
                zoomLevelRef.current
            );
            const updatedReframe: ActiveReframe = {
                ...currentReframe,
                currentPosition: startPosition
            };
            subGraphReframe.current = updatedReframe;
            let newX = updatedReframe.nodeStartPosition.x;
            let newY = updatedReframe.nodeStartPosition.y;
            let newWidth = updatedReframe.nodeStartWidth;
            let newHeight = updatedReframe.nodeStartHeight;

            // Handle left side drag
            switch (updatedReframe.direction) {
                case 'LEFT':
                case 'BOTTOM_LEFT': {
                    const xDelta = Math.min(
                        updatedReframe.currentPosition.x - updatedReframe.startPosition.x,
                        updatedReframe.nodeStartWidth - DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM
                    );
                    const dragWidth = updatedReframe.nodeStartWidth - xDelta;
                    newWidth = Math.max(dragWidth, DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM);
                    newX = updatedReframe.nodeStartPosition.x + xDelta;
                }
            }
            // Handle bottom side drag
            switch (updatedReframe.direction) {
                case 'BOTTOM_LEFT':
                case 'BOTTOM_RIGHT':
                case 'BOTTOM': {
                    const yDelta = updatedReframe.currentPosition.y - updatedReframe.startPosition.y;
                    newHeight = Math.max(updatedReframe.nodeStartHeight + yDelta, DRAW_CONFIG.SUB_GRAPH_NODE_HEIGHT_MINIMUM);
                }
            }
            // Handle right side drag
            switch (updatedReframe.direction) {
                case 'BOTTOM_RIGHT':
                case 'RIGHT': {
                    const xDelta = updatedReframe.currentPosition.x - updatedReframe.startPosition.x;
                    newWidth = Math.max(updatedReframe.nodeStartWidth + xDelta, DRAW_CONFIG.SUB_GRAPH_NODE_WIDTH_MINIMUM);
                }
            }

            reframeNode(
                updatedReframe.nodeId,
                { x: newX, y: newY },
                newWidth,
                newHeight
            );
        }
    }, [reframeNode, containerRef])

    const onReframeEnd = useCallback(() => {
        subGraphReframe.current = null;
        setIsReframeActive(false);
        reframeNodeEnd();
    }, [reframeNodeEnd])

    return {
        isReframeActive,
        onReframeStart,
        onReframeUpdate,
        onReframeEnd
    }
}